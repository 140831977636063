/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Input } from "@danishagro/shared/src/components/atoms/Input/Input.component";
import { DA_Alert } from "@danishagro/shared/src/components/atoms/Alert/Alert.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { generateUniqueId } from "@danishagro/shared/src/helpers/generateUniqueId.helper";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { NotificationContactV2 } from "@templates/FarmInTime/components/Settings/interfaces/notificationContact.interface";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import {
    useDynamicOverlay,
    DA_DynamicOverlayWrapper,
} from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useValidation } from "../hooks/useValidation.hook";
import { useNotificationServiceBottomSheet } from "../../../hooks/useNotificationServiceBottomSheet";
import { DA_NotificationServiceViewState } from "../../enums/viewStates";
import { ContactRow } from "../../interfaces/contactRow.interface";
import { AddNewContact } from "./components/AddNewContact/AddNewContact.component";
import { EditOrRemoveContact } from "./components/EditOrRemove/EditOrRemove.component";
import S from "./NotificationServiceBottomSheet.module.scss";

export interface NotificationServiceBottomSheetProps {
    defaultContacts?: NotificationContactV2[];
    currentAddress?: DA_Address;

    onSubmit: (contact: NotificationContactV2) => void;
    onCancel: () => void;
}

export const DA_NotificationBottomSheet = (props: NotificationServiceBottomSheetProps) => {
    const { getDictionaryString } = useTranslations();
    const { dismissBottomSheet } = useBottomSheet();
    const { showOverlay, closeOverlay } = useDynamicOverlay();
    const [newContactName] = useState("");
    const [newContactEmail] = useState("");
    const [newContactPhone] = useState("");

    const mappedContacts = useMemo(() => {
        const data = props.defaultContacts ?? [];

        let result = [];

        result = data.map((contact) => {
            return {
                ...contact,
                id: generateUniqueId(),
            };
        });

        return result;
    }, [props.defaultContacts]);

    const [contactRows, setContactRows] = useState<ContactRow[]>(mappedContacts);
    const { validationErrorMessages, onInputChange, getError, validate } = useValidation(
        contactRows.length
    );

    const { onFormSubmitted, viewState } = useNotificationServiceBottomSheet(
        validate,
        props.currentAddress,
        props.onSubmit,
        props.onCancel
    );

    const viewStateEnum: DA_NotificationServiceViewState =
        viewState as DA_NotificationServiceViewState;

    const addInputLine = (name: string, email: string, phone: string) => {
        setContactRows((prev) => {
            const result = prev ? [...prev] : [];
            result.push({
                id: generateUniqueId(),
                name: name,
                email: email,
                phone: phone,
            });

            return result;
        });
        closeOverlay();
    };

    const editContact = useCallback(
        (id: string, name: string, email: string, phone: string) => {
            // Find indekset for det objekt med det samme id som det, der blev klikket på.
            const index = contactRows.findIndex((row) => row.id === id);

            if (index !== -1) {
                const updatedContact = {
                    ...contactRows[index],
                    name: name,
                    email: email,
                    phone: phone,
                };

                // Opdater contactRows med den opdaterede kontakt.
                const updatedRows = [...contactRows];
                updatedRows[index] = updatedContact;
                setContactRows(updatedRows);
            }
            closeOverlay();
        },
        [contactRows, closeOverlay]
    );

    const newContact = useCallback(() => {
        showOverlay({
            id: "new-contact-overlay",
            title: getDictionaryString("addContact"),
            content: <AddNewContact onSubmit={addInputLine} />,
        });
    }, [
        addInputLine,
        contactRows.length,
        getDictionaryString,
        newContactEmail,
        newContactName,
        newContactPhone,
        showOverlay,
    ]);

    const onDeleteLine = useCallback(
        (givenId: string) => {
            setContactRows((prev) => {
                let result = prev ? [...prev] : [];

                result = prev.filter((row) => row.id !== givenId);

                return result;
            });
            closeOverlay();
        },
        [closeOverlay]
    );

    const editOrRemoveContact = useCallback(
        (id: string, name: string, phone: string, email: string) => {
            showOverlay({
                id: "edit-user-overlay",
                title: name,
                content: (
                    <EditOrRemoveContact
                        id={id}
                        name={name}
                        email={email}
                        phone={phone}
                        onSubmit={editContact}
                        onRemove={onDeleteLine}
                    />
                ),
            });
        },
        [onDeleteLine, onFormSubmitted, showOverlay]
    );

    return (
        <DA_DynamicOverlayWrapper
            content={
                <>
                    <div className={S.textWrapper}>
                        <DA_Text>
                            {getDictionaryString("NotificationServiceModalDescription")}
                        </DA_Text>
                    </div>

                    {contactRows.length > 0 ? (
                        <div className={S.userItems}>
                            {contactRows.map(
                                (row) => (
                                    <button
                                        key={row.id}
                                        onClick={() =>
                                            editOrRemoveContact(
                                                row.id,
                                                row.name,
                                                row.phone,
                                                row.email
                                            )
                                        }
                                        className={S.userItem}
                                    >
                                        <div className={S.roletype}>
                                            <span>{row?.phone}</span> | <span>{row?.email}</span>
                                        </div>
                                        <div className={S.name}>{row?.name}</div>
                                    </button>
                                ),
                                []
                            )}
                        </div>
                    ) : (
                        <div className={S.noContactWrapper}>
                            <div className={S.iconWrapper}>
                                <DA_Icon name={DA_IconNames.SearchOutline} />
                            </div>
                            <DA_Text noPadding alignCenter>
                                {getDictionaryString("noContactsAdded")}
                            </DA_Text>
                        </div>
                    )}

                    <form onSubmit={onFormSubmitted} noValidate id="addContactForm">
                        <div className={S.content}>
                            {contactRows.map((row, index) => {
                                const nameError = getError("name", index);
                                const emailError = getError("email", index);
                                const phoneError = getError("phone", index);

                                return (
                                    <div key={row.id}>
                                        {/** Name */}
                                        <DA_Input
                                            autoComplete={`name-${index}`}
                                            value={row?.name}
                                            name={`name-${index}`}
                                            className={S.inputField}
                                            id={`name-${row.id}`}
                                            invalid={!!nameError}
                                            label={
                                                index === 0
                                                    ? `${getDictionaryString(
                                                          "NotificationServiceModalName"
                                                      )}*`
                                                    : undefined
                                            }
                                            onChange={() => {
                                                onInputChange("name", index);
                                            }}
                                        />

                                        {/** E-mail */}
                                        <DA_Input
                                            autoComplete={`email-${index}`}
                                            type="email"
                                            value={row?.email}
                                            name={`email-${index}`}
                                            className={S.inputField}
                                            id={`email-${row.id}`}
                                            invalid={!!emailError}
                                            label={
                                                index === 0
                                                    ? `${getDictionaryString(
                                                          "NotificationServiceModalEmail"
                                                      )}*`
                                                    : undefined
                                            }
                                            onChange={() => {
                                                onInputChange("email", index);
                                            }}
                                        />

                                        {/** Phone */}
                                        <DA_Input
                                            autoComplete={`phone-${index}`}
                                            type="tel"
                                            value={row?.phone}
                                            name={`phone-${index}`}
                                            className={S.inputField}
                                            id={`phone-${row.id}`}
                                            invalid={!!phoneError}
                                            label={
                                                index === 0
                                                    ? `${getDictionaryString(
                                                          "NotificationServiceModalPhone"
                                                      )}*`
                                                    : undefined
                                            }
                                            onChange={() => {
                                                onInputChange("phone", index);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className={S.inputAddressButton}>
                            <DA_ButtonLink
                                title={getDictionaryString("addNewContact")}
                                onClick={newContact}
                                icon={DA_IconNames.Add}
                                type="button"
                            />
                        </div>

                        {/** Validation */}
                        {validationErrorMessages?.length > 0 && (
                            <div className={S.errorMessage}>
                                {validationErrorMessages?.[0].value}
                            </div>
                        )}
                    </form>

                    {/** Server error */}
                    {viewState === DA_NotificationServiceViewState.ERROR && (
                        <div className={S.error}>
                            <DA_Alert
                                content={getDictionaryString("NotificationServiceModalError")}
                                type="error"
                                hideCloseBtn
                            />
                        </div>
                    )}
                </>
            }
            footer={
                <div className={S.buttons}>
                    <DA_Button
                        className={S.button}
                        title={getDictionaryString("NotificationServiceModalSubmit")}
                        disabled={[
                            DA_NotificationServiceViewState.SAVING,
                            DA_NotificationServiceViewState.DELETING,
                        ].includes(viewStateEnum)}
                        showSpinner={viewState === DA_NotificationServiceViewState.SAVING}
                        form="addContactForm"
                        submit
                    />

                    <DA_Button
                        title={getDictionaryString("NotificationServiceModalCancel")}
                        onClick={() => dismissBottomSheet()}
                        className={S.button}
                        isGhost
                    />
                </div>
            }
        />
    );
};
