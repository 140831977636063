import { useMemo } from "react";
import { stripTagsFromString } from "@baggie/core";

const SHORT_DESCRIPTION_LENGTH = 125;

export const useShortDescription = (shortDescription: string, fullDescription: string) =>
    useMemo(() => {
        if (shortDescription) {
            return shortDescription;
        }

        // Strip string for html tags and split it into characters with html entities (&aelig) counting as 1
        const fullDescriptionSplit = stripTagsFromString(fullDescription, " ")
            .replace(/&nbsp;/g, " ")
            .trim()
            .match(/&[a-z]+;|.?/gi);

        return (
            fullDescriptionSplit
                .slice(0, SHORT_DESCRIPTION_LENGTH)
                .join("")
                // Remove trailing spaces and dots and insert ellipsis or ending dot
                .replace(/(\.?\s?)+$/, "") +
            (fullDescriptionSplit.length > SHORT_DESCRIPTION_LENGTH ? "..." : ".")
        );
    }, [shortDescription, fullDescription]);
