import { useCallback, useState, useEffect, useMemo } from "react";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { useAddressesApi } from "@danishagro/shared/src/hooks/api/useAddressesApi.hook";
import { useAddressFromSessionStorage } from "@danishagro/shared/src/hooks/useAddressFromSessionStorage.hook";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";

export const useFilterOptions = () => {
    const { getDeliveryAddresses } = useAddressesApi();
    const { saveDeliveryInfo } = useCart();

    const { getAddressObject, setAddressInSessionStorage } = useAddressFromSessionStorage();

    const [deliveryAddresses, setDeliveryAddresses] = useState<DA_Address[]>([]);
    const [currentDeliveryAddress, setCurrentDeliveryAddress] = useState<DA_Address>(null);

    // Mappers

    const deliveryAddressOptions = useMemo(() => {
        const result = [];

        deliveryAddresses.forEach((deliveryAddress) => {
            result.push({
                value: deliveryAddress.id,
                label: `${deliveryAddress.streetName} ${deliveryAddress.streetNumber}, ${deliveryAddress.zipCode} ${deliveryAddress.city}`,
            });
        });

        return result;
    }, [deliveryAddresses]);

    const currentDeliveryAddressOption = useMemo(
        () => deliveryAddressOptions.find((x) => x.value === currentDeliveryAddress?.id),
        [deliveryAddressOptions, currentDeliveryAddress]
    );

    // Update

    const onDeliveryAddressSelectChanged = useCallback(
        (option: { label: string; value: string }) => {
            const newDeliveryAddress = deliveryAddresses.find((x) => x.id === option.value);

            setCurrentDeliveryAddress(newDeliveryAddress);
            setAddressInSessionStorage(newDeliveryAddress.id);

            saveDeliveryInfo({
                billingAddress: {
                    streetName: "",
                    streetNumber: "",
                    zipCode: "",
                    city: "",
                },
                shippingAddress: {
                    ...newDeliveryAddress,
                },
                shippingSameAsBilling: false,
            });
        },
        [deliveryAddresses, saveDeliveryInfo, setAddressInSessionStorage]
    );

    const onDeliveryAddressesSuccess = useCallback(
        (response: DA_Address[]) => {
            setCurrentDeliveryAddress(getAddressObject(response));
            setDeliveryAddresses(response);
        },
        [getAddressObject]
    );

    const onDeliveryAddressesError = useCallback(() => {
        // TODO: Make a toast telling that the feature is down.
        console.log("Error");
    }, []);

    const updateDeliveryAddresses = useCallback(() => {
        getDeliveryAddresses().then(onDeliveryAddressesSuccess).catch(onDeliveryAddressesError);
    }, [getDeliveryAddresses, onDeliveryAddressesError, onDeliveryAddressesSuccess]);

    const onAddressOverlayChanged = useCallback(
        (address: DA_Address) => {
            getDeliveryAddresses()
                .then((response) => {
                    setDeliveryAddresses(response);
                    setCurrentDeliveryAddress(address);
                })
                .catch(onDeliveryAddressesError);
        },
        [getDeliveryAddresses, onDeliveryAddressesError]
    );

    // Init

    useEffect(() => {
        updateDeliveryAddresses();
    }, [updateDeliveryAddresses]);

    return {
        deliveryAddresses,
        currentDeliveryAddress,
        currentDeliveryAddressOption,
        deliveryAddressOptions,
        onAddressOverlayChanged,
        onDeliveryAddressSelectChanged,
    };
};
