/* eslint-disable jsx-a11y/autocomplete-valid */
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_Input } from "@danishagro/shared/src/components/atoms/Input/Input.component";
import { DA_InputWrapper } from "@danishagro/shared/src/components/atoms/InputWrapper/InputWrapper.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_AddressPickerProps } from "./AddressPicker.interface";
import { useGooglePlacesAutocomplete } from "./hooks/useGooglePlacesAutocomplete.hook";
import S from "./AddressPicker.module.scss";

// TODO: Storybook
export const DA_AddressPicker = (props: DA_AddressPickerProps) => {
    const { getDictionaryString } = useTranslations();

    const { currentCulture } = useAppData();
    const GOOGLE_MAPS_API_KEY = "AIzaSyCBqY9mpYO6gk6YIWTeJbIev-lFSux2WOM"; // TODO: Support different keys for other sites (e.g. VILOFARM)

    const [currentAddressFormat, setCurrentAddressFormat] = useState<string>("AUTOMATIC");

    const [address, setAddress] = useState<{
        streetName: string;
        streetNumber: string;
        zipCode: string;
        city: string;
    }>({
        streetName: "",
        streetNumber: "",
        zipCode: "",
        city: "",
    });

    useEffect(() => {
        if (props.zipCode && props.city) {
            setAddress({
                streetName: props.streetName,
                streetNumber: props.streetNumber,
                zipCode: props.zipCode,
                city: props.city,
            });
        }
    }, [props.streetName, props.streetNumber, props.zipCode, props.city]);

    const onManualAddressToggleButtonClicked = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();

            const toggledFormat = currentAddressFormat === "MANUAL" ? "AUTOMATIC" : "MANUAL";

            setCurrentAddressFormat(toggledFormat);
        },
        [currentAddressFormat]
    );

    const addressFormatLabel = useMemo(() => {
        if (currentAddressFormat === "MANUAL") {
            return getDictionaryString("use automatic lookup");
        } else {
            return getDictionaryString("enter address manually");
        }
    }, [currentAddressFormat, getDictionaryString]);

    const prefixLabel = props.prefix ? `${props.prefix}_` : undefined;

    const streetNameError = props.validationErrorMessages.find(
        (x) => x.key === `${prefixLabel}streetName`
    );
    const streetNumberError = props.validationErrorMessages.find(
        (x) => x.key === `${prefixLabel}streetNumber`
    );
    const postalCodeError = props.validationErrorMessages.find(
        (x) => x.key === `${prefixLabel}zipCode`
    );
    const cityError = props.validationErrorMessages.find((x) => x.key === `${prefixLabel}city`);

    const currentErrorCount = useMemo(() => {
        let result = 0;

        if (streetNameError) result++;
        if (streetNumberError) result++;
        if (postalCodeError) result++;
        if (cityError) result++;

        return result;
    }, [cityError, postalCodeError, streetNameError, streetNumberError]);

    const onAddressChangeCallback = () => {
        props.onInputChange([
            `${prefixLabel}streetName`,
            `${prefixLabel}streetNumber`,
            `${prefixLabel}zipCode`,
            `${prefixLabel}city`,
        ]);
    };

    const { selectProps } = useGooglePlacesAutocomplete(
        props.prefix,
        setAddress,
        currentErrorCount,
        onAddressChangeCallback
    );

    const showManualClass =
        currentAddressFormat === "MANUAL" || props.forceOpenManual ? S.showManual : S.hideManual;

    return (
        <section className={S.section}>
            <div className={S.header}>
                <DA_Title h3>{props.title}</DA_Title>
                <DA_ButtonLink
                    className={S.headerManualAddressToggleButton}
                    onClick={onManualAddressToggleButtonClicked}
                    title={addressFormatLabel}
                    noUnderline
                />
            </div>

            <div className={S.inner}>
                {/** Automatic */}
                {currentAddressFormat === "AUTOMATIC" ? (
                    <div>
                        <DA_InputWrapper
                            label={getDictionaryString("find your address")}
                            forId="automaticDeliveryAddress"
                            invalid={currentErrorCount > 0}
                        >
                            <div className={S.selectWrapper}>
                                {/* TODO: You cannot mix "establishment", "street_address" and "premise", allow all and show fields */}
                                <GooglePlacesAutocomplete
                                    apiKey={GOOGLE_MAPS_API_KEY}
                                    apiOptions={{
                                        language: currentCulture,
                                        region: currentCulture,
                                    }}
                                    selectProps={selectProps}
                                    autocompletionRequest={{
                                        types: ["geocode"],
                                        componentRestrictions: {
                                            country: ["dk"],
                                        },
                                    }}
                                />
                            </div>

                            {/** Automatic: Error */}
                            {currentErrorCount === 4 ? (
                                <div className={S.invalidHelperText}>
                                    {getDictionaryString("CheckoutValidationAddressRequired")}
                                </div>
                            ) : null}
                        </DA_InputWrapper>
                    </div>
                ) : null}

                {/** Manual */}
                <div className={cn(S.inner, showManualClass)}>
                    <div className={S.split}>
                        <DA_Input
                            id="streetName"
                            label={`${getDictionaryString("streetName")}*`}
                            className={S.largeInputWrapper}
                            autoComplete={`${props.prefix} address-line1`}
                            onChange={() => props.onInputChange(`${prefixLabel}streetName`)}
                            defaultValue={address.streetName}
                            invalid={streetNameError ? true : false}
                            name={`${prefixLabel}streetName`}
                            helperText={streetNameError ? streetNameError.value : undefined}
                        />

                        <DA_Input
                            id="streetNumber"
                            label={`${getDictionaryString("streetNumber")}*`}
                            className={S.smallInputWrapper}
                            autoComplete={`${props.prefix} address-line2`}
                            onChange={() => props.onInputChange(`${prefixLabel}streetNumber`)}
                            defaultValue={address.streetNumber}
                            invalid={streetNumberError ? true : false}
                            name={`${prefixLabel}streetNumber`}
                            helperText={streetNumberError ? streetNumberError.value : undefined}
                        />
                    </div>
                    <div className={S.split}>
                        <DA_Input
                            id="postalCode"
                            label={`${getDictionaryString("zip")}*`}
                            className={S.smallInputWrapper}
                            autoComplete={`${props.prefix} postal-code`}
                            onChange={() => props.onInputChange(`${prefixLabel}zipCode`)}
                            defaultValue={address.zipCode}
                            invalid={postalCodeError ? true : false}
                            name={`${prefixLabel}zipCode`}
                            helperText={postalCodeError ? postalCodeError.value : undefined}
                        />

                        <DA_Input
                            id="city"
                            label={`${getDictionaryString("city")}*`}
                            className={S.largeInputWrapper}
                            autoComplete={`${props.prefix} city`}
                            defaultValue={address.city}
                            onChange={() => props.onInputChange(`${prefixLabel}city`)}
                            invalid={cityError ? true : false}
                            name={`${prefixLabel}city`}
                            helperText={cityError ? cityError.value : undefined}
                        />
                    </div>
                </div>

                <DA_ButtonLink
                    className={S.contentManualAddressToggleButton}
                    onClick={onManualAddressToggleButtonClicked}
                    title={addressFormatLabel}
                    noUnderline
                />

                {props.children}
            </div>
        </section>
    );
};
