import React, { useCallback } from "react";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { clamp } from "@baggie/core";
import S from "./QuantityPicker.module.scss";

export interface DA_QuantityPickerProps {
    value: number;
    onChange: (newQuantity: number) => void;
    disabled?: boolean;
    compactOnMobile?: boolean;
    inputId?: string;
    higherMax?: boolean;
}

export const DA_QuantityPicker = ({
    value,
    onChange,
    disabled,
    compactOnMobile,
    inputId,
    higherMax,
}: DA_QuantityPickerProps) => {
    const { isMobile } = useScreen();

    const showButtons = !isMobile || !compactOnMobile;
    const { getDictionaryString } = useTranslations();

    const higherMaxValue: number = 9999999;

    const handleFocus = useCallback((event) => event.target.select(), []);
    const handleChange = useCallback(
        (newQuantity: number) =>
            onChange(clamp(newQuantity, { min: 1, max: higherMax ? higherMaxValue : 999 })),
        [onChange, higherMax]
    );

    return (
        <div className={cn(S.container, "swiper-no-swiping")}>
            {showButtons && (
                <DA_Button
                    className={cn(S.button, S.minus)}
                    onClick={() => handleChange(value <= 1 ? 1 : value - 1)}
                    icon={DA_IconNames.Minus}
                    disabled={!value || value <= 1 || disabled}
                    title={getDictionaryString("accessibilityButtonMinus")}
                    hideText
                />
            )}

            <input
                id={inputId}
                value={isNaN(value) ? "" : value}
                onChange={(event) => handleChange(parseInt(event.target.value))}
                className={cn(S.input, showButtons && S.sharpCorners, higherMax && S.maxValue)}
                pattern="[0-9]*"
                min={0}
                max={higherMax ? higherMaxValue : 999}
                maxLength={3}
                inputMode="numeric"
                type="number"
                onFocus={handleFocus}
                disabled={disabled}
                aria-label={getDictionaryString("accessibilityQuantityInputField")}
            />

            {showButtons && (
                <DA_Button
                    className={cn(S.button, S.add)}
                    onClick={() => handleChange(!value ? 1 : value + 1)}
                    icon={DA_IconNames.Add}
                    disabled={higherMax ? value >= higherMaxValue : value >= 999 || disabled}
                    title={getDictionaryString("accessibilityButtonPlus")}
                    hideText
                />
            )}
        </div>
    );
};
