import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./Tag.module.scss";

export interface DA_TagProps {
    size?: "small" | "large" | "dynamic";
    color:
        | "good"
        | "warning"
        | "bad"
        | "neutral"
        | "pause"
        | "alternativeNeutral"
        | "alternativeNeutral2";
    children: React.ReactNode;
}

export const DA_Tag = (props: DA_TagProps) => {
    const colorClasses: Record<DA_TagProps["color"], string> = {
        good: S.good,
        warning: S.warning,
        bad: S.bad,
        pause: S.pause,
        neutral: S.neutral,
        alternativeNeutral: S.alternativeNeutral,
        alternativeNeutral2: S.alternativeNeutral2,
    };

    const sizeClass: Record<DA_TagProps["size"], string> = {
        small: S.sizeSmall,
        large: S.sizeLarge,
        dynamic: S.sizeDynamic,
    };

    return (
        <span className={cn(S.tag, colorClasses[props.color], sizeClass[props.size || "dynamic"])}>
            {props.children}
        </span>
    );
};
