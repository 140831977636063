import { useCallback, useEffect, useMemo, useState } from "react";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { FrequencySelectOption } from "@molecules/WishListButton/modals/interfaces/frequencySelectOption.interface";

export const useFrequencies = () => {
    const { getDictionaryString } = useTranslations();
    const { getFarmInTimeFrequencies } = useFarmInTimeApi();

    const [frequencies, setFrequencies] = useState<string[]>();
    const [currentFrequency, setCurrentFrequency] = useState<string>();
    const [viewState, setViewState] = useState<string>("LOADING");

    // Mappers

    const frequencyOptions = useMemo(() => {
        const result: {
            label: string;
            value: string;
            extra: string;
        }[] = [];

        frequencies?.forEach((frequency) => {
            result.push({
                value: frequency,
                label: getDictionaryString(frequency),
                extra: getDictionaryString(`helperText${frequency}`),
            });
        });

        return result;
    }, [frequencies, getDictionaryString]);

    const currentFrequencyOption = useMemo(
        () => frequencyOptions.find((x) => x.value === currentFrequency),
        [frequencyOptions, currentFrequency]
    );

    // Fetch frequencies

    const fetchFrequencies = useCallback(async () => {
        const response = await getFarmInTimeFrequencies();

        if (response?.[0]) {
            setFrequencies(response);
            setCurrentFrequency(response?.[0]);
        }

        return response;
    }, [getFarmInTimeFrequencies]);

    // Update

    const onFrequencySelectChanged = (option: FrequencySelectOption) => {
        setCurrentFrequency(option.value);
    };

    // Init

    useEffect(() => {
        fetchFrequencies()
            .then((response) => {
                if (response && response.length > 0) {
                    setViewState("DONE");
                } else {
                    setViewState("ERROR");
                }
            })
            .catch(() => {
                setViewState("ERROR");
            });
    }, [fetchFrequencies]);

    return {
        frequencies,
        frequencyOptions,
        currentFrequency,
        currentFrequencyOption,
        onFrequencySelectChanged,
        viewState,
    };
};
