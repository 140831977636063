import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_LinkProps } from "../../../interfaces/linkProps.interface";
import { DA_BasicLink } from "../BasicLink/BasicLink.component";
import { DA_Icon, DA_IconNames } from "../Icon/Icon.component";
import S from "./InlineLink.module.scss";

interface BaseProps {
    icon?: DA_IconNames;
    onlyIcon?: boolean;
    isBold?: boolean;
    itemProp?: string;
    largeText?: boolean;
    noUnderline?: boolean;
    className?: string;
    iconClassName?: string;
    forceNativeUrl?: boolean;
}

export type DA_InlineLinkProps = BaseProps & DA_LinkProps;

export const DA_InlineLink = (props: DA_InlineLinkProps) => {
    const {
        title,
        href,
        target,
        icon,
        onlyIcon,
        isBold,
        itemProp,
        largeText,
        noUnderline,
        className,
        iconClassName,
        conventionalRouting,
        forceNativeUrl = false,
    } = props;

    const linkClass = cn(S.link, largeText && S.largeText, noUnderline || S.underline, className);

    return (
        <DA_BasicLink
            href={href}
            target={target}
            className={linkClass}
            ariaLabel={onlyIcon ? title : undefined}
            conventionalRouting={conventionalRouting}
            forceNativeUrl={forceNativeUrl}
        >
            {icon && (
                <span className={S.iconWrapper}>
                    <DA_Icon name={icon} className={iconClassName} />
                </span>
            )}

            {!onlyIcon && (
                <span
                    className={cn(S.text, isBold && S.bold)}
                    itemProp={itemProp}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
            )}

            {onlyIcon && itemProp && <meta itemProp={itemProp} content={title} />}
        </DA_BasicLink>
    );
};
