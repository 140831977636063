import React, { FormEvent, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Checkbox } from "@danishagro/shared/src/components/atoms/Checkbox/Checkbox.component";
import { DA_InputWrapper } from "@danishagro/shared/src/components/atoms/InputWrapper/InputWrapper.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { ShippingInformationFormData } from "./interfaces/shippingInformationFormData.interface";
import { DA_AddressPicker } from "./components/AddressPicker/AddressPicker.component";
import { formatFormBody } from "./helpers/formatFormBody.helper";
import { useValidation } from "./hooks/useValidation.hook";
import S from "./ShippingInformation.module.scss";

export const DA_SteppedCheckoutShippingInformation = () => {
    const { getDictionaryString } = useTranslations();
    const navigate = useNavigate();
    const { isMobile } = useScreen();

    const { saveDeliveryInfo, customerDetails, shippingDetails, latestShippingDate } = useCart();
    const { validationErrorMessages, validate, onInputChange } = useValidation();

    const [shippingSameAsBilling, setShippingSameAsBilling] = useState<boolean>(true);
    const [forceOpenManual, setForceOpenManual] = useState<boolean>(false);

    const onFormSubmitted = async (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        const form = new FormData(event.target as HTMLFormElement);
        const body = {} as ShippingInformationFormData;

        form.forEach((value, key) => (body[key] = value));

        const isValid = validate(body);

        if (isValid) {
            // Submitting step 2
            // Will automatically refresh the cart globally
            const formattedBody = formatFormBody(body);
            saveDeliveryInfo(formattedBody)
                .then(() => {
                    // Proceed to step 3
                    navigate("/checkout/payment");
                })
                .catch(() => {
                    // TODO: A JIRA task has been created to handle this flow better.
                });
        } else {
            setForceOpenManual(true);
        }
    };

    // TODO: Compare billing and shipping and determine defaultChecked same address.

    return (
        <form noValidate onSubmit={onFormSubmitted}>
            {/** Shipping Address */}
            <DA_AddressPicker
                prefix="shipping"
                title={getDictionaryString("deliveryAddress")}
                onInputChange={onInputChange}
                validationErrorMessages={validationErrorMessages}
                forceOpenManual={forceOpenManual}
                {...shippingDetails}
            >
                <DA_InputWrapper>
                    <DA_Checkbox
                        name="shippingSameAsBilling"
                        checked={shippingSameAsBilling}
                        value="true"
                        onChange={setShippingSameAsBilling}
                        label={getDictionaryString(
                            "billing address is the same as the delivery address"
                        )}
                    />
                </DA_InputWrapper>
            </DA_AddressPicker>

            {/** Billing Address */}
            {shippingSameAsBilling === false && (
                <DA_AddressPicker
                    prefix="billing"
                    title={getDictionaryString("billingAddress")}
                    onInputChange={onInputChange}
                    validationErrorMessages={validationErrorMessages}
                    forceOpenManual={forceOpenManual}
                    {...customerDetails}
                />
            )}

            <section className={S.section}>
                <div className={S.header}>
                    <DA_Title h3>{getDictionaryString("OneStepCheckoutShippingHeading")}</DA_Title>
                    <span className={S.headerSpacer}>&nbsp;</span>
                </div>
                <div className={S.noteWrapper}>
                    <strong className={S.noteHeading}>
                        {getDictionaryString("OneStepCheckoutShippingDateHeading")}{" "}
                        {latestShippingDate ? (
                            format(latestShippingDate, "dd.MM.yyyy")
                        ) : (
                            <Skeleton width={75} baseColor="#ddd" highlightColor="#e7e7e7" />
                        )}
                    </strong>
                    <p className={S.noteText}>
                        {getDictionaryString("OneStepCheckoutShippingDateText")}
                    </p>
                </div>
            </section>

            <div className={S.buttons}>
                <DA_Button
                    href="/checkout/contact"
                    title={getDictionaryString(
                        isMobile
                            ? "back to contact information mobile"
                            : "back to contact information"
                    )}
                    isGhost
                />

                <DA_Button
                    title={getDictionaryString(
                        isMobile ? "continue to payment mobile" : "continue to payment"
                    )}
                    submit
                />
            </div>
        </form>
    );
};
