import React from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { useModal } from "@danishagro/shared/src/contexts/modal.context";
import { DA_ConfirmModalProps } from "./ConfirmModal.interface";
import S from "./ConfirmModal.module.scss";

export const DA_ConfirmModal = (props: DA_ConfirmModalProps) => {
    const { closeModal } = useModal();

    const onApproveButtonClicked = () => {
        if (typeof props.onApprove !== "undefined") {
            props.onApprove();
        }

        closeModal();
    };

    const onDenyButtonClicked = () => {
        if (typeof props.onDeny !== "undefined") {
            props.onDeny();
        }

        closeModal();
    };

    return (
        <DA_Modal title={props.title}>
            {/** Description */}
            {typeof props.description === "string" ? (
                <div className={S.text} dangerouslySetInnerHTML={{ __html: props.description }} />
            ) : (
                <div className={S.text}>{props.description}</div>
            )}

            <div className={S.buttonWrapper}>
                {/** Approve */}
                {typeof onApproveButtonClicked !== "undefined" && props.approveButtonLabel ? (
                    <DA_Button
                        color={props.approveButtonColor}
                        title={props.approveButtonLabel}
                        onClick={onApproveButtonClicked}
                    />
                ) : null}

                {/** Deny */}
                <DA_Button title={props.denyButtonLabel} onClick={onDenyButtonClicked} isGhost />
            </div>
        </DA_Modal>
    );
};
