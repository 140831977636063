import { useCallback } from "react";
import { camelCase } from "change-case";
import { HEX_COLORS } from "../constants/hexColors.constant";
import { useAppData } from "../contexts/appData.context";

export const useHexColor = () => {
    const { siteVariant } = useAppData();

    return useCallback(
        (name: string): string => {
            const parsedName = camelCase(name).replace(/_(\d+)/, "$1");

            if (parsedName in HEX_COLORS.common) {
                return HEX_COLORS.common[parsedName];
            } else if (parsedName in HEX_COLORS.themed[siteVariant.alias]) {
                return HEX_COLORS.themed[siteVariant.alias][parsedName];
            } else {
                throw `Color "${name}" not found`;
            }
        },
        [siteVariant]
    );
};
