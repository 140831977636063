import React, { MouseEvent, useRef } from "react";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { useOnClickOutside } from "@danishagro/shared/src/hooks/useOnClickOutside.hook";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_FarmInTimeQuickSelectTooltipProps } from "./Tooltip.interface";
import S from "./Tooltip.module.scss";

const FarmInTimeQuickSelectTooltip = (props: DA_FarmInTimeQuickSelectTooltipProps) => {
    const { getDictionaryString } = useTranslations();

    const wrapperElementRef = useRef(null);
    useOnClickOutside(wrapperElementRef, () => {
        if (typeof props.onClose !== "undefined") {
            props.onClose();
        }
    });

    const onLinkClicked = (event: MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        event?.stopPropagation();

        // Change enabled/disabled
        if (typeof props.onChange !== "undefined") {
            props.onChange();
        }

        // Hide tooltip
        if (typeof props.onClose !== "undefined") {
            props.onClose();
        }
    };

    return props.isVisible ? (
        <div ref={wrapperElementRef} className={S.quickSelectTooltip}>
            {/** Enabled */}
            {props.isQuickSelectEnabled ? (
                <DA_ButtonLink
                    onClick={onLinkClicked}
                    className={S.quickSelectLink}
                    title={getDictionaryString("FarmInTimeQuickSelectTooltipDisable")}
                />
            ) : null}

            {/** Disabled */}
            {!props.isQuickSelectEnabled ? (
                <DA_ButtonLink
                    onClick={onLinkClicked}
                    className={S.quickSelectLink}
                    title={getDictionaryString("FarmInTimeQuickSelectTooltipEnable")}
                />
            ) : null}
        </div>
    ) : null;
};

export default FarmInTimeQuickSelectTooltip;
