import React, { FormEvent, useCallback, useState } from "react";
import { DA_Alert } from "@danishagro/shared/src/components/atoms/Alert/Alert.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { DA_TextArea } from "@danishagro/shared/src/components/atoms/TextArea/TextArea.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_ViewStates } from "@danishagro/shared/src/content/viewState/viewStates";
import { DriverMessageModalProps } from "./DriverMessageModal.interface";
import S from "./DriverMessageModal.module.scss";

export const DriverMessageModal = (props: DriverMessageModalProps) => {
    const { getDictionaryString } = useTranslations();

    const { customerNumber } = useAppData();
    const { putFarmInTimeDriverMessageByAddress } = useFarmInTimeApi();

    const [viewState, setViewState] = useState<DA_ViewStates>(DA_ViewStates.INIT);

    const onFormSubmitted = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            const form = new FormData(event.target as HTMLFormElement);
            const body = {} as { driverMessage: string };

            form.forEach((value, key) => (body[key] = value));

            setViewState(DA_ViewStates.SAVING);

            try {
                await putFarmInTimeDriverMessageByAddress(
                    customerNumber,
                    props.currentAddress?.id,
                    body
                );

                props.onSubmit(body?.driverMessage);
            } catch {
                setViewState(DA_ViewStates.ERROR);
            }
        },
        [customerNumber, props, putFarmInTimeDriverMessageByAddress]
    );

    const onCancelButtonClicked = useCallback(() => {
        props.onCancel();
    }, [props]);

    return (
        <DA_Modal title={getDictionaryString("DriverMessageModalHeading")}>
            <form noValidate onSubmit={onFormSubmitted}>
                <div className={S.content}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getDictionaryString("DriverMessageModalDescription"),
                        }}
                    />

                    {/** Name */}
                    <DA_TextArea
                        autoComplete="off"
                        name="driverMessage"
                        className={S.inputWrapper}
                        inputClassName={S.input}
                        defaultValue={props?.message}
                        id="driverMessage"
                    />
                </div>

                <div className={S.buttons}>
                    {/** Submit */}
                    <DA_Button
                        title={getDictionaryString("DriverMessageModalSubmit")}
                        disabled={viewState === DA_ViewStates.SAVING}
                        showSpinner={viewState === DA_ViewStates.SAVING}
                        submit
                    />

                    {/** Cancel */}
                    <DA_Button
                        title={getDictionaryString("DriverMessageModalCancel")}
                        onClick={onCancelButtonClicked}
                        isGhost
                    />
                </div>
            </form>

            {/** Server error */}
            {viewState === DA_ViewStates.ERROR && (
                <div className={S.error}>
                    <DA_Alert
                        content={getDictionaryString("DriverMessageModalError")}
                        type="error"
                        hideCloseBtn
                    />
                </div>
            )}
        </DA_Modal>
    );
};
