import { useEffect, useState } from "react";
import { DA_CartData } from "@danishagro/shared/src/interfaces/cartData.interface";
import { DA_CartOrderLine } from "@danishagro/shared/src/interfaces/cartOrderLine.interface";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { DA_CartPriceData } from "@danishagro/shared/src/interfaces/price.interface";

export const useCompletedOrder = (cartId: string) => {
    const [awaitingResponse, setAwaitingResponse] = useState(true);
    const [price, setPrice] = useState<DA_CartPriceData>();
    const [customerDetails, setCustomerDetails] = useState<DA_CartData["customerDetails"]>();
    const [shippingDetails, setShippingDetails] = useState<DA_CartData["shippingDetails"]>();
    const [farmInTimeDriverMessage, setFarmInTimeDriverMessage] = useState<string>("");
    const [farmInTimeReferenceId, setFarmInTimeReferenceId] = useState<string>("");
    const [completedDate, setCompletedDate] = useState<Date>();
    const [orderLines, setOrderLines] = useState<DA_CartOrderLine[]>([]);

    const { getCompletedOrder } = useCart();

    useEffect(() => {
        if (cartId) {
            setAwaitingResponse(true);
            getCompletedOrder(cartId)
                .then((data) => {
                    setPrice(data.price);
                    setCustomerDetails(data.customerDetails);
                    setShippingDetails(data.shippingDetails);
                    setCompletedDate(data.completedDate);
                    setOrderLines(data.orderLines);
                    setFarmInTimeReferenceId(data.farmInTimeReferenceId);
                    setFarmInTimeDriverMessage(data.farmInTimeDriverMessage);
                })
                .finally(() => setAwaitingResponse(false));
        }
    }, [cartId, getCompletedOrder, setFarmInTimeDriverMessage]);

    return {
        awaitingResponse,
        price,
        customerDetails,
        shippingDetails,
        completedDate,
        orderLines,
        farmInTimeReferenceId,
        farmInTimeDriverMessage,
    };
};
