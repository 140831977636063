import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_OrderListCartOrderLine } from "@danishagro/shared/src/interfaces/OrderListCartOrderLine.interface";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { DA_FarmInTimePrice } from "@danishagro/shared/src/interfaces/farmInTimePrice.interface";

interface FarmInTimeProductsHook {
    refresh: () => void;
    isFetching: boolean;
    orderListProducts: DA_OrderListCartOrderLine[];
    farmInTimeListProducts: DA_OrderListCartOrderLine[];
    prices: DA_FarmInTimePrice[];
}

const FarmInTimeProductsContext = React.createContext<FarmInTimeProductsHook>({
    refresh: () => null,
    isFetching: false,
    orderListProducts: [],
    farmInTimeListProducts: [],
    prices: [],
});

type Props = {
    currentAddress?: DA_Address;
    children: React.ReactNode;
};

export const FarmInTimeProductsProvider = ({ currentAddress, children }: Props): JSX.Element => {
    const { getOrderListProductsV2, getProductPrices } = useFarmInTimeApi();
    const { customerNumber } = useAppData();
    const productsControllerRef = useRef<AbortController | null>();
    const pricesControllerRef = useRef<AbortController | null>();

    // State
    const [isFetching, setIsFetching] = useState(false);

    // Data
    const [orderListProducts, setOrderListProducts] = useState<DA_OrderListCartOrderLine[]>([]);

    const [farmInTimeListProducts, setFarmInTimeListProducts] = useState<
        DA_OrderListCartOrderLine[]
    >([]);

    const fetchProducts = useCallback(
        (setFetchingState = true) => {
            if (productsControllerRef.current) {
                productsControllerRef.current.abort();
            }

            if (setFetchingState) {
                setIsFetching(true);
            }

            productsControllerRef.current = new AbortController();
            getOrderListProductsV2(customerNumber, currentAddress?.id, {
                signal: productsControllerRef.current.signal,
            }).then((response) => {
                setOrderListProducts(response.orderList);
                setFarmInTimeListProducts(response.farmInTimeList);
                setIsFetching(false);
            });
        },
        [currentAddress?.id, customerNumber, getOrderListProductsV2]
    );

    // Prices

    const [prices, setPrices] = useState<DA_FarmInTimePrice[]>([]);

    useEffect(() => {
        if (pricesControllerRef.current) {
            pricesControllerRef.current.abort();
        }

        const productsForPriceLookup = [...orderListProducts, ...farmInTimeListProducts].map(
            ({ quantity, productNumber, productVariantId }) => ({
                quantity,
                productNumber,
                variantId: productVariantId || undefined,
            })
        );

        // Start with resetting prices
        setPrices([]);

        if (productsForPriceLookup.length) {
            pricesControllerRef.current = new AbortController();

            getProductPrices(customerNumber, productsForPriceLookup, {
                signal: pricesControllerRef.current.signal,
            }).then((response) => {
                const newPrices = productsForPriceLookup.map((target) => {
                    return (
                        response.find(({ productNumber, variantId }) => {
                            return (
                                productNumber === target.productNumber &&
                                variantId === target.variantId
                            );
                        }) || undefined
                    );
                });

                setPrices(newPrices);
            });
        }
    }, [orderListProducts, farmInTimeListProducts, getProductPrices, customerNumber]);

    // Update

    const refresh = (): void => {
        fetchProducts(false);
    };

    // Init

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    return (
        <FarmInTimeProductsContext.Provider
            value={{
                prices,
                orderListProducts,
                farmInTimeListProducts,
                isFetching,
                refresh,
            }}
        >
            {children}
        </FarmInTimeProductsContext.Provider>
    );
};

export const useFarmInTimeProducts = (): FarmInTimeProductsHook =>
    React.useContext(FarmInTimeProductsContext);
