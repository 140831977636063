export function isGreaterThanZero(value: string | number): boolean {
    // Check the type of the value and convert it to an integer if it's a string
    const intValue = typeof value === "string" ? parseInt(value, 10) : value;

    // Check if intValue is NaN (Not a Number) when the input is a string
    if (typeof intValue === "number" && isNaN(intValue)) {
        return false;
    }

    return intValue > 0;
}
