import React, { FormEvent, Fragment, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Checkbox } from "@danishagro/shared/src/components/atoms/Checkbox/Checkbox.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useQuickPayApi } from "@hooks/api/useQuickPayApi.hook";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useNavigate } from "react-router-dom";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { PaymentMethodFormData } from "./interfaces/paymentMethodFormData.interface";
import { useQuickpayPaymentMethods } from "./hooks/useQuickpayPaymentMethods.hook";
import { useValidation } from "./hooks/useValidation.hook";
import S from "./PaymentMethod.module.scss";

export const DA_SteppedCheckoutPaymentMethod = () => {
    const { getDictionaryString } = useTranslations();
    const navigate = useNavigate();
    const { isMobile } = useScreen();

    const [viewState, setViewState] = useState<string>("NONE");

    const { customerDetails, shippingDetails, orderSecret } = useCart();
    const { getPaymentLink } = useQuickPayApi();

    // TODO: Use a CheckoutProvider instead to fetch paymentMethods when entering stepped checkout init to avoid loading screen.
    const { currentPaymentMethod, onPaymentMethodChange, paymentMethods } =
        useQuickpayPaymentMethods();
    const { validationErrorMessages, validate } = useValidation();

    const onFormSubmitted = async (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        validatePreviousSteps();

        const form = new FormData(event.target as HTMLFormElement);
        const body = {} as PaymentMethodFormData;

        body.orderSecret = orderSecret;
        form.forEach((value, key) => (body[key] = value));

        const isValid = validate(body);

        if (isValid) {
            setViewState("LOADING");

            // Submitting step 3
            // Will return a redirect link
            getPaymentLink(body)
                .then(async (response: { url: string }) => {
                    if (response?.url) {
                        window.location.href = response?.url;
                    }
                })
                .finally(() => {
                    setViewState("DONE");
                });
        }
    };

    const validatePreviousSteps = () => {
        // Step 1: Mandatory fields
        const companyName = !!customerDetails.companyName;
        const email = !!customerDetails.email;
        const phone = !!customerDetails.phone;

        // Step 2: Mandatory fields
        const billingAddress = !!customerDetails.address;
        const billingZipCode = !!customerDetails.zipCode;
        const billingCity = !!customerDetails.city;
        const shippingAddress = !!shippingDetails.address;
        const shippingZipCode = !!shippingDetails.zipCode;
        const shippingCity = !!shippingDetails.city;

        if (!companyName || !email || !phone) {
            navigate("/checkout/contact");
        } else if (!billingAddress || !billingZipCode || !billingCity) {
            navigate("/checkout/shipping");
        } else if (!shippingAddress || !shippingZipCode || !shippingCity) {
            navigate("/checkout/shipping");
        }
    };

    const eulaError = validationErrorMessages.find((x) => x.key === "eula");

    return (
        <form noValidate onSubmit={onFormSubmitted}>
            <section className={S.section}>
                <div className={S.header}>
                    <DA_Title h3>{getDictionaryString("paymentMethod")}</DA_Title>
                    <span>&nbsp;</span>
                </div>
                <div className={S.inner}>
                    {paymentMethods?.map((paymentMethod) => {
                        const isSelected = currentPaymentMethod === paymentMethod;
                        const isSelectedClass = isSelected ? S.isSelected : undefined;

                        return (
                            <label
                                htmlFor={`paymentMethod-${paymentMethod}`}
                                key={paymentMethod}
                                className={cn(S.paymentItem, isSelectedClass)}
                            >
                                {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ] */}
                                <input
                                    className={S.paymentInput}
                                    type="radio"
                                    id={`paymentMethod-${paymentMethod}`}
                                    name="paymentMethod"
                                    value={paymentMethod}
                                    defaultChecked={isSelected}
                                    onChange={onPaymentMethodChange}
                                />
                                <div className={S.paymentInner}>
                                    <div className={S.paymentContent}>
                                        <strong>
                                            {getDictionaryString(
                                                `payment heading ${paymentMethod}`
                                            )}
                                        </strong>
                                        <p>
                                            {getDictionaryString(
                                                `payment description ${paymentMethod}`
                                            )}
                                        </p>
                                    </div>
                                    <div className={S.paymentIcons}>
                                        {/** Icon: Creditcard */}
                                        {paymentMethod === "creditcard" ? (
                                            <Fragment>
                                                <DA_Icon
                                                    name={DA_IconNames.DankortWrapped}
                                                    useSvgDimensions
                                                />
                                                <DA_Icon
                                                    name={DA_IconNames.VisaWrapped}
                                                    useSvgDimensions
                                                />
                                                <DA_Icon
                                                    name={DA_IconNames.MasterCardWrapped}
                                                    useSvgDimensions
                                                />
                                            </Fragment>
                                        ) : null}

                                        {/** Icon: MobilePay */}
                                        {paymentMethod === "mobilepay" ? (
                                            <DA_Icon
                                                name={DA_IconNames.MobilePayWrapped}
                                                useSvgDimensions
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </label>
                        );
                    })}
                </div>
            </section>

            {/** Submit */}
            <div className={S.section}>
                <div className={S.header}>
                    <DA_Title h3>{getDictionaryString("OneStepCheckoutApproveOrder")}</DA_Title>
                    <span>&nbsp;</span>
                </div>

                <DA_Checkbox
                    name="eula"
                    value="true"
                    required
                    label={
                        <>
                            <strong>{getDictionaryString("OneStepCheckoutEulaAccept")}</strong>{" "}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: getDictionaryString(
                                        "OneStepCheckoutEulaTermsConditions"
                                    ),
                                }}
                            />
                        </>
                    }
                />

                {/** Validation: EULA */}
                {eulaError ? <div className={S.error}>{eulaError.value}</div> : null}
            </div>

            <div className={S.buttons}>
                <DA_Button
                    href="/checkout/shipping"
                    title={getDictionaryString(
                        isMobile ? "back to shipping mobile" : "back to shipping"
                    )}
                    disabled={viewState === "LOADING"}
                    isGhost
                />

                <DA_Button
                    title={getDictionaryString(
                        isMobile ? "continue and pay mobile" : "continue and pay"
                    )}
                    disabled={viewState === "LOADING"}
                    showSpinner={viewState === "LOADING"}
                    submit
                />
            </div>
        </form>
    );
};
