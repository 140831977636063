import React, { useCallback, useRef } from "react";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { DA_FarmInTimeCartList } from "@templates/FarmInTime/components/Cart/components/List/List.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { useFarmInTimeProducts } from "@templates/FarmInTime/components/Cart/hooks/useProducts.hook";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useMergePrompt } from "@templates/FarmInTime/components/Cart/hooks/useMergePrompt.hook";
import { useCreateCartRequest } from "@templates/FarmInTime/components/Cart/hooks/useCreateCartRequest.hook";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_FarmInTimeCartProps } from "./Cart.interface";
import { useFarmInTimeQuickSelect } from "./contexts/FarmInTimeQuickSelectProvider";
import S from "./Cart.module.scss";

export const DA_FarmInTimeCart = (props: DA_FarmInTimeCartProps) => {
    const { getDictionaryString } = useTranslations();
    const { orderListProducts, farmInTimeListProducts, isFetching, prices } =
        useFarmInTimeProducts();
    const { orderLines, isLoadingCart } = useCart();
    const { scrollTo } = useScreen();
    const readMoreSectionRef = useRef<HTMLDivElement>();

    const { checkedCount, selectedProducts } = useFarmInTimeQuickSelect();

    const { showMergePrompt } = useMergePrompt(props.currentAddress, selectedProducts);
    const { createCart, awaitingCreateResponse } = useCreateCartRequest(
        props.currentAddress?.id,
        selectedProducts
    );

    const scrollToReadMore = useCallback(() => {
        scrollTo(readMoreSectionRef.current.getBoundingClientRect().y);
    }, [scrollTo]);

    const submitOrder = useCallback(() => {
        if (orderLines?.length > 0) {
            showMergePrompt();
        } else {
            createCart(undefined);
        }
    }, [createCart, orderLines?.length, showMergePrompt]);

    return (
        <div>
            <div className={S.actions}>
                <DA_ButtonLink
                    title={getDictionaryString("FarmInTimeOrderingReadMore")}
                    onClick={scrollToReadMore}
                />
            </div>

            <DA_ContentBox>
                <DA_FarmInTimeCartList
                    listTitle={getDictionaryString("FarmInTimeOrderingListTitle", {
                        uppercaseFirst: true,
                    })}
                    type="orderingList"
                    products={orderListProducts}
                    prices={prices.slice(0, orderListProducts.length)}
                    address={props.currentAddress}
                    isFetching={isFetching}
                />

                <DA_FarmInTimeCartList
                    className={S.farmInTimeList}
                    listTitle={getDictionaryString("FarmInTimeCartListTitle", {
                        uppercaseFirst: true,
                    })}
                    type="farmInTimeList"
                    products={farmInTimeListProducts}
                    prices={prices.slice(orderListProducts.length)}
                    address={props.currentAddress}
                    isFetching={isFetching}
                />

                <div className={S.buttonWrapper}>
                    <DA_Button
                        title={
                            checkedCount === 1
                                ? getDictionaryString("FarmInTimeSubmitButtonSingle")
                                : getDictionaryString("FarmInTimeSubmitButtonPlural", {
                                      COUNT: checkedCount,
                                  })
                        }
                        onClick={submitOrder}
                        disabled={
                            orderListProducts.length <= 0 && farmInTimeListProducts.length <= 0
                        }
                        //
                        // isLoadingCart:
                        // We don't know if the merge dialog should be shown
                        // unless we know if the cart holds any products
                        //
                        // awaitingCreateResponse:
                        // Show spinner while the Farm In Time cart is created
                        showSpinner={isLoadingCart || awaitingCreateResponse !== "IDLE"}
                    />
                </div>
            </DA_ContentBox>

            <section ref={readMoreSectionRef}>
                <DA_Text html={props.description} />
            </section>
        </div>
    );
};
