import React, { useRef } from "react";
import { useTranslations } from "../../../contexts/translations/translations.context";
import { DA_Text } from "../../atoms/Text/Text.component";
import { DA_Container } from "../../atoms/Container/Container.component";
import { DA_Image } from "../../atoms/Image/Image.component";
import { DA_Title } from "../../atoms/Title/Title.component";
import { DA_Button } from "../../atoms/Button/Button.component";
import { useAppData } from "../../../contexts/appData.context";
import S from "./PageNotFound.module.scss";

export interface DA_PageNotFoundProps {
    code: number;
}

export const DA_PageNotFound = (props: DA_PageNotFoundProps) => {
    const { currentSite } = useAppData();
    const { getDictionaryString, getTextItem } = useTranslations();

    const isDocumentUrl = document.location.href.includes("/documents/");
    const dictionaryPrefix = isDocumentUrl ? "document" : "page";

    const texts = useRef({
        headline:
            props.code === 404
                ? getDictionaryString(`${dictionaryPrefix} not found headline`)
                : "500 - Serverfejl",
        text:
            props.code === 404
                ? currentSite === "MYFARM"
                    ? getTextItem(`${dictionaryPrefix} not found`)
                    : getDictionaryString(`${dictionaryPrefix} not found`)
                : "Vi har åbenlyst et læk i systemet - noget gik galt på serveren.",
        button:
            props.code === 404
                ? getDictionaryString(`${dictionaryPrefix} not found button`, {
                      dontShowFallback: true,
                  })
                : "Gå til forsiden",
    });

    const goBack = () => (window.history.length === 1 ? window.close() : window.history.back());

    return (
        <DA_Container noVerticalPadding>
            <div className={S.container}>
                <div className={S.text}>
                    <DA_Title h1>{texts.current.headline}</DA_Title>

                    {typeof texts.current.text === "string" ? (
                        <DA_Text html={texts.current.text} />
                    ) : (
                        <DA_Text>{texts.current.text}</DA_Text>
                    )}

                    {texts.current.button && (
                        <DA_Button
                            title={texts.current.button}
                            href={isDocumentUrl ? undefined : "/"}
                            onClick={isDocumentUrl ? goBack : undefined}
                            conventionalRouting={props.code === 500}
                        />
                    )}
                </div>

                <div className={S.image}>
                    <DA_Image src={`/images/${props.code}.jpg`} alt="" />
                </div>
            </div>
        </DA_Container>
    );
};
