import React, { useMemo, useRef } from "react";
import { DA_Select } from "@danishagro/shared/src/components/atoms/Select/Select.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";

export interface DA_SortingSelectProps {
    fullWidth?: boolean;
    tabIndex?: number;
    alignMenuRight?: boolean;
}

export const DA_SortingSelect = ({
    fullWidth,
    tabIndex,
    alignMenuRight,
}: DA_SortingSelectProps) => {
    const { getDictionaryString } = useTranslations();
    const { sortingOptions, selectedSortingOption, setSelectedSortingOption } = useRelewise();

    const options = useMemo(() => {
        if (sortingOptions && sortingOptions.length > 0) {
            return sortingOptions.map((value) => ({
                value,
                label: getDictionaryString(`sorting.${value}`),
            }));
        } else {
            return [];
        }
    }, [getDictionaryString, sortingOptions]);

    const label = useRef(
        getDictionaryString("sortBy", {
            uppercaseFirst: true,
        })
    ).current;

    return (
        <DA_Select
            ariaLabel={label}
            placeholder={label}
            value={selectedSortingOption}
            options={options}
            onChange={({ value }) => setSelectedSortingOption(value)}
            tabIndex={tabIndex}
            alignMenuRight={alignMenuRight}
            fullWidth={fullWidth}
            small
        />
    );
};
