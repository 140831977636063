import React, { useCallback, useMemo, useRef, useState } from "react";
import { DA_Notification } from "../../../../interfaces/notification.interface";
import { useHeaderNotificationsApi } from "../../../../hooks/api/useHeaderNotificationsApi.hook";

interface NotificationsHook {
    notifications: DA_Notification[];
    notificationCount: number;
    viewState: string;
    fetchNotifications: () => void;
}

const NotificationsContext = React.createContext<NotificationsHook>({} as NotificationsHook);

type Props = {
    children: React.ReactNode;
};

export const NotificationsProvider = ({ children }: Props): JSX.Element => {
    const { getHeaderNotifications } = useHeaderNotificationsApi();
    // const { customerNumber, cvrAndCustomerNumbers } = useAppData();

    const [viewState, setViewState] = useState<string>("LOADING");
    const [notifications, setNotifications] = useState<DA_Notification[]>(null);

    const currentRequestRef = useRef<Promise<DA_Notification[]>>();

    const fetchUnreadNotifications = useCallback(async () => {
        const request = getHeaderNotifications(true, true);

        request.then((response) => {
            if (response && response.length > 0) {
                setNotifications(response);
                setViewState("DONE");
            } else {
                setNotifications(null);
                setViewState("EMPTY");
            }
        });

        request.catch(() => {
            setNotifications(null);
            setViewState("ERROR");
        });

        request.finally(() => {
            currentRequestRef.current = null;
        });

        return request;
    }, [getHeaderNotifications]);

    // Update

    const fetchNotifications = () => {
        if (typeof currentRequestRef.current === "undefined") {
            currentRequestRef.current = fetchUnreadNotifications();
        }
    };

    // Update every 60 seconds
    // NOTE: Removed for now because notifications isn't ready for launch.

    // useEffect(() => {
    //     let interval = undefined;

    //     if (cvrAndCustomerNumbers && customerNumber) {
    //         interval = setInterval(() => {
    //             currentRequestRef.current = fetchUnreadNotifications();
    //         }, 60000);
    //     }

    //     return () => clearInterval(interval);
    // }, [customerNumber, cvrAndCustomerNumbers, fetchUnreadNotifications]);

    // Init after 2 seconds

    // useEffect(() => {
    //     // Start off by resetting notifications
    //     // in case this is triggered by changing the customer number
    //     if (typeof currentRequestRef.current === "undefined") {
    //         setNotifications(null);
    //         if (cvrAndCustomerNumbers && customerNumber) {
    //             setTimeout(() => {
    //                 currentRequestRef.current = fetchUnreadNotifications();
    //             }, 2000);
    //         }
    //     }
    // }, [customerNumber, cvrAndCustomerNumbers, fetchUnreadNotifications]);

    // Mappers

    const notificationCount = useMemo(() => {
        return notifications ? notifications.length : 0;
    }, [notifications]);

    return (
        <NotificationsContext.Provider
            value={{
                viewState,
                notifications,
                notificationCount,
                fetchNotifications,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotifications = () => React.useContext<NotificationsHook>(NotificationsContext);
