import React, { useCallback, MouseEvent, useState, useEffect } from "react";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { DA_AddressOverlay } from "@danishagro/shared/src/components/organisms/AddressOverlay/AddressOverlay.component";
import { useAddressesApi } from "@danishagro/shared/src/hooks/api/useAddressesApi.hook";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { ModalSize } from "@danishagro/shared/src/contexts/modal.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { AddressOverlayViewState } from "@danishagro/shared/src/components/organisms/AddressOverlay/interfaces/AddressOverlayViewState.interface";
import { useDynamicOverlay } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useAddressFromSessionStorage } from "@danishagro/shared/src/hooks/useAddressFromSessionStorage.hook";

export const useDeliveryAddresses = (
    setAddresses?: React.Dispatch<React.SetStateAction<DA_Address[]>>
) => {
    const { showOverlay, closeOverlay } = useDynamicOverlay();
    const { getDictionaryString } = useTranslations();
    const { hasAddressInSessionStorage, setAddressInSessionStorage } =
        useAddressFromSessionStorage();
    const [allAddresses, setAllAddresses] = useState<DA_Address[]>([]);
    const { getAddressObject, currentAddressFromSessionStorage } = useAddressFromSessionStorage();
    const [_, setSelectedAddress] = useState<DA_Address | null>(null);
    const { getDeliveryAddresses } = useAddressesApi();
    const { saveDeliveryInfo, shippingDetails } = useCart();

    const [hasSaveDeliveryInfoBeenCalled, setHasSaveDeliveryInfoBeenCalled] =
        useState<boolean>(false);

    const [viewState, setViewState] = useState<string>("NONE");

    useEffect(() => {
        getDeliveryAddresses().then((response) => {
            setAllAddresses(response);
        });
    }, [getDeliveryAddresses]);

    const currentAddress = getAddressObject(allAddresses);

    function getShippingAddressAsDA_Address(shippingDetails) {
        return {
            id: "",
            locationId: "",
            name: shippingDetails.addressDescription,
            streetName: shippingDetails.streetName,
            streetNumber: shippingDetails.streetNumber,
            zipCode: shippingDetails.zipCode,
            city: shippingDetails.city,
            countryCode: "",
        };
    }

    useEffect(() => {
        if (shippingDetails) {
            setSelectedAddress(getShippingAddressAsDA_Address(shippingDetails));
        } else if (!shippingDetails && currentAddressFromSessionStorage) {
            const sessionAddress = getAddressObject(allAddresses, currentAddressFromSessionStorage);
            if (sessionAddress) {
                setSelectedAddress(sessionAddress);
            }
        } else if (!shippingDetails && currentAddress) {
            setSelectedAddress(currentAddress);
        }
    }, [
        allAddresses,
        getAddressObject,
        currentAddressFromSessionStorage,
        shippingDetails,
        currentAddress,
    ]);

    useEffect(() => {
        if (currentAddress && !hasSaveDeliveryInfoBeenCalled) {
            const result = {
                billingAddress: {
                    streetName: "",
                    streetNumber: "",
                    zipCode: "",
                    city: "",
                },
                shippingAddress: {
                    ...currentAddress,
                },
                shippingSameAsBilling: false,
            };

            // TODO: Refactor handling of this address logic
            saveDeliveryInfo(result)
                .then(() => {
                    setAddressInSessionStorage(result.shippingAddress.id);
                    setHasSaveDeliveryInfoBeenCalled(true);
                    setAddressInSessionStorage(result.shippingAddress.id);
                })
                .catch(() => {
                    // TODO: A JIRA task has been created to handle this flow better.
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        currentAddress,
        shippingDetails,
        saveDeliveryInfo,
        setAddressInSessionStorage,
        hasSaveDeliveryInfoBeenCalled,
        hasAddressInSessionStorage,
    ]);

    const onAddressOverlaySubmitted = useCallback(
        (changedShippingAddress: DA_Address) => {
            const result = {
                billingAddress: {
                    streetName: "",
                    streetNumber: "",
                    zipCode: "",
                    city: "",
                },
                shippingAddress: {
                    ...changedShippingAddress,
                },
                shippingSameAsBilling: false,
            };

            saveDeliveryInfo(result)
                .then(() => {
                    closeOverlay();
                    setAddressInSessionStorage(result.shippingAddress.id);
                })
                .catch(() => {
                    // TODO: A JIRA task has been created to handle this flow better.
                    closeOverlay();
                });
        },
        [closeOverlay, saveDeliveryInfo, setAddressInSessionStorage]
    );

    const onDeliveryAddressesSuccess = useCallback(
        (response: DA_Address[]) => {
            setViewState("NONE");

            showOverlay(
                {
                    content: (
                        <DA_AddressOverlay
                            addresses={response}
                            selectedAddress={currentAddress}
                            defaultViewState={AddressOverlayViewState.PickerAndCreateLink}
                            submitText={getDictionaryString("useAddress")}
                            onSubmit={onAddressOverlaySubmitted}
                            setAddresses={setAddresses}
                        />
                    ),
                    title: getDictionaryString("changeAddress"),
                    id: "delivery-addresses-overlay",
                },
                { modal: { size: ModalSize.LG } }
            );
        },
        [getDictionaryString, onAddressOverlaySubmitted, showOverlay, currentAddress, setAddresses]
    );

    const onDeliveryAddressesError = useCallback(() => {
        // TODO: A JIRA task has been created to handle this flow better.
    }, []);

    const onEditDeliveryAddressClicked = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event?.preventDefault();

            setViewState("LOADING");

            getDeliveryAddresses().then(onDeliveryAddressesSuccess).catch(onDeliveryAddressesError);
        },
        [getDeliveryAddresses, onDeliveryAddressesSuccess, onDeliveryAddressesError]
    );

    return {
        onEditDeliveryAddressClicked,
        deliveryStatusModalViewState: viewState,
    };
};
