import React, { useEffect, useState } from "react";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_SortingSelect } from "@molecules/SortingSelect/SortingSelect.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_Chip } from "@danishagro/shared/src/components/atoms/Chip/Chip.component";
import S from "./ProductListHeader.module.scss";

export interface ProductListHeaderProps {
    totalProductCount?: number;
}

export const ProductListHeader = ({ totalProductCount }: ProductListHeaderProps) => {
    const [hasSelectedFacets, setHasSelectedFacets] = useState(false);
    const { getDictionaryString } = useTranslations();
    const { selectedFacets, removeFilter, resetFilters } = useRelewise();
    const { isMobileOrTablet } = useScreen();

    useEffect(() => {
        if (selectedFacets) {
            setHasSelectedFacets(Object.values(selectedFacets).some((facets) => facets.length > 0));
        }
    }, [selectedFacets]);

    return (
        <>
            {!isMobileOrTablet && (
                <div className={S.resultsHeader}>
                    <div className={S.categoryFilter}>
                        {hasSelectedFacets ? (
                            <div className={S.selectedFilters}>
                                <div>
                                    {getDictionaryString("your filtering", {
                                        uppercaseFirst: true,
                                    })}
                                    :
                                </div>
                                {Object.keys(selectedFacets).map((facet) =>
                                    selectedFacets[facet].map((facetValue, facetIndex) => {
                                        const facetName = getDictionaryString(`facet.${facet}`);
                                        const chosenFilter = `${facetName}: ${facetValue}`;
                                        return (
                                            <DA_Chip
                                                key={`${facetIndex}--${facetValue}`}
                                                label={chosenFilter}
                                                removable
                                                onRemove={() => removeFilter(facet, facetValue)}
                                            />
                                        );
                                    })
                                )}
                                <div className={S.removeAllBtn}>
                                    <DA_ButtonLink
                                        title={getDictionaryString("reset filters", {
                                            uppercaseFirst: true,
                                        })}
                                        onClick={resetFilters}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <div className={S.sortingFilter}>
                        <div className={S.totalResults}>
                            {getDictionaryString("results", {
                                uppercaseFirst: true,
                            })}
                            : {totalProductCount}{" "}
                            {getDictionaryString(totalProductCount === 1 ? "product" : "products")}
                        </div>

                        <div className={S.sorting}>
                            <DA_SortingSelect alignMenuRight />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
