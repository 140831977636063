import React, { MouseEvent, useCallback, useMemo, useRef, useState } from "react";
import Tippy from "@tippyjs/react";
import { animated, useSpring } from "react-spring";
import { Placement } from "tippy.js";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useScreen } from "../../../contexts/screen.context";
import { DA_Icon, DA_IconNames } from "../Icon/Icon.component";
import S from "./Tooltip.module.scss";

export interface DA_TooltipProps {
    text: React.ReactNode;
    icon?: DA_IconNames;
    onClick?: () => void;
    className?: string;
    children?: React.ReactNode;
    placement?: Placement;
    textAlign?: "left" | "center" | "right";
}

export const DA_Tooltip = ({
    text,
    icon = DA_IconNames.Info,
    onClick,
    className,
    children,
    placement,
    textAlign = "left",
}: DA_TooltipProps) => {
    const [visible, setVisible] = useState(false);
    const arrowRef = useRef<HTMLDivElement>(null);
    const showDelay = useRef<NodeJS.Timeout | null>(null);

    const { isMobile } = useScreen();
    const initialAnimationSettings = useMemo(
        () => ({
            opacity: 0,
            config: { tension: 200, friction: 15 },
        }),
        []
    );
    const [popUpStyle, setSpring] = useSpring(() => initialAnimationSettings);

    // Determine the class for text alignment
    const getTextAlignmentClass = useMemo(() => {
        const alignmentClasses = {
            left: S.textAlignLeft,
            center: S.textAlignCenter,
            right: S.textAlignRight,
        };
        return alignmentClasses[textAlign] || S.textAlignLeft;
    }, [textAlign]);

    const show = useCallback((event) => {
        // Don't show tooltip on hover
        // unless the cursor has been hovering for 300 ms
        showDelay.current = setTimeout(
            () => setVisible(true),
            event.type === "mouseenter" ? 300 : 0
        );
    }, []);

    const hide = useCallback(() => {
        clearTimeout(showDelay.current);
        setVisible(false);
    }, []);

    const onMount = useCallback(() => {
        setSpring.start({
            ...initialAnimationSettings,
            opacity: 1,
        });
    }, [setSpring, initialAnimationSettings]);

    const onHide = useCallback(
        ({ unmount }) => {
            setSpring.start({ ...initialAnimationSettings, onRest: unmount });
        },
        [setSpring, initialAnimationSettings]
    );

    const onButtonClicked = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            // Either trigger passed in onClick
            if (typeof onClick !== "undefined") {
                onClick();
                return;
            }

            // Or show or hide the tooltip
            if (isMobile) {
                visible ? hide() : show(event);
            }
        },
        [hide, isMobile, onClick, show, visible]
    );

    return (
        <Tippy
            arrow={true}
            visible={visible}
            placement={placement || "auto"}
            onClickOutside={hide}
            onMount={onMount}
            onHide={onHide}
            popperOptions={{
                modifiers: [{ name: "arrow", options: { element: arrowRef.current } }],
            }}
            render={(attr) => (
                <animated.div
                    className={cn(S.popUp, getTextAlignmentClass)}
                    style={popUpStyle}
                    {...attr}
                >
                    {text}
                    <div className={S.arrow} ref={arrowRef} data-popper-arrow="" />
                </animated.div>
            )}
        >
            {children ? (
                <div className={cn(S.tooltipWithChildren, className)}>
                    {children}
                    <button
                        type="button"
                        onClick={onButtonClicked}
                        onMouseEnter={isMobile ? undefined : show}
                        onMouseLeave={isMobile ? undefined : hide}
                        onFocus={isMobile ? undefined : show}
                        onBlur={isMobile ? undefined : hide}
                        className={cn(S.tooltipButton, S.onTopOfChildren, className)}
                    />
                </div>
            ) : (
                <button
                    type="button"
                    onClick={onButtonClicked}
                    onMouseEnter={isMobile ? undefined : show}
                    onMouseLeave={isMobile ? undefined : hide}
                    onFocus={isMobile ? undefined : show}
                    onBlur={isMobile ? undefined : hide}
                    className={cn(S.tooltipButton, S.standAlone, className)}
                >
                    <DA_Icon name={icon} className={S.icon} />
                </button>
            )}
        </Tippy>
    );
};
