import React, { useMemo, useState } from "react";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { DA_TabItem } from "@danishagro/shared/src/components/molecules/Tab/Tab.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { FarmInTimeProductsProvider } from "@templates/FarmInTime/components/Cart/hooks/useProducts.hook";
import { DA_FarmInTimeCart } from "../components/Cart/Cart.component";
import { DA_FarmInTimeSettings } from "../components/Settings/Settings.component";
import { DA_FarmInTimeQuickSelectProvider } from "../components/Cart/contexts/FarmInTimeQuickSelectProvider";

export const useTabs = (currentDeliveryAddress: DA_Address) => {
    const { getDictionaryString } = useTranslations();
    const [orderListProducts] = useState([]);

    const tabs = useMemo(
        () => [
            {
                id: "BESTILLINGSLISTE",
                title: getDictionaryString("FarmInTimeCart"),
                content: (
                    <FarmInTimeProductsProvider currentAddress={currentDeliveryAddress}>
                        <DA_FarmInTimeQuickSelectProvider>
                            <DA_FarmInTimeCart
                                currentAddress={currentDeliveryAddress}
                                description={getDictionaryString("FarmInTimeCartDescription")}
                                orderingListProducts={orderListProducts}
                            />
                        </DA_FarmInTimeQuickSelectProvider>
                    </FarmInTimeProductsProvider>
                ),
            },
            {
                id: "FARM-IN-TIME",
                title: getDictionaryString("FarmInTimeSettings"),
                content: <DA_FarmInTimeSettings currentAddress={currentDeliveryAddress} />,
            },
            /* Temporarily hidden in MVP
            {
                id: "BEDRIFTSKATALOG",
                title: getDictionaryString("FarmInTimeCatalog"),
                content: (
                    <DA_FarmInTimeCatalog
                        currentAddress={currentDeliveryAddress}
                    />
                ),
            },
             */
        ],
        [getDictionaryString, currentDeliveryAddress, orderListProducts]
    );

    const [currentTab, setCurrentTab] = useState<DA_TabItem>(tabs?.[0]);

    const onTabChanged = (newCurrentTab: DA_TabItem) => {
        setCurrentTab(newCurrentTab);
    };

    return {
        tabs,
        currentTab,
        onTabChanged,
    };
};
