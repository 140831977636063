import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import {
    DA_CategoryNavigation,
    DA_CategoryNavigationProps,
} from "@molecules/CategoryNavigation/CategoryNavigation.component";
import { DA_CategoryLink } from "@interfaces/categoryLink.interface";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_LinkSlider } from "@molecules/LinkSlider/LinkSlider.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_FilteringMobileDrawer } from "@organisms/FilteringMobileDrawer/FilteringMobileDrawer.component";
import { useInView } from "react-intersection-observer";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_NewProductSimple } from "@danishagro/shared/src/interfaces/productv2.interface";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_SwitchElement } from "@danishagro/shared/src/components/atoms/SwitchElement/SwitchElement.component";
import { DA_CategoryProductList } from "@organisms/CategoryProductList/CategoryProductList.component";
import { useSearchParams } from "react-router-dom";
import { Relewise_ProductList } from "./Relewise.ProductList.component";
import { Relewise_Facets } from "./Relewise.Facets.component";
import { ProductListHeader } from "./components/ProductListHeader.component";
import S from "./Relewise.Category.module.scss";

export enum PaginationViewState {
    Initial = "INITIAL",
    None = "NONE",
    Full = "FULL",
    Loading = "LOADING",
}
export interface Relewise_CategoryProps extends Omit<DA_CategoryNavigationProps, "heading"> {
    categoryId: string;
    facetGroups?: string[];
    enableQuickOrder?: boolean;
    subCategories?: DA_CategoryLink[];
}

export const Relewise_Category = ({
    title,
    categoryId,
    subCategories,
    enableQuickOrder,
    ...navigationProps
}: Relewise_CategoryProps) => {
    const [showFacets] = useState(true);
    const [paginationViewState, setPaginationViewState] = useState<string>(
        PaginationViewState.Initial
    );

    const [initialLoading, setInitialLoading] = useState<boolean>(true);

    const {
        fetchProducts,
        pageCount,
        facets,
        products,
        totalProductCount,
        selectedFacets,
        selectedSortingOption,
        page,
        pageSize,
        initialDefaultPageSize,
        initialQuickOrderPageSize,
        resetFilters,
        setPage,
        setPageSize,
        handleProductResponse,
    } = useRelewise();
    const { isMobileOrTablet } = useScreen();
    const { getDictionaryString } = useTranslations();
    const hasChildren = useMemo(() => subCategories && subCategories.length > 0, [subCategories]);

    const checkForVetMedication = (products: DA_NewProductSimple[]) => {
        // Iterate over each object in the array
        for (const obj of products) {
            // Check if both vetMedicalLink.value and vetMedicationLogo.value have values
            if (obj.data?.vetMedicalLink?.value && obj.data?.vetMedicationLogo?.value) {
                return true; // Return true if both have values
            }
        }
        return false; // Return false if no object has both values
    };
    const hasVetMedication = checkForVetMedication(products);

    // Get vetMedication Logo
    const getVetMedicationLogo = (data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].data.vetMedicationLogo) {
                return data[i].data.vetMedicationLogo;
            }
        }
        return null; // Return null if vetMedicationLogo is not found in any object
    };
    const vetMedicationLogo = getVetMedicationLogo(products);

    // Get vedMedication Link
    const getVetMedicationLink = (data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].data?.vetMedicalLink) {
                return data[i].data?.vetMedicalLink;
            }
        }
        return null; // Return null if vetMedicationLogo is not found in any object
    };
    const vetMedicationLink = getVetMedicationLink(products);

    const [searchParams, setSearchParams] = useSearchParams();
    const hasQuickOrderParam = searchParams.has("quickorder");
    const [showQuickerOrder, setShowQuickerOrder] = useState(
        () => JSON.parse(localStorage.getItem("quickOrder")) || false
    );

    const { ref: bottomDiv, inView } = useInView({
        rootMargin: "200px",
    });

    const initialRequest = useRef(true);

    const loadMore = useCallback(() => {
        if (page < pageCount) {
            setPaginationViewState(PaginationViewState.Loading);
            setPage(page + 1);
            fetchProducts(page + 1).then((response) => {
                handleProductResponse(response, true);
                setPaginationViewState(PaginationViewState.None);
            });
        }
    }, [fetchProducts, handleProductResponse, page, pageCount, setPage]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (enableQuickOrder && showQuickerOrder && !hasQuickOrderParam) {
            params.set("quickorder", "true");
            setPageSize(initialQuickOrderPageSize);
            setSearchParams(params);

            if (pageSize < initialQuickOrderPageSize) {
                setPaginationViewState(PaginationViewState.Loading);
                fetchProducts(1, initialQuickOrderPageSize).then((response) => {
                    handleProductResponse(response, false);
                    setPaginationViewState(PaginationViewState.None);
                });
            }
        }

        if ((!enableQuickOrder || !showQuickerOrder) && hasQuickOrderParam) {
            params.delete("quickorder");
            setPageSize(initialDefaultPageSize);
            setSearchParams(params);
        }
    }, [
        enableQuickOrder,
        showQuickerOrder,
        hasQuickOrderParam,
        searchParams,
        setSearchParams,
        setPageSize,
        fetchProducts,
        handleProductResponse,
        pageSize,
        initialQuickOrderPageSize,
        initialDefaultPageSize,
    ]);

    useEffect(() => {
        localStorage.setItem("quickOrder", JSON.stringify(showQuickerOrder));
    }, [showQuickerOrder]);

    useEffect(() => {
        if (page === pageCount) {
            setPaginationViewState(PaginationViewState.Full);
        }
    }, [page, pageCount, paginationViewState]);

    useEffect(() => {
        if (inView && paginationViewState === PaginationViewState.None && pageCount > page) {
            loadMore();
        }
    }, [inView, loadMore, page, pageCount, paginationViewState, setPage]);

    useEffect(() => {
        if (!initialRequest.current) {
            setPaginationViewState(PaginationViewState.Loading);

            //Always reset to page 1 on sorting or facetting
            setPage(1);
            fetchProducts(1).then((response) => {
                handleProductResponse(response, false);
                setPaginationViewState(PaginationViewState.None);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacets, selectedSortingOption]);

    useEffect(() => {
        if (!initialRequest.current && paginationViewState === PaginationViewState.None) {
            setPaginationViewState(PaginationViewState.Loading);
            fetchProducts().then((response) => {
                handleProductResponse(response, false);
                setPaginationViewState(PaginationViewState.None);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentPageSize = params.has("quickorder")
            ? initialQuickOrderPageSize
            : initialDefaultPageSize;

        if (params.has("quickorder")) {
            setPageSize(initialQuickOrderPageSize);
        }

        fetchProducts(1, currentPageSize).then((response) => {
            handleProductResponse(response, false);
            setPaginationViewState(PaginationViewState.None);
            initialRequest.current = false;
            setInitialLoading(false);
        });

        const handleUnload = () => {
            resetFilters();
        };
        window.addEventListener("unload", handleUnload);
        return () => {
            window.removeEventListener("unload", handleUnload);
            handleUnload();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <article>
            <div className={S.titleWrapper}>
                <div className={S.categoryHeading}>
                    {title ? (
                        <DA_Title fullWidth h1>
                            {title}
                        </DA_Title>
                    ) : null}
                </div>

                {enableQuickOrder && !isMobileOrTablet && (
                    <div className={S.toggleWrapper}>
                        <label htmlFor="switchQuickOrder">
                            <span>{getDictionaryString("categoryquickorder")}</span>
                        </label>
                        <DA_SwitchElement
                            id="switchQuickOrder"
                            checked={showQuickerOrder}
                            onChange={(enabled) => {
                                setShowQuickerOrder(enabled);
                            }}
                        />
                    </div>
                )}
            </div>

            <div className={cn(S.container, initialLoading && S.dimmed)}>
                <div className={S.innerContainer}>
                    {initialLoading ? (
                        <div />
                    ) : enableQuickOrder && showQuickerOrder ? (
                        <>
                            {hasChildren ? (
                                <DA_CategoryProductList
                                    items={products}
                                    {...navigationProps}
                                    relatedCategoryLinks={subCategories}
                                    topCategoryLink={navigationProps.relatedCategoryLinks.find(
                                        (cat) => cat.currentPage
                                    )}
                                />
                            ) : (
                                <DA_CategoryProductList
                                    items={products}
                                    {...navigationProps}
                                    topCategoryLink={{
                                        id: "group0",
                                        name: navigationProps.parentCategoryName,
                                        url: navigationProps.parentCategoryLink,
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <section className={S.content}>
                            <div className={S.menuWrapper}>
                                <div className={S.menu}>
                                    {hasChildren && isMobileOrTablet ? (
                                        <DA_LinkSlider
                                            title={getDictionaryString("other categories")}
                                            items={subCategories}
                                        />
                                    ) : hasChildren ? (
                                        <DA_CategoryNavigation
                                            heading={getDictionaryString("categories")}
                                            title={title}
                                            {...navigationProps}
                                            relatedCategoryLinks={subCategories}
                                        />
                                    ) : isMobileOrTablet ? (
                                        <DA_LinkSlider
                                            title={getDictionaryString("other categories")}
                                            items={navigationProps.relatedCategoryLinks.filter(
                                                ({ currentPage }) => !currentPage
                                            )}
                                        />
                                    ) : (
                                        <DA_CategoryNavigation
                                            heading={getDictionaryString("categories")}
                                            title={navigationProps.parentCategoryName}
                                            {...navigationProps}
                                        />
                                    )}

                                    {showFacets && facets && !isMobileOrTablet ? (
                                        <Relewise_Facets facets={facets} />
                                    ) : null}
                                </div>
                            </div>

                            <div className={S.results}>
                                {products.length > 0 ? (
                                    <>
                                        {hasVetMedication && (
                                            <DA_BasicLink
                                                className={cn(S.vetMedicationLink)}
                                                href={vetMedicationLink?.value}
                                            >
                                                <DA_Image
                                                    src={vetMedicationLogo?.value}
                                                    width={120}
                                                    alt={getDictionaryString(
                                                        "VetMedicationImageAltText"
                                                    )}
                                                    className={S.vetMedicationImage}
                                                />
                                            </DA_BasicLink>
                                        )}
                                        <ProductListHeader totalProductCount={totalProductCount} />
                                        <Relewise_ProductList
                                            products={products}
                                            categoryId={categoryId}
                                        />

                                        <div
                                            className={
                                                paginationViewState === PaginationViewState.Full
                                                    ? S.loadMoreWrapperFull
                                                    : S.loadMoreWrapper
                                            }
                                        >
                                            {paginationViewState === PaginationViewState.Loading ? (
                                                <DA_Spinner className={S.spinner} />
                                            ) : null}

                                            {paginationViewState === PaginationViewState.None ? (
                                                <DA_Button
                                                    onClick={() => loadMore()}
                                                    ref={bottomDiv}
                                                    title={getDictionaryString("loadMoreProducts")}
                                                />
                                            ) : null}
                                        </div>
                                    </>
                                ) : (
                                    <div className={S.placeholder}>
                                        <DA_Icon name={DA_IconNames.Leaf} className={S.leaf} />
                                        <DA_Title h3 tag={DA_TitleTag.P}>
                                            {getDictionaryString("no products")}
                                        </DA_Title>
                                    </div>
                                )}
                            </div>
                        </section>
                    )}

                    {isMobileOrTablet && <DA_FilteringMobileDrawer facets={facets} />}
                </div>
            </div>
        </article>
    );
};
