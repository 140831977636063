import React, { useEffect, useState } from "react";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { getPage } from "@helpers/getPage.helper";
import { DA_NewProduct } from "@danishagro/shared/src/interfaces/productv2.interface";
import { DA_NewCategoryPageData } from "@interfaces/newCategoryPageData.interface";
import { DA_ProductPage } from "./Product.page";
import { DA_CategoryPage } from "./Category.page";
import { DA_NotFoundPage } from "./NotFound.page";

export const DA_PlaceholderPage = React.memo(() => {
    const [pageData, setPageData] = useState<{
        pageAlias: string;
        [key: string]: any;
    }>();
    const [newPageData, setNewPageData] = useState<DA_NewProduct>(undefined);
    const [showError, setShowError] = useState(false);
    const [dataFetched, setDataFetched] = useState(false); // Tracks if an attempt to fetch data has been made

    const { currentPathDetails, pageIsLoading, setPageIsLoading } = usePage();

    useEffect(() => {
        if (currentPathDetails.length) {
            setPageIsLoading(true);
            setShowError(false);

            getPage()
                .then((response) => {
                    setPageData(response);
                    setDataFetched(true); // Set to true upon successful fetch
                    setNewPageData(response.product);
                })
                .catch((error) => {
                    setPageData(undefined);
                    setShowError(true);
                    setDataFetched(true); // Also set to true upon fetch failure
                    console.log(error);
                })
                .finally(() => {
                    setPageIsLoading(false);
                });
        }
    }, [currentPathDetails, setPageIsLoading]);

    return (
        <>
            {showError ? (
                <div>Something went wrong</div>
            ) : pageData?.pageAlias === "ProductGroup" ? (
                <DA_CategoryPage
                    key={(pageData as DA_NewCategoryPageData).groupProduct.id}
                    pageData={pageData as DA_NewCategoryPageData}
                />
            ) : pageData?.pageAlias === "Product" ? (
                <DA_ProductPage pageData={newPageData} />
            ) : !pageIsLoading && dataFetched ? (
                <DA_NotFoundPage />
            ) : null}
        </>
    );
});

DA_PlaceholderPage.displayName = "DA_PlaceholderPage";
