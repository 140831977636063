import React from "react";
import { CheckoutSteps } from "@interfaces/checkoutParams.interface";
import { DA_ErrorBoundary } from "@danishagro/shared/src/components/molecules/ErrorBoundary/ErrorBoundary.component";
import { DA_SteppedCheckoutContactInformation } from "./components/ContactInformation/ContactInformation.component";
import { DA_SteppedCheckoutPaymentMethod } from "./components/PaymentMethod/PaymentMethod.component";
import { DA_SteppedCheckoutProps } from "./SteppedCheckout.interface";
import { DA_SteppedCheckoutShippingInformation } from "./components/ShippingInformation/ShippingInformation.component";

export const DA_SteppedCheckout = (props: DA_SteppedCheckoutProps) => (
    <DA_ErrorBoundary featureName="Stepped Checkout" featureVersion="v0">
        {props.currentStep === CheckoutSteps.Contact ? (
            <DA_SteppedCheckoutContactInformation />
        ) : null}

        {props.currentStep === CheckoutSteps.Shipping ? (
            <DA_SteppedCheckoutShippingInformation />
        ) : null}

        {props.currentStep === CheckoutSteps.Payment ? <DA_SteppedCheckoutPaymentMethod /> : null}
    </DA_ErrorBoundary>
);
