import React from "react";
import { ModalSize } from "@danishagro/shared/src/contexts/modal.context";
import { DA_MergePrompt } from "@templates/FarmInTime/components/Cart/components/MergePrompt/MergePrompt.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { useDynamicOverlay } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { SelectedProductsType } from "../contexts/FarmInTimeQuickSelectProvider";

export const useMergePrompt = (
    currentAddress: DA_Address,
    selectedProducts?: SelectedProductsType
) => {
    const { showOverlay } = useDynamicOverlay();
    const { getDictionaryString } = useTranslations();

    const showMergePrompt = () => {
        showOverlay(
            {
                content: (
                    <DA_MergePrompt
                        addressId={currentAddress.id}
                        selectedProducts={selectedProducts}
                    />
                ),
                id: "merge-prompt",
                title: getDictionaryString("CartMergeModalTitle"),
            },
            {
                bottomSheet: {
                    snapPoints: ({ minHeight, maxHeight }) => [minHeight, maxHeight],
                },
                modal: {
                    size: ModalSize.LG,
                },
            }
        );
    };

    return {
        showMergePrompt,
    };
};
