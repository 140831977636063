import React, { FormEvent, useCallback, useState } from "react";
import { DA_DynamicOverlayWrapper } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_TextArea } from "@danishagro/shared/src/components/atoms/TextArea/TextArea.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { DA_Alert } from "@danishagro/shared/src/components/atoms/Alert/Alert.component";
import { DA_ViewStates } from "@danishagro/shared/src/content/viewState/viewStates";
import { DriverMessageModalProps } from "../DriverMessageModal/DriverMessageModal.interface";
import S from "./DriverMessageBottomSheet.module.scss";

export const DA_DriverMessageBottomSheet = (props: DriverMessageModalProps) => {
    const { getDictionaryString } = useTranslations();
    const { customerNumber } = useAppData();
    const { putFarmInTimeDriverMessageByAddress } = useFarmInTimeApi();

    const [viewState, setviewState] = useState<DA_ViewStates>(DA_ViewStates.INIT);

    const onFormSubmitted = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            const form = new FormData(event.target as HTMLFormElement);
            const body = {} as { driverMessage: string };

            form.forEach((value, key) => (body[key] = value));

            setviewState(DA_ViewStates.SAVING);

            try {
                await putFarmInTimeDriverMessageByAddress(
                    customerNumber,
                    props.currentAddress?.id,
                    body
                );

                props.onSubmit(body?.driverMessage);
            } catch {
                setviewState(DA_ViewStates.ERROR);
            }
        },
        [customerNumber, props, putFarmInTimeDriverMessageByAddress]
    );

    const onCancelButtonClicked = useCallback(() => {
        props.onCancel();
    }, [props]);

    return (
        <DA_DynamicOverlayWrapper
            content={
                <>
                    <form noValidate onSubmit={onFormSubmitted} id="driverMessageForm">
                        <div className={S.content}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: getDictionaryString("DriverMessageModalDescription"),
                                }}
                            />

                            {/** Name */}
                            <DA_TextArea
                                autoComplete="off"
                                name="driverMessage"
                                className={S.inputWrapper}
                                inputClassName={S.input}
                                defaultValue={props?.message}
                                id="driverMessage"
                            />
                        </div>
                    </form>
                    {/** Server error */}
                    {viewState === DA_ViewStates.ERROR && (
                        <div className={S.error}>
                            <DA_Alert
                                content={getDictionaryString("DriverMessageModalError")}
                                type="error"
                                hideCloseBtn
                            />
                        </div>
                    )}
                </>
            }
            footer={
                <div className={S.buttons}>
                    {/** Submit */}
                    <DA_Button
                        title={getDictionaryString("DriverMessageModalSubmit")}
                        disabled={viewState === DA_ViewStates.SAVING}
                        className={S.button}
                        showSpinner={viewState === DA_ViewStates.SAVING}
                        form="driverMessageForm"
                        submit
                    />

                    {/** Cancel */}
                    <DA_Button
                        title={getDictionaryString("DriverMessageModalCancel")}
                        className={S.button}
                        onClick={onCancelButtonClicked}
                        isGhost
                    />
                </div>
            }
        />
    );
};
