import { useState } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { ShippingInformationFormData } from "../interfaces/shippingInformationFormData.interface";

export const useValidation = () => {
    const { getDictionaryString } = useTranslations();

    const [validationErrorMessages, setValidationErrorMessages] = useState<
        { key: string; value: string }[]
    >([]);

    const onInputChange = (key: string | string[]) => {
        let result = [...validationErrorMessages];

        if (Array.isArray(key)) {
            result = result.filter((x) => !key.includes(x.key));
        } else {
            result = result.filter((x) => x.key !== key);
        }

        setValidationErrorMessages(result);
    };

    const validate = (body: ShippingInformationFormData) => {
        const tmpValidationErrorMessages = [];

        ////////////////////////
        /// Shipping Address ///
        ////////////////////////

        // Street Name: Mandatory
        if (!body["shipping_streetName"] || body["shipping_streetName"]?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "shipping_streetName",
                value: getDictionaryString("CheckoutValidationAddressLine1Required"),
            });
        }

        // Street Number: Mandatory
        if (!body["shipping_streetNumber"] || body["shipping_streetNumber"]?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "shipping_streetNumber",
                value: getDictionaryString("CheckoutValidationAddressLine2Required"),
            });
        }

        // Zip Code: Mandatory
        if (!body["shipping_zipCode"] || body["shipping_zipCode"]?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "shipping_zipCode",
                value: getDictionaryString("CheckoutValidationPostalCodeRequired"),
            });
        }

        // City: Mandatory
        if (!body["shipping_city"] || body["shipping_city"]?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "shipping_city",
                value: getDictionaryString("CheckoutValidationCityRequired"),
            });
        }

        ///////////////////////
        /// Billing Address ///
        ///////////////////////

        if (body["shippingSameAsBilling"] !== "true") {
            // Street Name: Mandatory
            if (!body["billing_streetName"] || body["billing_streetName"]?.length === 0) {
                tmpValidationErrorMessages.push({
                    key: "billing_streetName",
                    value: getDictionaryString("CheckoutValidationAddressLine1Required"),
                });
            }

            // Street Number: Mandatory
            if (!body["billing_streetNumber"] || body["billing_streetNumber"]?.length === 0) {
                tmpValidationErrorMessages.push({
                    key: "billing_streetNumber",
                    value: getDictionaryString("CheckoutValidationAddressLine2Required"),
                });
            }

            // Zip Code: Mandatory
            if (!body["billing_zipCode"] || body["billing_zipCode"]?.length === 0) {
                tmpValidationErrorMessages.push({
                    key: "billing_zipCode",
                    value: getDictionaryString("CheckoutValidationPostalCodeRequired"),
                });
            }

            // City: Mandatory
            if (!body["billing_city"] || body["billing_city"]?.length === 0) {
                tmpValidationErrorMessages.push({
                    key: "billing_city",
                    value: getDictionaryString("CheckoutValidationCityRequired"),
                });
            }
        }

        setValidationErrorMessages(tmpValidationErrorMessages);

        return tmpValidationErrorMessages.length === 0;
    };

    return {
        validationErrorMessages,
        validate,
        onInputChange,
    };
};
