import { useCallback, useEffect, useMemo, useState } from "react";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";

export const useMonths = () => {
    const { getDictionaryString } = useTranslations();
    const { getFarmInTimeMonths } = useFarmInTimeApi();

    const [months, setMonths] = useState<string[]>();
    const [currentMonth, setCurrentMonth] = useState<string>();
    const [viewState, setViewState] = useState<string>("LOADING");
    const [hasMonthError, setMonthError] = useState<boolean>(false);

    // Mappers

    const monthOptions: { label: string; value: string }[] = useMemo(() => {
        const result = [];

        months?.forEach((month, index) => {
            result.push({
                value: (index + 1).toString(),
                label: getDictionaryString(month),
            });
        });

        return result;
    }, [months, getDictionaryString]);

    const currentMonthOption = useMemo(
        () => monthOptions.find((x) => x.value === currentMonth),
        [monthOptions, currentMonth]
    );

    // Fetch months

    const fetchMonths = useCallback(async () => {
        const response = await getFarmInTimeMonths();

        if (response?.[0]) {
            setMonths(response);
        }

        return response;
    }, [getFarmInTimeMonths]);

    // Update

    const onMonthSelectChanged = (option: { label: string; value: string }) => {
        setMonthError(false);
        setCurrentMonth(option?.value);
    };

    // Init

    useEffect(() => {
        fetchMonths()
            .then((response) => {
                if (response && response.length > 0) {
                    setViewState("DONE");
                } else {
                    setViewState("ERROR");
                }
            })
            .catch(() => {
                setViewState("ERROR");
            });
    }, [fetchMonths]);

    return {
        months,
        monthOptions,
        currentMonth,
        currentMonthOption,
        onMonthSelectChanged,
        viewState,
        hasMonthError,
        setMonthError,
    };
};
