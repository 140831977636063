import React, { useRef, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Input } from "@danishagro/shared/src/components/atoms/Input/Input.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_DynamicOverlayWrapper } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import S from "./AddNewContact.module.scss";

export const AddNewContact = ({
    onSubmit,
}: {
    onSubmit: (name: string, email: string, phone: string) => void;
}) => {
    const { getDictionaryString } = useTranslations();
    const [nameError, setNameError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [phoneError, setPhoneError] = useState<string | null>(null);

    const newContactNameElementRef = useRef<HTMLInputElement>();
    const newContactEmailElementRef = useRef<HTMLInputElement>();
    const newContactPhoneElementRef = useRef<HTMLInputElement>();

    const handleNameChange = () => {
        if (nameError) {
            setNameError(null);
        }
    };

    const handleEmailChange = () => {
        if (emailError) {
            setEmailError(null);
        }
    };

    const handlePhoneChange = () => {
        if (phoneError) {
            setPhoneError(null);
        }
    };

    const handleValidation = () => {
        const name = newContactNameElementRef.current?.value;
        const email = newContactEmailElementRef.current?.value;
        const phone = newContactPhoneElementRef.current?.value;

        let isValid = true;

        if (!name) {
            setNameError(
                getDictionaryString("NotificationServiceMobileAddContactValidationNameRequired")
            );
            isValid = false;
        } else {
            setNameError(null);
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            setEmailError(
                getDictionaryString("NotificationServiceMobileAddContactValidationEmailRequired")
            );
            isValid = false;
        } else {
            setEmailError(null);
        }

        const phoneRegex = /^\+45\d{8}$/;
        if (!phone || !phoneRegex.test(phone)) {
            setPhoneError(
                getDictionaryString("NotificationServiceMobileAddContactValidationPhoneValidation")
            );
            isValid = false;
        } else {
            setPhoneError(null);
        }

        if (isValid) {
            onSubmit(name, email, phone);
        }
    };

    return (
        <DA_DynamicOverlayWrapper
            content={
                <>
                    <DA_Input
                        label={getDictionaryString("NotificationServiceModalName", {
                            uppercaseFirst: true,
                        })}
                        id="invite-name"
                        type="text"
                        className={S.input}
                        ref={newContactNameElementRef}
                        onChange={handleNameChange}
                        helperText={nameError ? nameError : ""}
                        invalid={!!nameError}
                    />

                    <DA_Input
                        label={getDictionaryString("NotificationServiceModalEmail", {
                            uppercaseFirst: true,
                        })}
                        id="invite-email"
                        type="email"
                        className={S.input}
                        ref={newContactEmailElementRef}
                        onChange={handleEmailChange}
                        helperText={emailError ? emailError : ""}
                        invalid={!!emailError}
                    />

                    <DA_Input
                        label={getDictionaryString("NotificationServiceModalPhone", {
                            uppercaseFirst: true,
                        })}
                        id="invite-phone"
                        type="phone"
                        className={S.input}
                        ref={newContactPhoneElementRef}
                        onChange={handlePhoneChange}
                        helperText={phoneError ? phoneError : ""}
                        invalid={!!phoneError}
                    />
                </>
            }
            footer={
                <div className={S.addNewContactButtons}>
                    <DA_Button
                        title={getDictionaryString("NotificationServiceModalSubmit")}
                        className={S.addNewContactButton}
                        onClick={() => {
                            handleValidation();
                        }}
                    />
                </div>
            }
        />
    );
};
