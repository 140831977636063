import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { DA_Toast } from "@danishagro/shared/src/components/molecules/Toast/Toast.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useDynamicOverlay } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { SelectedProductsType } from "../contexts/FarmInTimeQuickSelectProvider";

export const useCreateCartRequest = (addressId: string, selectedProducts: SelectedProductsType) => {
    const { getDictionaryString } = useTranslations();
    const navigate = useNavigate();
    const { customerNumber } = useAppData();
    const { closeOverlay } = useDynamicOverlay();
    const { resetCart } = useCart();
    const { createFarmInTimeCartWithSelectionV2 } = useFarmInTimeApi();

    const [awaitingCreateResponse, setAwaitingCreateResponse] = useState<
        "IDLE" | "MERGE" | "NO-MERGE"
    >("IDLE");

    const quickSelectSelectedProducts = useMemo(() => {
        if (!selectedProducts) {
            return [];
        }

        const includedKeysOrderingList = Object.keys(
            selectedProducts?.data?.orderingList ?? {}
        ).filter((key) => selectedProducts?.data?.orderingList?.[key] === true);
        const includedKeysFarmInTimeList = Object.keys(
            selectedProducts?.data?.farmInTimeList ?? {}
        ).filter((key) => selectedProducts?.data?.farmInTimeList?.[key] === true);

        return [...includedKeysOrderingList, ...includedKeysFarmInTimeList];
    }, [selectedProducts]);

    const createCart = useCallback(
        (mergeWithOrderSecret: boolean) => {
            setAwaitingCreateResponse(mergeWithOrderSecret ? "MERGE" : "NO-MERGE");
            createFarmInTimeCartWithSelectionV2(
                customerNumber,
                addressId,
                mergeWithOrderSecret,
                quickSelectSelectedProducts
            )
                .then((response) => {
                    closeOverlay();

                    toast.custom(() => (
                        <DA_Toast
                            href={`/bestillingsliste/checkout?cart=${response.id}`}
                            goTo={getDictionaryString("ToastShowCart")}
                            message={getDictionaryString("ToastAddedToCartText")}
                        />
                    ));

                    if (mergeWithOrderSecret) {
                        resetCart();
                    }

                    localStorage.removeItem(`fit-quick-select:${customerNumber}`);

                    navigate(`/bestillingsliste/checkout?cart=${response.id}`);
                })
                .catch((error) => console.log(error))
                .finally(() => {
                    setAwaitingCreateResponse("IDLE");
                });
        },
        [
            createFarmInTimeCartWithSelectionV2,
            customerNumber,
            addressId,
            closeOverlay,
            resetCart,
            navigate,
            getDictionaryString,
            quickSelectSelectedProducts,
        ]
    );

    return { createCart, awaitingCreateResponse };
};
