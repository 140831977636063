/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Input } from "@danishagro/shared/src/components/atoms/Input/Input.component";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { DA_Alert } from "@danishagro/shared/src/components/atoms/Alert/Alert.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { generateUniqueId } from "@danishagro/shared/src/helpers/generateUniqueId.helper";
import { useNotificationServiceModal } from "../../hooks/useNotificationServiceModal";
import { ContactRow } from "../interfaces/contactRow.interface";
import { DA_NotificationServiceViewState } from "../enums/viewStates";
import { NotificationServiceModalProps } from "./NotificationServiceModal.interface";
import { useValidation } from "./hooks/useValidation.hook";
import S from "./NotificationServiceModal.module.scss";

export const NotificationServiceModal = (props: NotificationServiceModalProps) => {
    const { getDictionaryString, getDictionaryItem } = useTranslations();

    const mappedContacts: ContactRow[] = useMemo(() => {
        const data = props.defaultContacts ?? [{ name: "", phone: "", email: "" }];

        let result = [];

        result = data.map((contact) => {
            return {
                ...contact,
                id: generateUniqueId(),
            };
        });

        return result;
    }, [props.defaultContacts]);

    const [contactRows, setContactRows] = useState<ContactRow[]>(mappedContacts);
    const { validationErrorMessages, onInputChange, getError, validate } = useValidation(
        contactRows.length
    );
    const { onFormSubmitted, onCancelButtonClicked, viewState } = useNotificationServiceModal(
        validate,
        props.currentAddress,
        props.onSubmit,
        props.onCancel
    );

    const viewStateEnum: DA_NotificationServiceViewState =
        viewState as DA_NotificationServiceViewState;

    const addInputLine = useCallback(() => {
        setContactRows((prev) => {
            const result = prev ? [...prev] : [];

            result.push({ id: generateUniqueId(), name: "", phone: "", email: "" });

            return result;
        });
    }, []);

    const onDeleteLine = useCallback((givenId: string) => {
        setContactRows((prev) => {
            let result = prev ? [...prev] : [];

            result = [...result.filter((row) => row.id !== givenId)];

            console.log(result.length);

            return result;
        });
    }, []);

    return (
        <DA_Modal title={getDictionaryString("NotificationServiceModalHeading")}>
            <DA_Text>{getDictionaryItem("NotificationServiceModalDescription")}</DA_Text>
            <form onSubmit={onFormSubmitted} noValidate>
                {contactRows.length === 0 ? (
                    <DA_Text bold noPadding>
                        {getDictionaryString("noContactsAdded")}
                    </DA_Text>
                ) : (
                    <div className={S.content}>
                        {contactRows.map((row, index) => {
                            const nameError = getError("name", index);
                            const emailError = getError("email", index);
                            const phoneError = getError("phone", index);

                            return (
                                <div key={row.id} className={S.inputLine}>
                                    {/** Name */}
                                    <DA_Input
                                        autoComplete={`name-${index}`}
                                        className={S.input}
                                        defaultValue={row?.name}
                                        name={`name-${index}`}
                                        id={`name-${row.id}`}
                                        invalid={!!nameError}
                                        label={
                                            index === 0
                                                ? `${getDictionaryString(
                                                      "NotificationServiceModalName"
                                                  )}*`
                                                : undefined
                                        }
                                        onChange={() => {
                                            onInputChange("name", index);
                                        }}
                                    />

                                    {/** E-mail */}
                                    <DA_Input
                                        autoComplete={`email-${index}`}
                                        type="email"
                                        className={S.input}
                                        defaultValue={row?.email}
                                        name={`email-${index}`}
                                        id={`email-${row.id}`}
                                        invalid={!!emailError}
                                        label={
                                            index === 0
                                                ? `${getDictionaryString(
                                                      "NotificationServiceModalEmail"
                                                  )}*`
                                                : undefined
                                        }
                                        onChange={() => {
                                            onInputChange("email", index);
                                        }}
                                    />

                                    {/** Phone */}
                                    <DA_Input
                                        autoComplete={`phone-${index}`}
                                        className={S.input}
                                        type="tel"
                                        defaultValue={row?.phone}
                                        name={`phone-${index}`}
                                        id={`phone-${row.id}`}
                                        invalid={!!phoneError}
                                        label={
                                            index === 0
                                                ? `${getDictionaryString(
                                                      "NotificationServiceModalPhone"
                                                  )}*`
                                                : undefined
                                        }
                                        onChange={() => {
                                            onInputChange("phone", index);
                                        }}
                                    />

                                    {/** Delete Button */}
                                    <button
                                        type="button"
                                        className={S.removeButton}
                                        onClick={() => onDeleteLine(row.id)}
                                    >
                                        <DA_Icon
                                            name={DA_IconNames.Trash}
                                            className={cn(S.removeIcon)}
                                        />
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                )}
                <div className={S.inputAddressButton}>
                    <DA_ButtonLink
                        title={getDictionaryString("addContact")}
                        onClick={addInputLine}
                        icon={DA_IconNames.Add}
                        type="button"
                    />
                </div>

                <div className={S.buttons}>
                    {/** Submit */}
                    <DA_Button
                        title={getDictionaryString("NotificationServiceModalSubmit")}
                        disabled={[
                            DA_NotificationServiceViewState.SAVING,
                            DA_NotificationServiceViewState.DELETING,
                        ].includes(viewStateEnum)}
                        showSpinner={viewState === DA_NotificationServiceViewState.SAVING}
                        submit
                    />

                    {/** Cancel */}
                    <DA_Button
                        title={getDictionaryString("NotificationServiceModalCancel")}
                        onClick={onCancelButtonClicked}
                        isGhost
                    />
                </div>

                {/** Validation */}
                {validationErrorMessages?.length > 0 && (
                    <div className={S.errorMessage}>{validationErrorMessages?.[0].value}</div>
                )}
            </form>

            {/** Server error */}
            {viewState === DA_NotificationServiceViewState.ERROR && (
                <div className={S.error}>
                    <DA_Alert
                        content={getDictionaryString("NotificationServiceModalError")}
                        type="error"
                        hideCloseBtn
                    />
                </div>
            )}
        </DA_Modal>
    );
};
