import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import {
    DA_NewProduct,
    DA_NewProductSimple,
    DA_NewVariantEntity,
} from "@danishagro/shared/src/interfaces/productv2.interface";
import { DA_ProductDetails } from "@templates/ProductDetails/ProductDetails.component";
import { useProductPrices } from "@danishagro/shared/src/hooks/useProductPrices.hook";
import { getPageByUrl } from "@helpers/getPage.helper";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./ProductLoader.module.scss";

export interface ProductLoaderProps {
    product: DA_NewProductSimple;
}

export const ProductLoader = ({ product }: ProductLoaderProps) => {
    const [detailedProduct, setDetailedProduct] = useState<DA_NewProduct>(null);
    const [productNumber, setProductNumber] = useState(product.masterProductNumber);
    const [isLoading, setIsLoading] = useState(true);
    const [headerOffset, setHeaderOffset] = useState(0);
    const priceData = useProductPrices({ productNumber }, true);
    const elementRef = useRef(null);

    const scrollToProduct = useCallback(() => {
        if (elementRef.current && headerOffset > 0) {
            const { top } = elementRef.current.getBoundingClientRect();
            const offsetPosition = top + window.scrollY - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }, [headerOffset]);

    useLayoutEffect(() => {
        const borderOffset = 10;
        const headerOffset = getComputedStyle(document.documentElement).getPropertyValue(
            "--header-height"
        );
        setHeaderOffset(parseInt(headerOffset) + borderOffset);
    }, []);

    const loadProductByUrl = useCallback(
        (url: string) => {
            setIsLoading(true);
            getPageByUrl(url).then((response) => {
                setProductNumber(response.product.masterProductNumber);
                setDetailedProduct(response.product);
                setIsLoading(false);
                scrollToProduct();
            });
        },
        [scrollToProduct]
    );

    useEffect(() => {
        if (product) {
            loadProductByUrl(product.productUrl);
        }
    }, [loadProductByUrl, product]);

    const onVariantChange = (variantEntity: DA_NewVariantEntity) => {
        loadProductByUrl(variantEntity.url);
    };

    return (
        <div className={cn(S.productLoaderWrapper, isLoading && S.dimmed)} ref={elementRef}>
            {isLoading && !detailedProduct ? (
                <DA_ContentBox className={S.loadingWrapper}>
                    <DA_Spinner className={S.spinner} />
                </DA_ContentBox>
            ) : isLoading ? (
                <div className={S.loadingWrapper}>
                    <DA_Spinner className={S.spinner} />
                </div>
            ) : null}

            {detailedProduct ? (
                <div className={S.productWrapper}>
                    <DA_ProductDetails
                        {...detailedProduct}
                        isQuickOrder={true}
                        price={priceData}
                        onVariantChange={onVariantChange}
                    />
                </div>
            ) : null}
        </div>
    );
};
