import React from "react";
import { getB2bImage } from "@danishagro/shared/src/helpers/getB2bImage.helper";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { DA_ProductTag } from "@molecules/ProductTag/ProductTag.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_CartOrderLine } from "@danishagro/shared/src/interfaces/cartOrderLine.interface";
import { DA_CartLinePrice } from "@danishagro/shared/src/interfaces/price.interface";
import { ExpressDeliveryModes } from "@danishagro/shared/src/interfaces/expressDeliveryModes.interface";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { format } from "date-fns";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import S from "./CheckoutCartItems.module.scss";

export interface DA_CheckoutCartItemsProps {
    items: DA_CartOrderLine[];
    orderLinesPriceData: DA_CartLinePrice[];
    currentCulture: string;
    className?: string;
    expressDeliveryModes: ExpressDeliveryModes;
    getDictionaryString: (str: string, options?: { uppercaseFirst: boolean }) => string;
    isFarmInTimeCart?: boolean;
}

export const DA_CheckoutCartItems = ({
    items,
    orderLinesPriceData,
    currentCulture,
    expressDeliveryModes,
    getDictionaryString,
    className,
    isFarmInTimeCart,
}: DA_CheckoutCartItemsProps) => {
    const { showPrices } = useAppData();
    return (
        <div className={S.itemsList}>
            {items.map((item, index) => {
                const priceFormatted = orderLinesPriceData?.[index] ? (
                    formatNumber(orderLinesPriceData[index].price.priceWithoutVat, currentCulture, {
                        decimals: 2,
                    })
                ) : (
                    <Skeleton width={50} />
                );
                const charges = orderLinesPriceData?.[index]?.charges
                    ? orderLinesPriceData[index].charges.map((fee) => ({
                          code: fee.chargeCode,
                          title: getDictionaryString(fee.chargeCode),
                          price: formatNumber(fee.totalCalculated, currentCulture, {
                              decimals: 2,
                          }),
                      }))
                    : [];
                const shippingDateOnj = new Date(orderLinesPriceData[index]?.requestedShippingDay);
                const shippingDate = orderLinesPriceData?.[index] ? (
                    shippingDateOnj.getTime() < 0 ? (
                        // TODO: Remove this, when price service returns a real shipping date
                        expressDeliveryModes.cropProtectionDeliveryMode === "111" ? (
                            getDictionaryString("crop protection delivery same day")
                        ) : expressDeliveryModes.cropProtectionDeliveryMode === "112" ? (
                            getDictionaryString("crop protection delivery day to day")
                        ) : (
                            "-"
                        )
                    ) : (
                        format(shippingDateOnj, "dd.MM.yyyy")
                    )
                ) : (
                    <Skeleton width={72} />
                );

                // TODO: Develop new image component for presets
                const image = getB2bImage(item.image, 170);

                return (
                    <div className={cn(S.item, className || "")} key={item.id}>
                        <img
                            src={B2bImageSrc(item?.imageUrl, ImageConfig.PdpThumbnail)}
                            alt={image.alt}
                            width={image.width}
                            height={image.height}
                            className={S.image}
                        />

                        {/* <DA_Image
                            src={image.url}
                            alt={image.alt}
                            width={image.width}
                            height={image.height}
                            className={S.image}
                        /> */}
                        <div className={S.itemContent}>
                            <span className={S.itemHeading}>{item.productName}</span>

                            <div className={S.itemToolbar}>
                                <span>
                                    {item.quantity} {item.unit}
                                </span>
                                {showPrices && <span>{priceFormatted}</span>}
                            </div>

                            <div className={S.charges}>
                                <span className={S.chargeItem}>
                                    {orderLinesPriceData?.[index] ? (
                                        <>
                                            {orderLinesPriceData[index]?.withoutFeeAndChargesPrice
                                                ?.priceWithoutVat &&
                                                orderLinesPriceData[index]
                                                    ?.withoutFeeAndChargesPrice?.priceWithoutVat !==
                                                    0 &&
                                                orderLinesPriceData[index]
                                                    ?.withoutFeeAndChargesPrice?.priceWithoutVat !==
                                                    orderLinesPriceData[index].price
                                                        .priceWithoutVat && (
                                                    <>
                                                        {getDictionaryString("agreedPrice")}:{" "}
                                                        {formatNumber(
                                                            orderLinesPriceData[index]
                                                                ?.withoutFeeAndChargesPrice
                                                                ?.priceWithoutVat,
                                                            currentCulture,
                                                            {
                                                                decimals: 2,
                                                            }
                                                        )}
                                                    </>
                                                )}
                                        </>
                                    ) : (
                                        <Skeleton width="30" />
                                    )}
                                </span>
                            </div>

                            <div className={S.charges}>
                                <span className={S.chargeItem}>
                                    {orderLinesPriceData?.[index] ? (
                                        orderLinesPriceData[index]?.feeAndChargesPrice
                                            ?.priceWithoutVat > 0 ? (
                                            <>
                                                {getDictionaryString("fees")}:{" "}
                                                {formatNumber(
                                                    orderLinesPriceData[index]?.feeAndChargesPrice
                                                        ?.priceWithoutVat,
                                                    currentCulture,
                                                    {
                                                        decimals: 2,
                                                    }
                                                )}
                                            </>
                                        ) : null
                                    ) : (
                                        <Skeleton width="30" />
                                    )}
                                </span>
                            </div>

                            <div className={S.charges}>
                                <span className={S.chargeItem}>
                                    {orderLinesPriceData?.[index] ? (
                                        orderLinesPriceData[index]?.orderLineKvantum > 0 ? (
                                            <>
                                                {getDictionaryString("Kvantum")}:{" "}
                                                {formatNumber(
                                                    orderLinesPriceData[index]?.orderLineKvantum,
                                                    currentCulture,
                                                    {
                                                        decimals: 2,
                                                    }
                                                )}
                                            </>
                                        ) : null
                                    ) : (
                                        <Skeleton width="30" />
                                    )}
                                </span>
                            </div>

                            {showPrices && charges.length > 0 && (
                                <div className={S.charges}>
                                    {charges.map((fee) => (
                                        <div key={fee.code}>
                                            {fee.title}: {fee.price}
                                        </div>
                                    ))}
                                </div>
                            )}

                            {showPrices && (
                                <>
                                    <div className={S.deliveryDate}>
                                        {getDictionaryString("latest delivery date", {
                                            uppercaseFirst: true,
                                        })}
                                        : {shippingDate}
                                    </div>

                                    {item.allowCropProtectionDelivery ? (
                                        <div className={S.deliveryType}>
                                            <DA_ProductTag
                                                icon={DA_IconNames.PlantHand}
                                                color="green"
                                                size="small"
                                            >
                                                {getDictionaryString(
                                                    "allow crop protection delivery"
                                                )}
                                            </DA_ProductTag>
                                        </div>
                                    ) : null}

                                    {item.allowExpressDelivery ? (
                                        <div className={S.deliveryType}>
                                            <DA_ProductTag
                                                icon={DA_IconNames.Truck}
                                                color="blue"
                                                size="small"
                                            >
                                                {getDictionaryString("allow express delivery")}
                                            </DA_ProductTag>
                                        </div>
                                    ) : null}
                                </>
                            )}

                            {item.driverMessage && !isFarmInTimeCart && (
                                <div className={S.driverMessage}>
                                    <DA_Text smallText noPadding>
                                        {getDictionaryString("driverMessageCartTitle")}:{" "}
                                        {item.driverMessage}
                                    </DA_Text>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
