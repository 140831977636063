import React, { useCallback, useMemo, useState } from "react";
import { animated, useSpring } from "react-spring";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_ButtonColor } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_ConfirmModal } from "@danishagro/shared/src/components/molecules/ConfirmModal/ConfirmModal.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { DA_OrderListCartOrderLine } from "@danishagro/shared/src/interfaces/OrderListCartOrderLine.interface";
import { DA_QuantityPicker } from "@molecules/QuantityPicker/QuantityPicker.component";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useMeasure } from "react-use";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useFarmInTimeProducts } from "@templates/FarmInTime/components/Cart/hooks/useProducts.hook";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import Skeleton from "react-loading-skeleton";
import { DA_Checkbox } from "@danishagro/shared/src/components/atoms/Checkbox/Checkbox.component";
import { useFarmInTimeQuickSelect } from "@danishagro/b2b/src/components/templates/FarmInTime/components/Cart/contexts/FarmInTimeQuickSelectProvider";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { debounce } from "lodash";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { DA_Tag } from "@danishagro/shared/src/components/atoms/Tag/Tag.component";
import { isGreaterThanZero } from "@danishagro/shared/src/helpers/greaterThanZero.helper";
import S from "./Item.module.scss";

export const DA_FarmInTimeCartItem = React.memo(
    (data: DA_OrderListCartOrderLine & { address: DA_Address; type?: string }) => {
        const {
            address,
            type,
            productNumber,
            productName,
            productId,
            dwVariantId,
            productUrl,
            image,
            quantity,
            price,
            farmInTimeFrequency,
            farmInTimeOrderListProductId,
            quantityOnPallet,
        } = data;
        const [squashed, setSquashed] = useState(false);
        const [isRemoving, setIsRemoving] = useState(false);
        const [currentQuantity, setCurrentQuantity] = useState(quantity);
        const [saveState, setSaveState] = useState("NONE");
        const saveStateTimeout = React.useRef<NodeJS.Timeout>();

        const { getDictionaryString, getDictionaryItem } = useTranslations();
        const { currentCulture } = useAppData();
        const { showModal, closeModal } = useModal();
        const [itemRef, { height }] = useMeasure();
        const { deleteProductFromOrderList, updateOrderListProductV2 } = useFarmInTimeApi();
        const { customerNumber } = useAppData();
        const { isMobile, isMobileOrTablet } = useScreen();

        const { refresh } = useFarmInTimeProducts();

        const styles = useSpring({
            height: squashed ? 0 : isMobile ? undefined : height,
            immediate: !squashed,
        });

        // const productImage = useRef(`${image.url}/${ImageConfig.MiniBasketThumbnail}`).current;

        const priceFormatted = useMemo(
            () =>
                price ? (
                    formatNumber(price.priceWithoutVat, currentCulture, { decimals: 2 })
                ) : (
                    <Skeleton width={50} />
                ),
            [price, currentCulture]
        );

        const remove = useCallback(
            (productId: number) => {
                setIsRemoving(true);
                deleteProductFromOrderList(customerNumber, address?.id, productId)
                    .then(() => {
                        closeModal();
                        setSquashed(true);

                        // Wait at least till animation is over.
                        if (typeof refresh !== "undefined") {
                            setTimeout(() => {
                                refresh();
                            }, 150); // TODO: Quickfix
                        }
                    })
                    .finally(() => setIsRemoving(false));
            },
            [deleteProductFromOrderList, customerNumber, address?.id, closeModal, refresh]
        );

        const confirmRemove = useCallback(
            (id: number) =>
                showModal(
                    <DA_ConfirmModal
                        title={getDictionaryString("remove product")}
                        description={
                            type === "orderingList"
                                ? getDictionaryString("FarmInTimeOrderingsListRemoveModalText")
                                : getDictionaryString("FarmInTimeRemoveModalText")
                        }
                        approveButtonLabel={getDictionaryString("confirmDelete")}
                        approveButtonColor={DA_ButtonColor.Alert}
                        denyButtonLabel={getDictionaryString("cancel")}
                        onApprove={() => remove(id)}
                    />,
                    { size: ModalSize.XS }
                ),
            [showModal, getDictionaryString, remove, type]
        );

        const updateItem = useCallback(
            (partialItem: object) => {
                setSaveState("LOADING");

                updateOrderListProductV2(customerNumber, address.id, farmInTimeOrderListProductId, {
                    productId: productId,
                    variantId: dwVariantId,
                    quantity: currentQuantity,
                    ...partialItem,
                }).then(() => {
                    setSaveState("SAVED");
                    clearTimeout(saveStateTimeout.current);

                    // NOTE: No need to refresh list when updating quantity, because price is currently per unit.
                    // refresh();

                    saveStateTimeout.current = setTimeout(() => {
                        setSaveState("NONE");
                    }, 2000);
                });
            },
            [
                address.id,
                currentQuantity,
                customerNumber,
                farmInTimeOrderListProductId,
                productId,
                dwVariantId,
                updateOrderListProductV2,
            ]
        );

        // Update
        const onQuantityChanged = useCallback(
            (newQuantity: number) => {
                setCurrentQuantity(newQuantity);

                const debouncedFn = debounce(() => {
                    updateItem({
                        quantity: newQuantity,
                    });
                }, 1500);

                debouncedFn();
            },
            [updateItem]
        );

        const { selectedProducts, toggleItem, isQuickSelectEnabled } = useFarmInTimeQuickSelect();

        const quickSelectCheckboxLabel = useMemo(() => {
            if (isMobileOrTablet) return "";

            switch (selectedProducts?.data?.[type]?.[farmInTimeOrderListProductId]) {
                case false:
                    return getDictionaryString("FarmInTimeCartItemQuickSelectLabelExclude");
                default:
                    return getDictionaryString("FarmInTimeCartItemQuickSelectLabelInclude");
            }
        }, [
            farmInTimeOrderListProductId,
            getDictionaryString,
            isMobileOrTablet,
            selectedProducts,
            type,
        ]);

        const onQuickSelectChanged = (state: boolean) => {
            toggleItem(type, farmInTimeOrderListProductId, state);
        };

        const isSelectionShownClass =
            type === "farmInTimeList" && isQuickSelectEnabled ? S.isShown : S.isHidden;

        const url = `/${productUrl}`.replace(/^\/+/, "/");

        return (
            <animated.div style={styles}>
                <div className={S.wrapper} ref={itemRef}>
                    <div className={S.item}>
                        <div className={S.mainInfo}>
                            {/** Image */}
                            <div className={S.imageWrapper}>
                                <DA_BasicLink href={url}>
                                    <DA_Image
                                        src={B2bImageSrc(image.url, ImageConfig.PdpThumbnail)}
                                        alt={productName}
                                        className={S.image}
                                    />
                                </DA_BasicLink>
                            </div>

                            {/** Name */}
                            <div className={S.numberAndName}>
                                <div className={S.number}>{productNumber}</div>
                                <DA_BasicLink href={url} className={S.nameLink}>
                                    <div className={S.name}>{productName}</div>
                                </DA_BasicLink>
                            </div>
                        </div>

                        <div className={cn(S.actionsWrapper, !isMobile && S.isTabletOrDesktop)}>
                            <div
                                className={cn(S.actions, farmInTimeFrequency && S.includeFrequency)}
                            >
                                {/** Quantity Counter */}
                                <DA_QuantityPicker
                                    onChange={onQuantityChanged}
                                    value={currentQuantity}
                                    disabled={saveState === "LOADING"}
                                />
                                {farmInTimeFrequency && !isMobile && (
                                    <div className={S.frequency}>
                                        {getDictionaryString("FarmInTimeCartItemFrequency")}{" "}
                                        {getDictionaryString(farmInTimeFrequency)}
                                    </div>
                                )}

                                {/** Delete Button */}
                                {isRemoving ? <DA_Spinner className={S.spinner} /> : null}

                                {(!isRemoving && type === "orderingList") ||
                                (type === "farmInTimeList" && !isQuickSelectEnabled) ? (
                                    <button
                                        type="button"
                                        className={S.removeButton}
                                        onClick={() => confirmRemove(farmInTimeOrderListProductId)}
                                    >
                                        <DA_Icon
                                            name={DA_IconNames.Trash}
                                            className={S.removeIcon}
                                        />
                                    </button>
                                ) : null}

                                {/** Indicator */}
                                <div className={S.saveIndicatorWrapper}>
                                    {saveState === "LOADING" && <DA_Spinner />}
                                    {saveState === "SAVED" && (
                                        <DA_Icon name={DA_IconNames.Checkmark} />
                                    )}
                                </div>
                            </div>

                            <div className={cn(S.selectionWrapper, isSelectionShownClass)}>
                                <DA_Checkbox
                                    checked={
                                        selectedProducts?.data?.[type]?.[
                                            farmInTimeOrderListProductId
                                        ]
                                    }
                                    label={quickSelectCheckboxLabel}
                                    onChange={onQuickSelectChanged}
                                />
                            </div>

                            <div className={S.labelTagWrapper}>
                                {!isMobile && isGreaterThanZero(quantityOnPallet) && (
                                    <DA_Tag color="neutral" size="dynamic">
                                        {getDictionaryItem("quantityonpallettext", {
                                            quantity: quantityOnPallet,
                                        })}
                                    </DA_Tag>
                                )}
                            </div>

                            <div className={S.pricingWrapper}>
                                {/** Price */}
                                <div className={S.total}>
                                    <span className={S.totalLabel}>
                                        {getDictionaryString("total")}:{" "}
                                    </span>

                                    {priceFormatted}
                                </div>
                            </div>
                        </div>

                        {farmInTimeFrequency && isMobile && (
                            <div className={S.mobileFrequency}>
                                {getDictionaryString("FarmInTimeCartItemFrequency")}{" "}
                                {getDictionaryString(farmInTimeFrequency)}
                            </div>
                        )}
                        {isMobile && isGreaterThanZero(quantityOnPallet) && (
                            <div className={cn(S.labelTagWrapper, S.mobileLabelTagWrapper)}>
                                <DA_Tag color="neutral" size="dynamic">
                                    {getDictionaryItem("quantityonpallettext", {
                                        quantity: quantityOnPallet,
                                    })}
                                </DA_Tag>
                            </div>
                        )}
                    </div>
                </div>
            </animated.div>
        );
    }
);

DA_FarmInTimeCartItem.displayName = "CartItem";
