import React, { useCallback, useRef } from "react";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import {
    DA_DynamicOverlayWrapper,
    useDynamicOverlay,
} from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { DriverMessageOverlay } from "../DriverMessageOverlay/DriverMessageOverlay.component";
import { DriverMessageMobileButtonProps } from "./DriverMessageMobileButton.interface";
import S from "./DriverMessageMobileButton.module.scss";

export const DA_DriverMessageMobileButton = (props: DriverMessageMobileButtonProps) => {
    const { getDictionaryString } = useTranslations();
    const { showOverlay, closeOverlay } = useDynamicOverlay();

    const existingMessageRef = useRef<string>();

    const onModalSubmitted = useCallback(
        (updatedMessage: string) => {
            if (updatedMessage !== existingMessageRef.current) {
                props.onUpdateDriverMessage(updatedMessage);
            }

            closeOverlay();
        },
        [closeOverlay, props]
    );

    const onButtonClicked = useCallback(async () => {
        showOverlay({
            id: "driverMessageOverlay",
            title: getDictionaryString("DriverMessageModalHeading"),
            closeButtonToBack: true,
            content: (
                <DA_DynamicOverlayWrapper
                    content={
                        <DriverMessageOverlay
                            productId={props.productId}
                            variantId={props.variantId}
                            message={props?.message}
                            customerNumber={props.customerNumber}
                            orderSecret={props.orderSecret}
                            onSubmit={onModalSubmitted}
                            onCancel={closeOverlay}
                        />
                    }
                    footer={null}
                />
            ),
        });
    }, [
        closeOverlay,
        getDictionaryString,
        onModalSubmitted,
        props.customerNumber,
        props?.message,
        props.orderSecret,
        props.productId,
        props.variantId,
        showOverlay,
    ]);

    return (
        <DA_ButtonLink
            title={
                props?.message
                    ? getDictionaryString("edit comment")
                    : getDictionaryString("add comment")
            }
            icon={DA_IconNames.AddComment}
            onClick={onButtonClicked}
            className={S.button}
        />
    );
};
