import { useEffect, useRef } from "react";
import { DA_TabItem } from "../Tab.component";

export const useChangeCallback = (currentTab: DA_TabItem, callback: (item: DA_TabItem) => void) => {
    const onTabChangeCallback = useRef<((item: DA_TabItem) => void) | null>(null);

    useEffect(() => {
        if (onTabChangeCallback.current) {
            onTabChangeCallback.current(currentTab);
        }
    }, [currentTab]);

    useEffect(() => {
        onTabChangeCallback.current = callback;
    }, [callback]);
};
