import React, { FormEvent, useCallback, useState } from "react";
import { B2B_API_ROOT } from "@danishagro/shared/src/constants/urlRoot.constants";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_TextArea } from "@danishagro/shared/src/components/atoms/TextArea/TextArea.component";
import { getApiUrl } from "@danishagro/shared/src/helpers/getApiUrl.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_ViewStates } from "@danishagro/shared/src/content/viewState/viewStates";
// import { DriverMessageModalProps } from "./DriverMessageModal.interface";
import { DA_DynamicOverlayWrapper } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { DA_Alert } from "@danishagro/shared/src/components/atoms/Alert/Alert.component";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import S from "./DriverMessageOverlay.module.scss";

export interface DriverMessageOverlayProps {
    productId?: string;
    variantId?: string;
    lineId?: string;
    message?: string;
    customerNumber?: string;
    orderSecret?: string;

    onSubmit: (updatedMessage: string) => void;
    onCancel: () => void;
}

export const DriverMessageOverlay = (props: DriverMessageOverlayProps) => {
    const { getDictionaryString } = useTranslations();

    const { fetchWithAuthB2b } = useAppData();
    const { updateCartOrderLines, cartId } = useCart();

    const [viewState, setViewState] = useState<DA_ViewStates>(DA_ViewStates.INIT);

    // TODO: Copy from desktop
    const updateOrderlineDriverMessage = useCallback(
        async (driverMessage: string) => {
            // const url = getApiUrl(B2B_API_ROOT, "/cart/updatedrivermessage", undefined);
            // const props = {
            //     id: 'cartid',
            //     lineId: 'lineId'
            // }
            const url = getApiUrl(
                B2B_API_ROOT,
                `/carts/${props.customerNumber}/savedrivermessage`,
                undefined
            );

            const request = await fetchWithAuthB2b(url, props.customerNumber, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    productId: props.productId,
                    variantId: props.variantId,
                    id: cartId,
                    lineId: props.lineId,
                    customerNumber: props.customerNumber,
                    driverMessage: driverMessage,
                }),
            });

            if (request.ok) {
                const json = await request.json();
                updateCartOrderLines(json.cart.orderLines);

                if (json.success === true) {
                    return true;
                }
            }

            return false;
        },
        [
            cartId,
            fetchWithAuthB2b,
            props.customerNumber,
            props.lineId,
            props.productId,
            props.variantId,
            updateCartOrderLines,
        ]
    );

    const onFormSubmitted = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            const form = new FormData(event.target as HTMLFormElement);
            const body = {} as { driverMessage: string };

            form.forEach((value, key) => (body[key] = value));

            setViewState(DA_ViewStates.SAVING);

            try {
                const didSucceed = await updateOrderlineDriverMessage(body.driverMessage);

                if (didSucceed) {
                    props.onSubmit(body?.driverMessage);
                } else {
                    throw new Error("Failed to update driver message");
                }
            } catch (error) {
                console.error(error); // Log the error for debugging purposes
                setViewState(DA_ViewStates.ERROR);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [props, updateOrderlineDriverMessage]
    );

    const onCancelButtonClicked = useCallback(() => {
        props.onCancel();
    }, [props]);

    return (
        <DA_DynamicOverlayWrapper
            content={
                <>
                    <form noValidate onSubmit={onFormSubmitted} id="driverMessageForm">
                        <div className={S.content}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: getDictionaryString(
                                        "CartDriverMessageModalDescription"
                                    ),
                                }}
                            />

                            {/** DriverMessage */}
                            <DA_TextArea
                                autoComplete="off"
                                name="driverMessage"
                                className={S.inputWrapper}
                                inputClassName={S.input}
                                defaultValue={props?.message}
                                id="driverMessage"
                            />
                        </div>
                    </form>

                    {/** Server error */}
                    {viewState === DA_ViewStates.ERROR && (
                        <div className={S.error}>
                            <DA_Alert
                                content={getDictionaryString("DriverMessageModalError")}
                                type="error"
                                hideCloseBtn
                            />
                        </div>
                    )}
                </>
            }
            footer={
                <div className={S.buttons}>
                    {/** Cancel */}
                    <DA_Button
                        title={getDictionaryString("DriverMessageModalCancel")}
                        onClick={onCancelButtonClicked}
                        className={S.button}
                        isGhost
                    />

                    {/** Submit */}
                    <DA_Button
                        title={getDictionaryString("DriverMessageModalSubmit")}
                        disabled={viewState === DA_ViewStates.SAVING}
                        showSpinner={viewState === DA_ViewStates.SAVING}
                        form="driverMessageForm"
                        className={S.button}
                        submit
                    />
                </div>
            }
        />
    );
};
