import { useCallback } from "react";
import { DA_UserInformation } from "../../interfaces/userInformation.interface";
import { useRequest } from "../useRequest.hook";
import { FetchOptions } from "../../interfaces/fetchOptions.interface";
import { DA_UserAccountDetails } from "../../interfaces/userAccountDetails.interface";

type GetUserInfo = (options?: FetchOptions) => Promise<DA_UserInformation>;

type SetUserInfo = (
    parameters: DA_UserInformation,
    options?: FetchOptions
) => Promise<DA_UserInformation>;

type GetUserAccountDetails = (
    customerNumber: string,
    options?: FetchOptions
) => Promise<DA_UserAccountDetails>;

type GetEditProfileLink = (
    locale: string,
    redirectUri: string,
    options?: FetchOptions
) => Promise<{ url: string }>;

type GetResetPasswordLink = (
    locale: string,
    redirectUri: string,
    options?: FetchOptions
) => Promise<{ url: string }>;

export const useUserInformationApi = () => {
    const request = useRequest();

    const getUserInfo: GetUserInfo = useCallback(
        (options?) => request.get(`user/userinfo`, undefined, options),
        [request]
    );

    const setUserInfo: SetUserInfo = useCallback(
        (parameters, options?) =>
            request.put(`user/userinfo`, parameters, options).then((response) => response.json()),
        [request]
    );

    const getUserAccountDetails: GetUserAccountDetails = useCallback(
        (customerNumber, options?) =>
            request.get(`/user/getuseraccountdetails/${customerNumber}`, options),
        [request]
    );

    const getEditProfileLink: GetEditProfileLink = useCallback(
        (locale, redirectUri, options?) =>
            request.get(
                `utility/editprofilelink`,
                {
                    locale,
                    redirecturi: encodeURIComponent(redirectUri),
                },
                options
            ),
        [request]
    );

    const getResetPasswordLink: GetResetPasswordLink = useCallback(
        (locale, redirectUri, options?) =>
            request.get(
                `utility/resetpasswordlink`,
                {
                    locale,
                    redirecturi: encodeURIComponent(redirectUri),
                },
                options
            ),
        [request]
    );

    return {
        getUserInfo,
        setUserInfo,
        getUserAccountDetails,
        getEditProfileLink,
        getResetPasswordLink,
    };
};
