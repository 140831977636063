import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_CategoryLink } from "@interfaces/categoryLink.interface";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import S from "./CategoryNavigation.module.scss";

export interface DA_CategoryNavigationProps {
    heading: string;
    title: string;
    parentCategoryLink?: string;
    parentCategoryName: string;
    level: number;
    relatedCategoryLinks: DA_CategoryLink[];
}

export const DA_CategoryNavigation = ({
    heading,
    title,
    parentCategoryLink,
    parentCategoryName,
    level,
    relatedCategoryLinks,
}: DA_CategoryNavigationProps) => {
    const { getDictionaryString } = useTranslations();

    return (
        <nav>
            <DA_Title h3>{heading}</DA_Title>

            <div className={S.categoryWrapper}>
                {level > 0 && (
                    <div className={S.parentCategoryWrapper}>
                        <span className={S.iconWrapper}>
                            <DA_Icon name={DA_IconNames.ChevronRight} className={S.iconRotate} />
                        </span>
                        <DA_BasicLink href={parentCategoryLink} className={S.parentLink}>
                            {`${getDictionaryString("back to", {
                                uppercaseFirst: true,
                            })} ${parentCategoryName}`}
                        </DA_BasicLink>
                    </div>
                )}

                {relatedCategoryLinks && relatedCategoryLinks.length > 0 && (
                    <div className={cn(S.contentWrapper)}>
                        <span className={S.parentCategoryName}>{title}</span>

                        <ul className={S.listWrapper}>
                            {relatedCategoryLinks.map((item) => (
                                <li key={item.id} className={S.listItem}>
                                    <DA_BasicLink
                                        href={
                                            item.metaInformation.canonicalUrl ||
                                            item.redirectUrl ||
                                            item.url
                                        }
                                        className={cn(
                                            S.categoryLink,
                                            item.currentPage && S.currentPage
                                        )}
                                        target={
                                            item.metaInformation.canonicalUrl ? "_blank" : "_self"
                                        }
                                    >
                                        {item.name}
                                    </DA_BasicLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    );
};
