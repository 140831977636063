import React, { useCallback, useRef, useState } from "react";
import {
    DA_Button,
    DA_ButtonColor,
} from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Tooltip } from "@danishagro/shared/src/components/atoms/Tooltip/Tooltip.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_ViewStates } from "@danishagro/shared/src/content/viewState/viewStates";
import { DriverMessageModal } from "../DriverMessageModal/DriverMessageModal.component";
import { DriverMessageButtonProps } from "./DriverMessageButton.interface";
import S from "./DriverMessageButton.module.scss";

export const DA_DriverMessageButton = (props: DriverMessageButtonProps) => {
    const { getDictionaryString } = useTranslations();
    const { showModal, closeModal } = useModal();

    const [viewState, setViewState] = useState<DA_ViewStates>(DA_ViewStates.NONE);
    const existingMessageRef = useRef<string>();

    const onModalSubmitted = useCallback(
        (updatedMessage: string) => {
            if (updatedMessage !== existingMessageRef.current) {
                setViewState(DA_ViewStates.SAVED);

                props.onUpdateDriverMessage(updatedMessage);

                setTimeout(() => {
                    setViewState(DA_ViewStates.NONE);
                }, 3000);
            }

            closeModal();
        },
        [closeModal, props]
    );

    const onModalCancelled = useCallback(() => {
        closeModal();
    }, [closeModal]);

    const onButtonClicked = useCallback(async () => {
        setViewState(DA_ViewStates.LOADING);

        showModal(
            <DriverMessageModal
                lineId={props.lineId}
                productId={props.productId}
                variantId={props.variantId}
                message={props?.message}
                customerNumber={props.customerNumber}
                orderSecret={props.orderSecret}
                onSubmit={onModalSubmitted}
                onCancel={onModalCancelled}
            />,
            {
                size: ModalSize.SM,
            }
        );

        setViewState(DA_ViewStates.NONE);
    }, [onModalCancelled, onModalSubmitted, props, showModal]);

    return (
        <DA_Tooltip
            text={
                props.message
                    ? getDictionaryString("edit comment")
                    : getDictionaryString("add comment")
            }
            placement="top"
            onClick={onButtonClicked}
        >
            <DA_Button
                className={S.button}
                onClick={onButtonClicked}
                showSpinner={viewState === "LOADING"}
                color={DA_ButtonColor.White}
                large={true}
                icon={
                    viewState === DA_ViewStates.SAVED
                        ? DA_IconNames.Checkmark
                        : DA_IconNames.AddComment
                }
            />
        </DA_Tooltip>
    );
};
