import { useCallback } from "react";
import { PaymentMethodFormData } from "@templates/SteppedCheckout/components/PaymentMethod/interfaces/paymentMethodFormData.interface";
import { getApiUrl } from "@danishagro/shared/src/helpers/getApiUrl.helper";
import { API_GATEWAY_ROOT } from "@danishagro/shared/src/constants/urlRoot.constants";
import { FetchOptions } from "@danishagro/shared/src/interfaces/fetchOptions.interface";

type GetPaymentLink = (
    parameters: PaymentMethodFormData,
    options?: FetchOptions
) => Promise<Response>;

type GetPaymentMethods = (options?: FetchOptions) => Promise<string[]>;

export const useQuickPayApi = () => {
    const getPaymentLink: GetPaymentLink = useCallback(
        (parameters, options?) =>
            fetch(getApiUrl(API_GATEWAY_ROOT, "quickpay/getpaymentlink"), {
                ...options,
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(parameters),
            }).then((response) => response.json()),
        []
    );

    const getPaymentMethods: GetPaymentMethods = useCallback(
        (parameters, options?) =>
            fetch(getApiUrl(API_GATEWAY_ROOT, "quickpay/getpaymentmethods"), options).then(
                (response) => response.json()
            ),
        []
    );

    return {
        getPaymentLink,
        getPaymentMethods,
    };
};
