export enum CheckoutSteps {
    Contact = "contact",
    Shipping = "shipping",
    Payment = "payment",
    Receipt = "receipt",
}

export type SteppedCheckoutParams = {
    step: CheckoutSteps;
};
