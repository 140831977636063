import React, { ReactNode, useEffect, useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "../Icon/Icon.component";
import S from "./Checkbox.module.scss";

export interface DA_CheckboxProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "className"> {
    label: string | ReactNode;
    className?: string;
    labelElementClassName?: string;
    indeterminate?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (state: boolean, props: any) => void;
    isMobile?: boolean;
    isSelectAllCheckbox?: boolean;
    isSpecialCheckbox?: boolean;
    icon?: DA_IconNames;
}

export const DA_Checkbox = (props: DA_CheckboxProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(props.checked ?? false);

    const onCheckboxChanged = ({ currentTarget }) => {
        setIsChecked(props.checked ?? currentTarget.checked);

        if (typeof props.onChange !== "undefined") {
            props.onChange(currentTarget.checked, props);
        }
    };

    useEffect(() => {
        if (typeof props.checked !== "undefined") {
            setIsChecked(props.checked);
        } else if (
            typeof props.checked === "undefined" &&
            typeof props.defaultChecked !== "undefined"
        ) {
            setIsChecked(props.defaultChecked);
        }
    }, [props.checked, props.defaultChecked]);

    // Determine which icon to display
    // Default icon
    let icon = DA_IconNames.Checkmark;
    // Change to minus icon when partially selected
    if (props.isSelectAllCheckbox) {
        icon = DA_IconNames.Minus;
    }

    return (
        <div
            className={cn(
                S.wrapper,
                props.disabled && S.disabled,
                props.className,
                props.isMobile && S.mobileSize
            )}
        >
            <label
                className={cn(
                    S.labelElement,
                    props.labelElementClassName,
                    props.isSpecialCheckbox && S.isSpecialCheckboxLabel
                )}
            >
                <div className={S.labelInside}>
                    {/** Input */}
                    <input
                        className={S.input}
                        checked={isChecked}
                        disabled={props.disabled}
                        name={props.name}
                        value={props.value}
                        onChange={onCheckboxChanged}
                        type="checkbox"
                    />

                    {props?.isSpecialCheckbox ? (
                        <div
                            className={cn(S.specialCheckbox, isChecked && S.specialCheckboxChecked)}
                        >
                            <DA_Icon name={props.icon} className={S.specialIcon} />
                            <div className={S.label}>{props.label}</div>
                            <div
                                className={cn(
                                    S.indicator,
                                    !props.disabled &&
                                        (isChecked || props.indeterminate) &&
                                        S.colored
                                )}
                            >
                                {isChecked && <DA_Icon name={icon} className={S.checkmarkIcon} />}

                                {!isChecked && props.indeterminate && (
                                    <div className={S.indeterminateMark} />
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div
                                className={cn(
                                    S.indicator,
                                    !props.disabled &&
                                        (isChecked || props.indeterminate) &&
                                        S.colored
                                )}
                            >
                                {isChecked && <DA_Icon name={icon} className={S.checkmarkIcon} />}

                                {!isChecked && props.indeterminate && (
                                    <div className={S.indeterminateMark} />
                                )}
                            </div>

                            <div className={S.label}>{props.label}</div>
                        </>
                    )}
                </div>
            </label>
        </div>
    );
};
