import React, { useRef, useState } from "react";
import {
    DA_Button,
    DA_ButtonColor,
} from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Input } from "@danishagro/shared/src/components/atoms/Input/Input.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_DynamicOverlayWrapper } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import S from "./EditOrRemove.module.scss";

export const EditOrRemoveContact = ({
    id,
    name,
    email,
    phone,
    onRemove,
    onSubmit,
}: {
    id: string;
    name: string;
    email: string;
    phone: string;
    onSubmit: (id: string, name: string, email: string, phone: string) => void;
    onRemove: (id: string) => void;
}) => {
    const { getDictionaryString } = useTranslations();
    const [nameError, setNameError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [phoneError, setPhoneError] = useState<string | null>(null);

    // Refs for input fields when editing contact
    const editContactNameElementRef = useRef<HTMLInputElement>();
    const editContactEmailElementRef = useRef<HTMLInputElement>();
    const editContactPhoneElementRef = useRef<HTMLInputElement>();

    const handleNameChange = () => {
        if (nameError) {
            setNameError(null);
        }
    };

    const handleEmailChange = () => {
        if (emailError) {
            setEmailError(null);
        }
    };

    const handlePhoneChange = () => {
        if (phoneError) {
            setPhoneError(null);
        }
    };

    const handleValidation = () => {
        const name = editContactNameElementRef.current?.value;
        const email = editContactEmailElementRef.current?.value;
        const phone = editContactPhoneElementRef.current?.value;

        let isValid = true;

        if (!name) {
            setNameError(
                getDictionaryString("NotificationServiceMobileAddContactValidationNameRequired")
            );
            isValid = false;
        } else {
            setNameError(null);
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email || !emailRegex.test(email)) {
            setEmailError(
                getDictionaryString("NotificationServiceMobileAddContactValidationEmailRequired")
            );
            isValid = false;
        } else {
            setEmailError(null);
        }

        const phoneRegex = /^\+45\d{8}$/;
        if (!phone || !phoneRegex.test(phone)) {
            setPhoneError(
                getDictionaryString("NotificationServiceMobileAddContactValidationPhoneValidation")
            );
            isValid = false;
        } else {
            setPhoneError(null);
        }

        if (isValid) {
            onSubmit(id, name, email, phone);
        }
    };

    return (
        <DA_DynamicOverlayWrapper
            content={
                <>
                    <DA_Input
                        label={getDictionaryString("NotificationServiceModalName", {
                            uppercaseFirst: true,
                        })}
                        id="edit-name"
                        type="text"
                        className={S.input}
                        defaultValue={name}
                        ref={editContactNameElementRef}
                        onChange={handleNameChange}
                        helperText={nameError ? nameError : ""}
                        invalid={!!nameError}
                    />

                    <DA_Input
                        label={getDictionaryString("NotificationServiceModalEmail", {
                            uppercaseFirst: true,
                        })}
                        id="invite-email"
                        type="email"
                        className={S.input}
                        defaultValue={email}
                        ref={editContactEmailElementRef}
                        onChange={handleEmailChange}
                        helperText={emailError ? emailError : ""}
                        invalid={!!emailError}
                    />

                    <DA_Input
                        label={getDictionaryString("NotificationServiceModalPhone", {
                            uppercaseFirst: true,
                        })}
                        id="invite-phone"
                        type="phone"
                        className={S.input}
                        defaultValue={phone}
                        ref={editContactPhoneElementRef}
                        onChange={handlePhoneChange}
                        helperText={phoneError ? phoneError : ""}
                        invalid={!!phoneError}
                    />
                </>
            }
            footer={
                <div className={S.editButtonsWrapper}>
                    {/** Submit */}
                    <DA_Button
                        title={getDictionaryString("NotificationServiceModalSubmit ")}
                        className={S.editButton}
                        onClick={() => handleValidation()}
                    />

                    {/** REMOVE */}
                    <DA_Button
                        title={getDictionaryString("NotificationServiceModalRemove ")}
                        onClick={() => onRemove(id)}
                        className={S.editButton}
                        color={DA_ButtonColor.Alert}
                        isGhost
                    />
                </div>
            }
        />
    );
};
