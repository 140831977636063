import React from "react";
import Switch from "react-switch";
import { useHexColor } from "@danishagro/shared/src/hooks/useHexColor.hook";

export interface DA_SwitchElementProps {
    id?: string;
    disabled?: boolean;
    label?: string;
    checked?: boolean;
    onChange?: (isChecked: boolean) => void;
    size?: "small" | "large";
}

export const DA_SwitchElement = (props: DA_SwitchElementProps) => {
    const getHexColor = useHexColor();

    return (
        <Switch
            id={props.id}
            checked={props.checked}
            onChange={() => props.onChange(!props.checked)}
            onColor={getHexColor("primary-1")}
            offColor="#ccc"
            offHandleColor={props.disabled ? "#f5f5f5" : undefined}
            boxShadow={props.disabled ? undefined : "0px 2px 6px rgba(0, 0, 0, 0.25)"}
            handleDiameter={props.size === "small" ? 12 : 20}
            uncheckedIcon={false}
            checkedIcon={false}
            aria-label={props.label}
            disabled={props.disabled}
            width={props.size === "small" ? 23 : 40}
            height={props.size === "small" ? 14 : 24}
        />
    );
};
