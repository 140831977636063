import { camelCase } from "change-case";
import CSSColors from "../styles/global/colors-export.module.scss";

export const HEX_COLORS = {
    common: <Record<string, string>>Object.fromEntries(
        Object.entries(CSSColors)
            .filter(([name]) => name.startsWith("palette--common--"))
            .map(([key, value]) => [
                camelCase(key.replace(/^palette--common--/, "")).replace(/_(\d+)/, "$1"),
                value,
            ])
    ),
    themed: <Record<string, Record<string, string>>>Object.entries(CSSColors)
        .filter(([name]) => name.startsWith("palette--site--"))
        .reduce((obj, [key, value]) => {
            const themeRegex = /^palette--site--((?!--).*?)--(.*)/;
            const themeName = key.replace(themeRegex, "$1");
            const colorName = camelCase(key.replace(themeRegex, "$2")).replace(/_(\d+)/, "$1");

            if (!(themeName in obj)) {
                obj[themeName] = {};
            }

            obj[themeName][colorName] = value;

            return obj;
        }, {}),
};
