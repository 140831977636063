import React, { useCallback, useEffect, useRef } from "react";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_InputWrapper } from "@danishagro/shared/src/components/atoms/InputWrapper/InputWrapper.component";
import { DA_Select } from "@danishagro/shared/src/components/atoms/Select/Select.component";
import { DA_Tab } from "@danishagro/shared/src/components/molecules/Tab/Tab.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Tooltip } from "@danishagro/shared/src/components/atoms/Tooltip/Tooltip.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { ModalSize } from "@danishagro/shared/src/contexts/modal.context";
import { DA_AddressOverlay } from "@danishagro/shared/src/components/organisms/AddressOverlay/AddressOverlay.component";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { AddressOverlayViewState } from "@danishagro/shared/src/components/organisms/AddressOverlay/interfaces/AddressOverlayViewState.interface";
import { useDynamicOverlay } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useFilterOptions } from "./hooks/useFilterOptions";
import { useTabs } from "./hooks/useTabs";
import S from "./FarmInTime.module.scss";

export const DA_FarmInTime = () => {
    const { getDictionaryString } = useTranslations();
    const { showOverlay, closeOverlay } = useDynamicOverlay();
    const { scrollTo } = useScreen();

    const selectRef = useRef(null);

    useEffect(() => {
        scrollTo(0);
    }, [scrollTo]);

    const {
        currentDeliveryAddress,
        currentDeliveryAddressOption,
        deliveryAddressOptions,
        deliveryAddresses,
        onAddressOverlayChanged,
        onDeliveryAddressSelectChanged,
    } = useFilterOptions();

    const addAddress = useCallback(() => {
        selectRef.current.close();
        showOverlay(
            {
                content: (
                    <DA_AddressOverlay
                        addresses={deliveryAddresses}
                        onSubmit={(givenAddress: DA_Address) => {
                            onAddressOverlayChanged(givenAddress);
                            closeOverlay();
                        }}
                        defaultViewState={AddressOverlayViewState.CreateForm}
                    />
                ),
                title: getDictionaryString("addAddress"),
                id: "farm-in-time-address-overlay",
            },
            { modal: { size: ModalSize.SM } }
        );
    }, [
        closeOverlay,
        deliveryAddresses,
        getDictionaryString,
        onAddressOverlayChanged,
        showOverlay,
    ]);

    const { tabs, currentTab, onTabChanged } = useTabs(currentDeliveryAddress);

    return (
        <>
            <header className={S.header}>
                <DA_Title h1 noMargin>
                    {getDictionaryString("farmintime")}
                </DA_Title>
            </header>

            <div className={S.filter}>
                {/** Delivery Address */}
                <DA_InputWrapper
                    label={getDictionaryString("FarmInTimeChooseDeliveryAddress")}
                    forId="farm-in-time-delivery-address"
                >
                    <DA_Select
                        id="farm-in-time-delivery-address"
                        disabled={
                            currentTab.id === "BEDRIFTSKATALOG" ||
                            deliveryAddressOptions.length === 0
                        }
                        value={currentDeliveryAddressOption?.value}
                        options={deliveryAddressOptions}
                        onChange={onDeliveryAddressSelectChanged}
                        postfix={
                            <button className={S.addAddress} onClick={addAddress}>
                                {getDictionaryString("addNewDeliveryAddress")}
                            </button>
                        }
                        methodsRef={selectRef}
                    />

                    {currentTab.id === "BEDRIFTSKATALOG" && (
                        <DA_Tooltip
                            text={getDictionaryString(
                                "FarmInTimeChooseDeliveryAddressDisabledTooltip"
                            )}
                            icon={DA_IconNames.Question}
                        />
                    )}
                </DA_InputWrapper>
            </div>

            {/** Tabs */}
            <DA_Tab
                items={tabs}
                onTabChange={onTabChanged}
                key={currentDeliveryAddress?.id || "no-address"}
            />
        </>
    );
};
