import { useState } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { isEmailValid } from "@danishagro/shared/src/helpers/validateEmail.helper";
import { NotificationContact } from "../../../../../../Settings/interfaces/notificationContact.interface";

export const useValidation = (inputLines: number) => {
    const { getDictionaryString } = useTranslations();

    const [validationErrorMessages, setValidationErrorMessages] = useState<
        { key: string; value: string }[]
    >([]);

    const onInputChange = (key: string, index: number) => {
        let result = [...validationErrorMessages];

        result = result.filter((x) => x.key !== `${key}-${index}`);

        setValidationErrorMessages(result);
    };

    const validate = (body: NotificationContact) => {
        const tmpValidationErrorMessages = [];

        for (let i = 0; i < inputLines; i++) {
            // Name: Mandatory
            if (!body[`name-${i}`] || body[`name-${i}`]?.length === 0) {
                tmpValidationErrorMessages.push({
                    key: `name-${i}`,
                    value: getDictionaryString("NotificationServiceValidationNameRequiredMobile", {
                        ROW: i + 1,
                    }),
                });
            }

            // Email: Mandatory
            if (!body[`email-${i}`] || body[`email-${i}`]?.length === 0) {
                tmpValidationErrorMessages.push({
                    key: `email-${i}`,
                    value: getDictionaryString("NotificationServiceValidationEmailRequiredMobile", {
                        ROW: i + 1,
                    }),
                });
            }

            // Email: Validation
            if (!isEmailValid(body[`email-${i}`])) {
                tmpValidationErrorMessages.push({
                    key: `email-${i}`,
                    value: getDictionaryString("NotificationServiceValidationEmailFormatMobile", {
                        ROW: i + 1,
                    }),
                });
            }

            // Phone: Mandatory
            if (!body[`phone-${i}`] || body[`phone-${i}`]?.length === 0) {
                tmpValidationErrorMessages.push({
                    key: `phone-${i}`,
                    value: getDictionaryString("NotificationServiceValidationPhoneRequiredMobile", {
                        ROW: i + 1,
                    }),
                });
            }

            // Phone: Validation (Phone Number)
            if (!/^\+45\d{8}$/.test(body[`phone-${i}`])) {
                tmpValidationErrorMessages.push({
                    key: `phone-${i}`,
                    value: getDictionaryString("NotificationServiceValidationPhoneFormatMobile", {
                        ROW: i + 1,
                    }),
                });
            }
        }

        setValidationErrorMessages(tmpValidationErrorMessages);

        return tmpValidationErrorMessages.length === 0;
    };

    const getError = (name: string, index: number) => {
        return validationErrorMessages.find((x) => x.key === `${name}-${index}`);
    };

    return {
        validationErrorMessages,
        validate,
        onInputChange,
        getError,
    };
};
