import React, { useCallback, useMemo, useState } from "react";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_QuantityPicker } from "@molecules/QuantityPicker/QuantityPicker.component";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { DA_Spinner } from "@danishagro/shared/src/components/atoms/Spinner/Spinner.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { DA_ConfirmModal } from "@danishagro/shared/src/components/molecules/ConfirmModal/ConfirmModal.component";
import { DA_ButtonColor } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import Skeleton from "react-loading-skeleton";
import { debounce } from "lodash";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { DA_Tag } from "@danishagro/shared/src/components/atoms/Tag/Tag.component";
import { isGreaterThanZero } from "@danishagro/shared/src/helpers/greaterThanZero.helper";
import { ProductItemProps } from "./ProductItem.interface";
import { FrequencyPicker } from "./components/FrequencyPicker/FrequencyPicker.component";
import { getMonthIndexFromMonth } from "./helpers/getMonthIndexFromMonth.helper";
import S from "./ProductItem.module.scss";

export const ProductItem = (props: ProductItemProps) => {
    const { getDictionaryString, getDictionaryItem } = useTranslations();
    const { currentCulture, customerNumber } = useAppData();
    const { isMobile } = useScreen();
    const { showModal, closeModal } = useModal();

    const { updateFarmInTimeProductV2, deleteFarmInTimeProduct } = useFarmInTimeApi();

    const [saveState, setSaveState] = useState("NONE");
    const saveStateTimeout = React.useRef<NodeJS.Timeout>();

    const [currentMonth, setCurrentMonth] = useState(
        getMonthIndexFromMonth(props.months, props.farmInTimeMonth)
    );

    const [currentFrequency, setCurrentFrequency] = useState(props.farmInTimeFrequency);

    const [currentQuantity, setCurrentQuantity] = useState(props.quantity);

    const priceFormatted = useMemo(
        () =>
            props.price ? (
                formatNumber(props.price?.priceWithoutVat, currentCulture, {
                    decimals: 2,
                })
            ) : (
                <Skeleton width={50} />
            ),
        [props.price, currentCulture]
    );

    const updateItem = useCallback(
        (partialItem: object) => {
            setSaveState("LOADING");

            updateFarmInTimeProductV2(
                customerNumber,
                props.currentAddress?.id,
                props.farmInTimeId,
                {
                    productId: props.productId,
                    quantity: currentQuantity,
                    variantId: props.dwVariantId,
                    frequency: currentFrequency,
                    month: currentMonth,
                    ...partialItem,
                }
            ).then(() => {
                setSaveState("SAVED");
                if (typeof props.onItemUpdate !== "undefined") {
                    props.onItemUpdate();
                }

                clearTimeout(saveStateTimeout.current);

                saveStateTimeout.current = setTimeout(() => {
                    setSaveState("NONE");
                }, 2000);
            });
        },
        [
            updateFarmInTimeProductV2,
            customerNumber,
            props,
            currentQuantity,
            currentFrequency,
            currentMonth,
        ]
    );

    // Update
    const onQuantityChanged = useCallback(
        (newQuantity: number) => {
            setCurrentQuantity(newQuantity);

            const debouncedFn = debounce(() => {
                updateItem({
                    quantity: newQuantity,
                });
            }, 1500);

            debouncedFn();
        },
        [updateItem]
    );

    const deleteItem = useCallback(() => {
        deleteFarmInTimeProduct(customerNumber, props.currentAddress?.id, props.farmInTimeId).then(
            () => {
                if (typeof props.onItemUpdate !== "undefined") {
                    props.onItemUpdate();
                }

                setSquashed(true);
                closeModal();
            }
        );
    }, [closeModal, customerNumber, deleteFarmInTimeProduct, props]);

    const onDeleteButtonClicked = () => {
        showModal(
            <DA_ConfirmModal
                title={getDictionaryString("FarmInTimeSettingsDeleteTitle")}
                description={getDictionaryString("FarmInTimeSettingsDeleteDescription")}
                approveButtonLabel={getDictionaryString("confirmDelete")}
                approveButtonColor={DA_ButtonColor.Alert}
                onApprove={() => deleteItem()}
                denyButtonLabel={getDictionaryString("cancel")}
            />,
            { size: ModalSize.XS }
        );
    };

    const [squashed, setSquashed] = useState(false);
    const [itemRef, { height }] = useMeasure();
    const styles = useSpring({
        height: squashed ? 0 : isMobile ? undefined : height,
        immediate: !squashed,
    });

    const url = `/${props.productUrl}`.replace(/^\/+/, "/");

    return (
        <animated.div style={styles}>
            <div className={S.wrapper} ref={itemRef}>
                {isMobile ? (
                    <div className={cn(S.item, props.className)}>
                        <div className={S.mobileImageAndData}>
                            <div className={S.mobileImageWrapper}>
                                {/** Image */}
                                <DA_BasicLink href={url}>
                                    <DA_Image
                                        src={B2bImageSrc(props.image.url, ImageConfig.PdpThumbnail)}
                                        alt={props.productName}
                                        className={S.image}
                                    />
                                </DA_BasicLink>
                            </div>

                            {/** Name */}
                            <div>
                                <div className={S.id}>{props.productNumber}</div>
                                <DA_BasicLink href={url} className={S.nameLink}>
                                    <div className={S.name}>{props.productName} </div>
                                </DA_BasicLink>
                            </div>
                        </div>
                        <div>
                            <div className={S.mobileFrequencyTitle}>
                                {getDictionaryString("frequency")}
                            </div>
                            <div className={S.mobileSelectWrapper}>
                                <FrequencyPicker
                                    disabled={saveState === "LOADING"}
                                    frequencyOptions={props.frequencyOptions}
                                    months={props.months}
                                    monthOptions={props.monthOptions}
                                    currentMonth={currentMonth}
                                    setCurrentMonth={setCurrentMonth}
                                    currentFrequency={currentFrequency}
                                    setCurrentFrequency={setCurrentFrequency}
                                    onItemUpdated={updateItem}
                                />
                            </div>
                        </div>
                        <div className={S.mobileQuantityPriceWrapper}>
                            <div>
                                {/** Quantity Counter */}
                                <DA_QuantityPicker
                                    onChange={onQuantityChanged}
                                    value={currentQuantity}
                                    disabled={saveState === "LOADING"}
                                />

                                {/** Delete Button */}
                                <button
                                    type="button"
                                    className={S.removeButton}
                                    disabled={saveState === "LOADING"}
                                    onClick={onDeleteButtonClicked}
                                >
                                    <DA_Icon name={DA_IconNames.Trash} className={S.removeIcon} />
                                </button>
                            </div>
                            {/** Indicator */}
                            <div className={S.saveIndicatorWrapper}>
                                <div className={S.saveIndicatorContent}>
                                    {saveState === "LOADING" && <DA_Spinner />}
                                    {saveState === "SAVED" && (
                                        <DA_Icon name={DA_IconNames.Checkmark} />
                                    )}
                                </div>
                            </div>

                            {/** Price */}
                            <div className={S.total}>
                                <span className={S.totalLabel}>
                                    {getDictionaryString("total")}:{" "}
                                </span>
                                {priceFormatted}
                            </div>
                        </div>
                        <div className={S.labelTagWrapper}>
                            {isGreaterThanZero(props.quantityOnPallet) && (
                                <DA_Tag color="neutral" size="dynamic">
                                    {getDictionaryItem("quantityonpallettext", {
                                        quantity: props.quantityOnPallet,
                                    })}
                                </DA_Tag>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={cn(S.item, props.className)}>
                        <div className={S.imageAndDataWrapper}>
                            {/** Image */}
                            <div className={S.imageWrapper}>
                                <DA_BasicLink href={url}>
                                    <DA_Image
                                        src={B2bImageSrc(props.image.url, ImageConfig.PdpThumbnail)}
                                        alt={props.productName}
                                        className={S.image}
                                    />
                                </DA_BasicLink>
                            </div>

                            {/** Name */}
                            <div>
                                <div className={S.id}>{props.productNumber}</div>
                                <DA_BasicLink href={url} className={S.nameLink}>
                                    <div className={S.name}>{props.productName}</div>
                                </DA_BasicLink>
                            </div>
                        </div>

                        {/** Frequency */}
                        <div className={S.actions}>
                            <FrequencyPicker
                                disabled={saveState === "LOADING"}
                                frequencyOptions={props.frequencyOptions}
                                months={props.months}
                                monthOptions={props.monthOptions}
                                currentMonth={currentMonth}
                                setCurrentMonth={setCurrentMonth}
                                currentFrequency={currentFrequency}
                                setCurrentFrequency={setCurrentFrequency}
                                onItemUpdated={updateItem}
                            />
                        </div>
                        {/** Amount */}
                        <div className={S.quantityWrapper}>
                            {/** Quantity Counter */}
                            <DA_QuantityPicker
                                onChange={onQuantityChanged}
                                value={currentQuantity}
                                disabled={saveState === "LOADING"}
                            />

                            {/** Delete Button */}
                            <button
                                type="button"
                                className={S.removeButton}
                                disabled={saveState === "LOADING"}
                                onClick={onDeleteButtonClicked}
                            >
                                <DA_Icon name={DA_IconNames.Trash} className={S.removeIcon} />
                            </button>

                            {isGreaterThanZero(props.quantityOnPallet) && (
                                <div className={S.labelTagWrapperAbsolute}>
                                    <DA_Tag color="neutral" size="dynamic">
                                        {getDictionaryItem("quantityonpallettext", {
                                            quantity: props.quantityOnPallet,
                                        })}
                                    </DA_Tag>
                                </div>
                            )}
                        </div>

                        {/** Indicator */}
                        <div className={S.saveIndicatorWrapper}>
                            {saveState === "LOADING" && <DA_Spinner />}

                            {saveState === "SAVED" && (
                                <div className={S.iconWrapper}>
                                    <DA_Icon name={DA_IconNames.Checkmark} />
                                </div>
                            )}
                        </div>

                        {isGreaterThanZero(props.quantityOnPallet) && (
                            <div className={S.labelTagWrapper}>
                                <DA_Tag color="neutral" size="dynamic">
                                    {getDictionaryItem("quantityonpallettext", {
                                        quantity: props.quantityOnPallet,
                                    })}
                                </DA_Tag>
                            </div>
                        )}

                        {/** Price */}
                        <div className={S.total}>
                            <span className={S.totalLabel}>{getDictionaryString("total")}: </span>
                            {priceFormatted}
                        </div>
                    </div>
                )}
            </div>
        </animated.div>
    );
};
