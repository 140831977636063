import { NotificationContact } from "../../../../../../Settings/interfaces/notificationContact.interface";

export const mapFormDataToArray = (body: NotificationContact): NotificationContact[] => {
    const dataArray = Object.keys(body).reduce((array, key) => {
        const prefix = key.split("-")[0];
        const index = key.split("-")[1];

        if (!array[index]) {
            array[index] = {};
        }

        array[index][prefix] = body[key];

        return array;
    }, []);

    return dataArray.filter((item) => Object.values(item).every((value) => value !== ""));
};
