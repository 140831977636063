import { DA_GlobalVariables } from "@danishagro/shared/src/interfaces/sharedGlobalVariables.interface";
import { getSharedGlobalVariables } from "@danishagro/shared/src/helpers/getSharedGlobalVariables.helper";
import { B2B_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";

export const getGlobalVariables = (
    customerNumber?: string,
    forceNew = false
): Promise<DA_GlobalVariables> =>
    new Promise((resolve, reject) =>
        getSharedGlobalVariables(forceNew)
            .then((sharedGlobalVariables) => {
                const currentCulture = globalThis.dictionaryObj?.culture || "da-dk";

                if (
                    location.hostname !== "localhost" &&
                    typeof globalThis.navigationObj === "undefined" &&
                    typeof globalThis.dictionaryObj === "undefined" &&
                    globalThis.statusCode !== 404
                ) {
                    globalThis.showServerError = true;
                }

                const getDictionaryObj = globalThis.dictionaryObj
                    ? Promise.resolve(globalThis.dictionaryObj)
                    : fetch(`${B2B_SITE_ORIGIN}/api/dictionary/getdictionaryobject`)
                          .then((res) => res.json())
                          .catch(() => ({
                              culture: currentCulture,
                              translations: [],
                          }));

                getDictionaryObj
                    .then((dictionaryObj) => {
                        globalThis.dictionaryObj = dictionaryObj;
                        resolve({
                            ...sharedGlobalVariables,
                            currentCulture,
                            navigationObj: globalThis.navigationObj || [],
                            dictionaryObj,
                            contentObj: { culture: dictionaryObj.culture, translations: {} }, // Only used on MyFarm
                            cultures: [], // Only used on MyFarm
                        });
                    })
                    .catch((err) => reject(err));
            })
            .catch((err) => reject(err))
    );
