import React, {
    ReactNode,
    useEffect,
    useMemo,
    useRef,
    useState,
    useImperativeHandle,
    forwardRef,
} from "react";
import { animated, useTransition } from "react-spring";
import { usePrevious } from "react-use";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useScreen } from "../../../contexts/screen.context";
import { DA_Container } from "../../atoms/Container/Container.component";
import { useChangeCallback } from "./hooks/useChangeCallback";
import S from "./Tab.module.scss";

export type DA_TabItem = {
    id?: string;
    title: string;
    content: ReactNode | string;
};

export interface DA_TabProps {
    items: (DA_TabItem | undefined)[];
    onTabChange?: (item: DA_TabItem) => void;
    isQuickOrder?: boolean;
}

export const DA_Tab = forwardRef((props: DA_TabProps, ref) => {
    const items = useMemo(
        () =>
            props.items.map(({ id, ...rest }) => ({
                id: id || encodeURIComponent(rest.title?.replace(/\s+/, "-").replace(/-+/, "-")),
                ...rest,
            })),
        [props.items]
    );
    const activeTabIdFromUrl = window.location.hash.replace("#tab-", "");
    const activeTabIndexFromUrl = items.findIndex((item) => item.id === activeTabIdFromUrl);
    const descriptionRef = useRef<HTMLDivElement>(null);

    const [currentTabIndex, setCurrentTabIndex] = useState<number>(
        activeTabIndexFromUrl >= 0 ? activeTabIndexFromUrl : 0
    );

    useImperativeHandle(ref, () => ({
        showDescriptionTab: () => {
            setCurrentTabIndex(0);

            setTimeout(() => {
                descriptionRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 50);
        },
        setActiveTab: (index: number) => {
            setCurrentTabIndex(index);
        },
    }));

    const currentTab = useMemo(() => items?.[currentTabIndex], [items, currentTabIndex]);

    const previousTabIndex = usePrevious(currentTabIndex);

    const tabRefs = useRef<HTMLLIElement[]>([]);

    const { isMobileOrTablet } = useScreen();

    const transition = useTransition(currentTabIndex, {
        key: currentTabIndex,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0, config: { duration: 0 } },
        config: { duration: 600 },
        immediate: typeof previousTabIndex === "undefined",
    });

    const renderContent = transition(
        (style, item) =>
            item === currentTabIndex && (
                <animated.div style={style}>
                    {typeof items?.[currentTabIndex]?.content === "string" ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: items?.[currentTabIndex]?.content as string,
                            }}
                        />
                    ) : (
                        items?.[currentTabIndex]?.content
                    )}
                </animated.div>
            )
    );

    const onTabChangeHandler = (item: DA_TabItem, index: number) => {
        setCurrentTabIndex(index);

        const newHash =
            index > 0 ? "#tab-" + item.id : window.location.pathname + window.location.search;
        history.replaceState(null, null, newHash);
    };

    useEffect(() => {
        if (
            previousTabIndex !== undefined &&
            tabRefs.current[currentTabIndex] &&
            !props.isQuickOrder
        ) {
            // Save the current scroll position
            const x = window.scrollX || window.pageXOffset;
            tabRefs.current[currentTabIndex].scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                // We keep 'inline' as 'start' because we are going to manually restore the x position
                inline: "start",
            });
            // Restore the horizontal scroll position
            window.scrollTo(x, window.scrollY || window.pageYOffset);
        }
    }, [currentTabIndex, previousTabIndex, props.isQuickOrder]);

    useEffect(() => {
        const changeActiveTab = () => {
            const tabIdFromUrl = window.location.hash.replace("#tab-", "");
            const tabIndexFromUrl = items.findIndex((item) => item.id === tabIdFromUrl);
            if (tabIndexFromUrl > -1 && tabIndexFromUrl !== currentTabIndex) {
                setCurrentTabIndex(tabIndexFromUrl);
            }
        };
        addEventListener("hashchange", changeActiveTab);
        return () => removeEventListener("hashchange", changeActiveTab);
    }, [items, currentTabIndex]);

    useChangeCallback(currentTab, props.onTabChange);

    return (
        <>
            <DA_Container noRightPadding={isMobileOrTablet} ref={descriptionRef}>
                <div className={S.tabBar}>
                    <div className={S.listWrapper}>
                        <ul className={S.list}>
                            {items.map((item, index) => (
                                <li key={index} ref={(ref) => (tabRefs.current[index] = ref)}>
                                    <button
                                        type="button"
                                        onClick={() => onTabChangeHandler(item, index)}
                                        className={cn(
                                            S.item,
                                            index === currentTabIndex && S.active
                                        )}
                                        title={item.title}
                                    >
                                        <span>{item.title}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className={S.line} />
                </div>
            </DA_Container>

            {renderContent}
        </>
    );
});

DA_Tab.displayName = "DA_Tab";
