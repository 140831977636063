import React, { ReactNode, MouseEvent, useCallback, useMemo, useState } from "react";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { DA_CartOrderLine } from "@danishagro/shared/src/interfaces/cartOrderLine.interface";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useNavigate } from "react-router-dom";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import Skeleton from "react-loading-skeleton";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useOrderLinesPriceData } from "@danishagro/shared/src/hooks/useOrderLinePriceData.hook";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_CartTotalProps } from "@organisms/CartTotal/CartTotal.component";
import { DA_CartTotal } from "@organisms/CartTotal/CartTotal.component";
import { DA_CheckoutCartItems } from "../CheckoutCartItems/CheckoutCartItems.component";
import S from "./CheckoutCartList.module.scss";

export interface DA_CheckoutCartListProps extends DA_CartTotalProps {
    items: DA_CartOrderLine[];
    children?: ReactNode;
    isFarmInTimeCart?: boolean;
}

export const DA_CheckoutCartList = React.memo((props: DA_CheckoutCartListProps) => {
    const { getDictionaryString } = useTranslations();
    const { currentCulture, showPrices } = useAppData();
    const orderLinesPriceData = useOrderLinesPriceData();
    const navigate = useNavigate();
    const { isMobile } = useScreen();
    const { isFarmInTimeCart, expressDeliveryModes, priceData } = useCart();

    // Navigate back to cart
    const onEditButtonClicked = () => {
        navigate("/cart");
    };

    // Format price
    const formatPrice = useCallback(
        (price: number) => formatNumber(price, currentCulture, { decimals: 2 }),
        [currentCulture]
    );

    // Show total price or skeleton
    const formattedPrices = useMemo(
        () => ({
            totalPrice: priceData ? (
                formatPrice(priceData?.totalPrice.priceWithVat)
            ) : (
                <Skeleton width={80} />
            ),
        }),
        [priceData, formatPrice]
    );

    // Total basket quantity
    const totalQuantity = useMemo(() => {
        return props.items.reduce((total, item) => total + item.quantity, 0);
    }, [props.items]);

    const [isMobileBasketCollapsed, setIsMobileBasketCollapsed] = useState<boolean>(false);
    const isMobileBasketCollapsedClass = isMobileBasketCollapsed ? S.isCollapsed : undefined;

    // Expand mobile basket list
    const onMobileBasketExpandToggle = useCallback(
        (event?: MouseEvent<HTMLButtonElement>) => {
            event?.preventDefault();

            setIsMobileBasketCollapsed(!isMobileBasketCollapsed);
        },
        [isMobileBasketCollapsed]
    );

    const quantityString = `(${totalQuantity} ${
        totalQuantity === 1 ? getDictionaryString("product") : getDictionaryString("products")
    })`;

    return (
        <>
            {isMobile ? (
                <>
                    {/* Mobile view */}
                    <section
                        onClick={onMobileBasketExpandToggle}
                        onKeyDown={(e) => {
                            // Execute on "Enter" key press
                            if (e.code === "Enter" || e.code === "Space") {
                                onMobileBasketExpandToggle();
                            }
                        }}
                        role="button" // role indicates it is meant to be a button
                        tabIndex={0}
                        className={cn(S.wrapper, S.mobileWrapper)}
                    >
                        <div className={S.header}>
                            {/* {getDictionaryString("CheckoutCartListHeading")} */}
                            <DA_ButtonLink
                                icon={
                                    isMobileBasketCollapsed
                                        ? DA_IconNames.ChevronSmallDown
                                        : DA_IconNames.ChevronSmallUp
                                }
                                title={
                                    isMobileBasketCollapsed
                                        ? `${getDictionaryString("ShowBasket")} ${quantityString}`
                                        : `${getDictionaryString("HideBasket")} ${quantityString}`
                                }
                            />

                            {showPrices && (
                                <span className={S.total}>{formattedPrices.totalPrice}</span>
                            )}
                        </div>
                        <div
                            className={cn(S.mobileBasketDetailsInner, isMobileBasketCollapsedClass)}
                        >
                            {!isFarmInTimeCart ? (
                                <DA_ButtonLink
                                    onClick={onEditButtonClicked}
                                    title={getDictionaryString("CheckoutCartListEditCart")}
                                    className={S.editBasket}
                                />
                            ) : null}

                            <DA_CheckoutCartItems
                                items={props.items}
                                orderLinesPriceData={orderLinesPriceData}
                                currentCulture={currentCulture}
                                expressDeliveryModes={expressDeliveryModes}
                                getDictionaryString={getDictionaryString}
                                className={S.mobileCheckoutListItems}
                                isFarmInTimeCart={isFarmInTimeCart}
                            />
                            <DA_CartTotal
                                className={S.mobileTotalCollapse}
                                prices={priceData}
                                hideButtons={true}
                            />
                        </div>
                    </section>
                </>
            ) : (
                <>
                    {/* Tablet & Desktop view */}
                    <section className={S.wrapper}>
                        <div className={S.header}>
                            <DA_Title h3 noMargin>
                                {getDictionaryString("CheckoutCartListHeading")}
                            </DA_Title>

                            {!isFarmInTimeCart ? (
                                <DA_ButtonLink
                                    onClick={onEditButtonClicked}
                                    title={getDictionaryString("CheckoutCartListEditCart")}
                                />
                            ) : null}
                        </div>

                        {/** Items */}
                        <DA_CheckoutCartItems
                            items={props.items}
                            orderLinesPriceData={orderLinesPriceData}
                            currentCulture={currentCulture}
                            expressDeliveryModes={expressDeliveryModes}
                            getDictionaryString={getDictionaryString}
                            isFarmInTimeCart={isFarmInTimeCart}
                        />

                        {/** Children */}
                        {props.children}
                    </section>
                </>
            )}
        </>
    );
});

DA_CheckoutCartList.displayName = "DA_CheckoutCartList";
