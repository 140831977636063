import React from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { getUrl } from "@danishagro/shared/src/helpers/getUrl.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import S from "./ComponentErrorBoundary.module.scss";

// ErrorBoundary props definition
type ErrorBoundaryProps = {
    children: React.ReactNode;
    fallback: React.ReactNode;
    showNothing?: boolean;
    componentName: string;
    showBlank?: boolean;
};

// ErrorBoundary state definition
type ErrorBoundaryState = {
    hasError: boolean;
};

// Main ErrorBoundary class component
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    // Update state to show fallback UI upon encountering an error
    static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    // Log error details for debugging
    componentDidCatch(error: Error, info: React.ErrorInfo) {
        console.error("Uncaught error:", error, info);
    }

    logError() {
        console.log(`Error detected in component: ${this.props.componentName}`);
    }

    renderErrorContent() {
        if (this.props.showNothing) {
            this.logError();
            return null;
        } else if (this.props.showBlank) {
            this.logError();
            return (
                <DA_Container>
                    <div className={S.errorWrapper}>
                        <div className={S.errorItem} />
                    </div>
                </DA_Container>
            );
        }
        return this.props.fallback;
    }

    render() {
        return this.state.hasError ? this.renderErrorContent() : this.props.children;
    }
}

// ComponentErrorBoundary props definition
type ComponentErrorBoundaryProps = {
    children: React.ReactNode;
    componentName: string;
    showNothing?: boolean;
    showBlank?: boolean;
};

// Functional component to wrap individual components and handle errors
const ComponentErrorBoundary: React.FC<ComponentErrorBoundaryProps> = ({
    children,
    componentName,
    showNothing = false,
    showBlank = false,
}) => {
    const { getDictionaryString } = useTranslations();

    return (
        <ErrorBoundary
            showNothing={showNothing}
            componentName={componentName}
            showBlank={showBlank}
            fallback={
                <DA_Container>
                    <div className={S.errorWrapper}>
                        <div className={S.errorItem}>
                            <strong>{getDictionaryString("Noget gik galt med")}</strong>
                            <p>{componentName}</p>
                            <p>
                                {getDictionaryString("Du kan evt.")}{" "}
                                <DA_BasicLink href={getUrl("contact")}>
                                    {getDictionaryString("kontakte kundeservice")}
                                </DA_BasicLink>
                            </p>
                        </div>
                    </div>
                </DA_Container>
            }
        >
            {children}
        </ErrorBoundary>
    );
};

export default ComponentErrorBoundary;
