import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_ProductPrice } from "@danishagro/shared/src/interfaces/price.interface";
import Skeleton from "react-loading-skeleton";
import S from "./Price.module.scss";

export interface DA_PriceProps {
    priceLabel: string;
    price: DA_ProductPrice;
    discountedPrice?: DA_ProductPrice;
    comment?: string;
    hasVariants?: boolean;
    largeSpacing?: boolean;
    alternative?: boolean;
    isQuickOrder?: boolean;
}

export const DA_Price = ({
    priceLabel,
    price,
    discountedPrice,
    comment,
    hasVariants,
    largeSpacing,
    alternative,
    isQuickOrder,
}: DA_PriceProps) => {
    const { currentCulture } = useAppData();
    return (
        <div
            className={cn(S.container, S.alternativeContainer)}
            itemProp="offers"
            itemScope
            itemType="https://schema.org/Offer"
        >
            {!alternative && <div className={S.priceLabel}>{priceLabel}</div>}
            <div className={cn(S.price, largeSpacing && S.largeSpacing)}>
                <div className={cn(S.currentPrice, isQuickOrder && S.quickOrderPrice)}>
                    {hasVariants && <span className={S.label}>Fra </span>}
                    {price ? (
                        <>
                            {formatNumber(price.priceWithoutVat, currentCulture, { decimals: 2 })}
                            <meta itemProp="price" content={price.toString()} />
                            <meta itemProp="priceCurrency" content={price.currency} />
                        </>
                    ) : (
                        <Skeleton width={80} />
                    )}
                </div>

                {discountedPrice && (
                    <div className={S.discountedPrice}>
                        {formatNumber(discountedPrice.priceWithoutVat, currentCulture, {
                            decimals: 2,
                        })}
                    </div>
                )}
            </div>

            {alternative && (
                <div className={S.alternativePriceLabel}>{`${priceLabel} ${
                    comment && comment
                }`}</div>
            )}

            {!alternative && comment && <span className={S.comment}>{comment}</span>}
        </div>
    );
};
