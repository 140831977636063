import React, { useState } from "react";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { animated, useSpring } from "react-spring";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useMeasure } from "react-use";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useCreateCartRequest } from "@templates/FarmInTime/components/Cart/hooks/useCreateCartRequest.hook";
import { DA_DynamicOverlayWrapper } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import Skeleton from "react-loading-skeleton";
import { useOrderLinesPriceData } from "@danishagro/shared/src/hooks/useOrderLinePriceData.hook";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { SelectedProductsType } from "../../contexts/FarmInTimeQuickSelectProvider";
import S from "./MergePrompt.module.scss";

export interface DA_MergePromptProps {
    addressId: string;
    selectedProducts?: SelectedProductsType;
}

export const DA_MergePrompt = (props: DA_MergePromptProps) => {
    const { getDictionaryString } = useTranslations();
    const [isExpanded, setIsExpanded] = useState(false);
    const [itemRef] = useMeasure();
    const { currentCulture } = useAppData();
    const { orderLines } = useCart();
    const orderLinesPriceData = useOrderLinesPriceData();
    const { isMobileOrTablet } = useScreen();

    const { createCart, awaitingCreateResponse } = useCreateCartRequest(
        props.addressId,
        props.selectedProducts
    );

    const styles = useSpring({
        height: `${isExpanded ? (isMobileOrTablet ? `100%` : `180px`) : 0}`,
        config: { friction: 24 },
    });

    return (
        <div className={S.promptContent}>
            <DA_DynamicOverlayWrapper
                content={
                    <>
                        <DA_Text noPadding>{getDictionaryString("CartMergeModalText")}</DA_Text>
                        <button
                            aria-expanded={isExpanded}
                            onClick={() => setIsExpanded(!isExpanded)}
                            className={S.expandButton}
                        >
                            {!isExpanded
                                ? getDictionaryString("MergeModalShowCart")
                                : getDictionaryString("MergeModalCloseCart")}
                            <DA_Icon
                                name={DA_IconNames.ChevronSmallDown}
                                className={cn(S.arrow, isExpanded && S.rotated)}
                            />
                        </button>

                        <animated.div
                            className={cn(S.cart, isExpanded && S.expanded)}
                            style={styles}
                            ref={itemRef}
                        >
                            <div className={S.cartHeader} aria-hidden={true}>
                                <div className={S.cartHeaderItem}>
                                    {getDictionaryString("product")}
                                </div>
                                <div className={S.cartHeaderItem}>
                                    {getDictionaryString("amount")}
                                </div>
                                <div className={S.cartHeaderItem}>
                                    {getDictionaryString("price")}
                                </div>
                            </div>

                            <div className={S.cartItemsWrapper}>
                                {orderLines.map((orderLine, index) => {
                                    return (
                                        <div key={index} className={S.itemWrapper}>
                                            <div className={S.imageNameWrapper}>
                                                <div key={index} className={S.imageWrapper}>
                                                    <img
                                                        src={`${orderLine.imageUrl}/${ImageConfig.SearchImage}`}
                                                        alt={orderLine.productName}
                                                    />
                                                </div>
                                                <div className={S.productName}>
                                                    {orderLine.productName}
                                                </div>
                                            </div>
                                            <div className={S.quantity}>
                                                {orderLine.quantity} {getDictionaryString("pcs")}
                                            </div>
                                            <div className={S.price}>
                                                {orderLinesPriceData?.[index] ? (
                                                    formatNumber(
                                                        orderLinesPriceData[index].price
                                                            .priceWithoutVat,
                                                        currentCulture,
                                                        {
                                                            decimals: 2,
                                                        }
                                                    )
                                                ) : (
                                                    <Skeleton width={50} />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </animated.div>
                    </>
                }
                footer={
                    <div className={S.buttonWrapper}>
                        {/** Submit */}
                        <DA_Button
                            title={getDictionaryString("cartMergeModalSubmitButton")}
                            onClick={() => createCart(true)}
                            className={S.primaryButton}
                            showSpinner={awaitingCreateResponse === "MERGE"}
                            disabled={awaitingCreateResponse === "NO-MERGE"}
                        />
                        {/** Decline */}
                        <DA_Button
                            title={getDictionaryString("cartMergeModalDeclineButton")}
                            onClick={() => createCart(false)}
                            className={S.primaryButton}
                            showSpinner={awaitingCreateResponse === "NO-MERGE"}
                            disabled={awaitingCreateResponse === "MERGE"}
                            isGhost
                        />
                    </div>
                }
            />
        </div>
    );
};
