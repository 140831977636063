import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DA_NavigationPageSimple } from "@danishagro/shared/src/interfaces/navigationPage.interface";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_VetMedicationImageLink } from "@danishagro/shared/src/components/molecules/VetMedication/VetMedication.component";
import { DA_VetMedication } from "@danishagro/shared/src/interfaces/vetMedication.interface";

interface PageHook {
    allPages: Omit<DA_NavigationPageSimple, "children">[];
    pageTree: DA_NavigationPageSimple[];
    currentPathDetails: ({ index: number } & Omit<DA_NavigationPageSimple, "children">)[];
    pageIsLoading: boolean;
    setPageIsLoading: (state: boolean) => void;
    setVetMedication: (data: DA_VetMedication) => void;
    getCategoryId: (categoryPath: string) => string;
    getCategoryFromId: (categoryId: string) => DA_NavigationPageSimple;
    getCategoryPathFromId: (categoryId: string) => string;
    getCategoryFacetsFromId: (categoryId: string) => string[];
}

const PageContext = React.createContext<PageHook>({
    allPages: [],
    pageTree: [],
    currentPathDetails: [],
    pageIsLoading: false,
    setPageIsLoading: () => null,
    setVetMedication: () => null,
    getCategoryId: () => null,
    getCategoryFromId: () => null,
    getCategoryPathFromId: () => null,
    getCategoryFacetsFromId: () => null,
});

type Props = {
    children: React.ReactNode;
};

const createPageArray = (pageArray: DA_NavigationPageSimple[]): DA_NavigationPageSimple[] => {
    const pages = [];

    (pageArray || []).map(({ children, ...current }) => {
        pages.push(current);

        if (children && children.length) {
            pages.push(...createPageArray(children));
        }
    });

    return pages;
};

export const PageProvider = ({ children }: Props): JSX.Element => {
    const [pageIsLoading, setPageIsLoading] = useState(false);
    const [currentPathDetails, setCurrentPathDetails] = useState([]);
    const [vetMedication, setVetMedication] = useState<DA_VetMedication>();
    const { navigationObj } = useAppData();
    const { pathname } = useLocation();
    const allPages: DA_NavigationPageSimple[] = createPageArray(navigationObj);

    const explorePath = useCallback((pathNames: string[], tree: DA_NavigationPageSimple[]) => {
        const indexes = [];
        tree.find(({ children, ...pageData }, index) => {
            if (pageData?.path) {
                const pathEnd = pageData.path.match(/\/?[^/]+\/?$/) || [pageData.path];
                if (pathEnd?.length && pathEnd[0].replace(/^\/|\/$/g, "") === pathNames[0]) {
                    indexes.push({ index, ...pageData });
                    if (typeof pathNames[1] === "string" && children) {
                        indexes.push(...explorePath(pathNames.slice(1), children));
                    }
                    return true;
                }
            }
        });
        return indexes;
    }, []);

    const getCategoryId = useCallback(
        (categoryPath: string): string => {
            const [result] = currentPathDetails.filter(
                (pathName) => pathName.path.replace(/^\/|\/$/, "") === categoryPath
            );

            return result?.categoryId?.toString() || undefined;
        },
        [currentPathDetails]
    );

    const getCategoryFromId = useCallback(
        (categoryId: string): DA_NavigationPageSimple => {
            return allPages.find((item) => item.categoryId?.toString() === categoryId);
        },
        [allPages]
    );

    const getCategoryPathFromId = useCallback(
        (categoryId: string): string => {
            const result = allPages.find((item) => item.categoryId?.toString() === categoryId);
            return result ? result.path : undefined;
        },
        [allPages]
    );

    const getCategoryFacetsFromId = useCallback(
        (categoryId: string): string[] => {
            const result = allPages.find((item) => item.categoryId?.toString() === categoryId);
            return result ? result.facets : [];
        },
        [allPages]
    );

    useEffect(() => {
        const pathNames = pathname.replace(/^\/|\/$/, "").split("/");
        const scopeIndexes = explorePath(pathNames, navigationObj);
        setCurrentPathDetails(scopeIndexes);
    }, [pathname, explorePath, navigationObj]);

    return (
        <PageContext.Provider
            value={{
                allPages,
                pageTree: navigationObj,
                currentPathDetails,
                pageIsLoading,
                setPageIsLoading,
                setVetMedication,
                getCategoryId,
                getCategoryFromId,
                getCategoryPathFromId,
                getCategoryFacetsFromId,
            }}
        >
            {children}

            {vetMedication?.labelUrl && vetMedication?.siteUrl && (
                <DA_VetMedicationImageLink
                    pathname={pathname}
                    link={vetMedication.siteUrl}
                    logo={vetMedication.labelUrl}
                />
            )}
        </PageContext.Provider>
    );
};

export const usePage = (): PageHook => React.useContext(PageContext);
