import { B2B_SITE_ORIGIN } from "@danishagro/shared/src/constants/urlRoot.constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPage: any = () => {
    const path = window.location.pathname + window.location.search;

    return globalThis.pageContent
        ? new Promise((resolve) => {
              const pageContent = { ...globalThis.pageContent };
              globalThis.pageContent = undefined;
              resolve(pageContent);
          })
        : fetch(`${B2B_SITE_ORIGIN}/api${path}`).then((response) => response.json());
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPageByUrl: any = (url: string) => {
    return fetch(`${B2B_SITE_ORIGIN}/api${url}`).then((response) => response.json());
};
