import React from "react";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_UspItem, DA_UspItemProps } from "@molecules/UspItem/UspItem.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import S from "./UspList.module.scss";

export interface DA_UspListProps {
    items: DA_UspItemProps[];
    header?: string;
    description?: string;
    mobileFullWidth?: boolean;
}

export const DA_UspList = ({ items, mobileFullWidth, header, description }: DA_UspListProps) => (
    <DA_Container className={cn(S.wrapper, mobileFullWidth && S.mobileFullWidth)}>
        <div className={S.list}>
            <div className={S.uspFirst}>
                <DA_Title h2 fullWidth>
                    {header}
                </DA_Title>
                <DA_Text noPadding html={description} />
            </div>
            {items.map((item, index) => (
                <div key={index} className={S.listItem}>
                    <DA_UspItem className={S.uspItem} {...item} />
                </div>
            ))}
        </div>
    </DA_Container>
);
