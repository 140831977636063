export enum DA_ViewStates {
    DELETING = "DELETING",
    DONE = "DONE",
    EMPTY = "EMPTY",
    ERROR = "ERROR",
    INIT = "INIT",
    LOADING = "LOADING",
    NONE = "NONE",
    SAVING = "SAVING",
    SAVED = "SAVED",
}
