import React, { useCallback } from "react";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { getUrl } from "@danishagro/shared/src/helpers/getUrl.helper";
import S from "./ShopPicker.module.scss";

export const DA_ShopPicker = () => {
    const { getDictionaryString } = useTranslations();
    const { isMobile } = useScreen();
    const { closeModal } = useModal();

    const useB2bSite = useCallback(() => {
        localStorage.setItem(`b2bShop`, `accepted`);
        closeModal();
    }, [closeModal]);

    return (
        <DA_ContentBox>
            <div className={S.textWrapper}>
                <DA_Text smallText noPadding html={getDictionaryString("ShopPickerTitle")} />
            </div>
            <div className={S.buttons}>
                <div className={S.buttonWrapper}>
                    <DA_Button
                        onClick={useB2bSite}
                        title={getDictionaryString("ShopPickerButtonProfessional")}
                    />
                    <span className={S.priceText}>
                        {isMobile
                            ? getDictionaryString("ShopPickerProTextMobile")
                            : getDictionaryString("ShopPickerProTextDesktop")}
                    </span>
                </div>
                <div className={S.divider} />
                <div className={S.buttonWrapper}>
                    <DA_Button
                        title={getDictionaryString("ShopPickerButtonPrivate")}
                        href={getUrl("b2cShop")}
                        target="_self"
                    />
                    <span className={S.priceText}>
                        {isMobile
                            ? getDictionaryString("ShopPickerPrivateTextMobile")
                            : getDictionaryString("ShopPickerPrivateTextDesktop")}
                    </span>
                </div>
            </div>
        </DA_ContentBox>
    );
};
