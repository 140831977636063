import { useState, useEffect, useMemo } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { addDays, format } from "date-fns";

export const useDeliveryOptions = () => {
    const { getDictionaryString } = useTranslations();

    const deliveryOptions = useMemo(
        () => [
            {
                title: getDictionaryString("deliveryoptions.deliverytitle"),
                description: getDictionaryString("deliveryoptions.deliverydescription"),
                code: "70",
                selected: true,
            },
            {
                title: getDictionaryString("deliveryoptions.pickuptitle"),
                description: getDictionaryString("deliveryoptions.pickupdescription"),
                code: "40",
                selected: false,
            },
        ],
        [getDictionaryString]
    );

    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<string>(
        deliveryOptions.find((option) => option.selected)?.code || ""
    );

    const [deliveryDate, setDeliveryDate] = useState<string>(undefined);
    const deliveryOptionMinDate = format(addDays(new Date(), 1), "yyyy-MM-dd");

    useEffect(() => {
        deliveryOptions.map((option) => (option.selected = option.code === selectedDeliveryOption));
    }, [deliveryOptions, selectedDeliveryOption]);

    return {
        deliveryOptions,
        selectedDeliveryOption,
        setSelectedDeliveryOption,
        deliveryDate,
        setDeliveryDate,
        deliveryOptionMinDate,
    };
};
