import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import S from "./StockStatus.module.scss";

export interface DA_StockStatusProps {
    fewInStock?: boolean;
    discontinued?: boolean;
    comment?: string;
    large?: boolean;
}

export const DA_StockStatus = ({
    fewInStock,
    discontinued,
    large,
    comment,
}: DA_StockStatusProps) => {
    const { getDictionaryString } = useTranslations();
    const stockClass = cn(
        S.status,
        large && S.statusLarge,
        fewInStock ? S.fewInStock : discontinued && S.discontinued
    );

    return (
        <div className={S.container}>
            <span className={stockClass} />
            <div>
                <span className={large && S.stockTextLarge}>
                    {(fewInStock && getDictionaryString("few in stock")) ||
                        (discontinued && getDictionaryString("discontinued")) ||
                        getDictionaryString("in stock")}
                </span>
                {comment && <span className={S.comment}> - {comment}</span>}
            </div>
        </div>
    );
};
