import React from "react";
import { useCustomer } from "@danishagro/shared/src/hooks/useCustomer.hook";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Toast } from "@danishagro/shared/src/components/molecules/Toast/Toast.component";
import { WishListToastProps } from "./WishListToast.interface";

export const DA_WishListToast = (props: WishListToastProps) => {
    const { getDictionaryString } = useTranslations();
    const { customer } = useCustomer();

    return (
        <DA_Toast
            href={props.href}
            message={
                <>
                    <div>{props.heading}</div>
                    {customer && (
                        <div className="break-all">
                            {getDictionaryString("ToastAccountText")}
                            <strong>{customer?.name}</strong>
                        </div>
                    )}
                </>
            }
            goTo={getDictionaryString("WishListToastButton")}
        />
    );
};
