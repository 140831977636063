import React, { useCallback, useMemo, useRef } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { getLoginLink } from "@danishagro/shared/src/helpers/getLoginLink.helper";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_CartPriceData } from "@danishagro/shared/src/interfaces/price.interface";
import Skeleton from "react-loading-skeleton";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./CartTotal.module.scss";

export interface DA_CartTotalProps {
    prices?: DA_CartPriceData;
    loggedIn?: boolean;
    hideButtons?: boolean;
    description?: string;
    className?: string;
}

export const DA_CartTotal = ({
    prices,
    loggedIn,
    hideButtons,
    description,
    className,
}: DA_CartTotalProps) => {
    const { currentCulture, showPrices } = useAppData();
    const { getDictionaryString } = useTranslations();

    const formatPrice = useCallback(
        (price: number) => formatNumber(price, currentCulture, { decimals: 2 }),
        [currentCulture]
    );

    const textOptions = useRef({ uppercaseFirst: true }).current;
    const text = useMemo(
        () => ({
            subtotal: getDictionaryString("subtotal", textOptions),
            comment: getDictionaryString("order delivery comment"),
            totalExclVat: getDictionaryString("total excl vat", textOptions),
            vat: getDictionaryString("vat", textOptions),
            total: getDictionaryString("total incl vat", textOptions),
            orderAsUser: getDictionaryString("order on account"),
            orderAsGuest: getDictionaryString("continue as guest"),
        }),
        [getDictionaryString, textOptions]
    );
    const formattedPrices = useMemo(
        () => ({
            subTotalPrice: prices ? (
                formatPrice(prices.priceBeforeFees.priceWithoutVat)
            ) : (
                <Skeleton width={80} />
            ),
            totalPriceExVat: prices ? (
                formatPrice(prices?.totalPrice.priceWithoutVat)
            ) : (
                <Skeleton width={80} />
            ),
            vatPrice: prices ? (
                formatPrice(prices.totalPrice.priceWithVat - prices.totalPrice.priceWithoutVat)
            ) : (
                <Skeleton width={50} />
            ),
            totalPrice: prices ? (
                formatPrice(prices?.totalPrice.priceWithVat)
            ) : (
                <Skeleton width={80} />
            ),
        }),
        [prices, formatPrice]
    );

    return (
        <section className={className}>
            {showPrices ? (
                <>
                    <div className={S.overview}>
                        <dl>
                            <dt className={S.borderBottom}>{text.subtotal}:</dt>
                            <dd className={S.borderBottom}>{formattedPrices.subTotalPrice}</dd>

                            {prices?.charges
                                ? prices.charges.map((charge, index) => (
                                      <React.Fragment key={index}>
                                          <dt>{getDictionaryString(charge.chargeCode)}:</dt>
                                          <dd>{formatPrice(charge.totalCalculated)}</dd>
                                      </React.Fragment>
                                  ))
                                : null}

                            <dt className={S.totalExclVat}>{text.totalExclVat}:</dt>
                            <dd className={S.totalExclVat}>{formattedPrices.totalPriceExVat}</dd>

                            <dt>{text.vat} (25 %):</dt>
                            <dd>{formattedPrices.vatPrice}</dd>

                            <dt className={S.total}>{text.total}:</dt>
                            <dd className={S.total}>{formattedPrices.totalPrice}</dd>

                            <div className={S.comment}>{text.comment}</div>
                        </dl>

                        {!hideButtons && (
                            <div className={S.actions}>
                                <div className={S.user}>
                                    <DA_Button
                                        title={text.orderAsUser}
                                        href={loggedIn ? "/checkout" : getLoginLink()}
                                    />
                                </div>

                                {/* TODO: Enable this again when possible - The CSS class is also commented out */}
                                {/*!loggedIn && (
                                    <div className={S.guest}>
                                        <DA_InlineLink title={text.orderAsGuest} href="/checkout/contact" />
                                    </div>
                                )*/}
                            </div>
                        )}
                    </div>

                    {description && (
                        <div className={S.description}>
                            <DA_Text smallText noPadding html={description} />
                        </div>
                    )}
                </>
            ) : (
                !hideButtons && (
                    <div className={cn(S.user, S.noPriceButtonWrapper)}>
                        <DA_Button
                            title={text.orderAsUser}
                            href={loggedIn ? "/checkout" : getLoginLink()}
                        />
                    </div>
                )
            )}
        </section>
    );
};
