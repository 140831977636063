import React from "react";
import { Helmet } from "react-helmet";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Cart } from "@templates/Cart/Cart.component";

export const DA_CartPage = () => {
    const { globalTranslations, getDictionaryString } = useTranslations();

    return (
        <>
            <Helmet>
                <title>
                    {getDictionaryString("cart")}
                    {" | "}
                    {globalTranslations.pageTitlePostfix}
                </title>
            </Helmet>

            <DA_Cart />
        </>
    );
};
