import { useCallback, useState, useEffect, useMemo } from "react";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { useAddressesApi } from "@danishagro/shared/src/hooks/api/useAddressesApi.hook";
import { useAddressFromSessionStorage } from "@danishagro/shared/src/hooks/useAddressFromSessionStorage.hook";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";

export const useDeliveryAddress = () => {
    const { getDeliveryAddresses } = useAddressesApi();
    const { setAddressInSessionStorage, getAddressObject } = useAddressFromSessionStorage();
    const [deliveryAddresses, setDeliveryAddresses] = useState<DA_Address[]>([]);
    const [currentDeliveryAddress, setCurrentDeliveryAddress] = useState<DA_Address>(null);
    const { saveDeliveryInfo } = useCart();

    // Mappers

    const deliveryAddressOptions = useMemo(() => {
        const result = [];

        deliveryAddresses.forEach((deliveryAddress) => {
            result.push({
                value: deliveryAddress.id,
                label: `${deliveryAddress.streetName} ${deliveryAddress.streetNumber}, ${deliveryAddress.zipCode} ${deliveryAddress.city}`,
            });
        });

        return result;
    }, [deliveryAddresses]);

    const currentDeliveryAddressOption = useMemo(
        () => deliveryAddressOptions.find((x) => x.value === currentDeliveryAddress?.id),
        [deliveryAddressOptions, currentDeliveryAddress]
    );

    // Update

    const onDeliveryAddressSelectChanged = (option: { label: string; value: string }) => {
        const newDeliveryAddress = deliveryAddresses.find((x) => x.id === option.value);

        setCurrentDeliveryAddress(newDeliveryAddress);
        setAddressInSessionStorage(newDeliveryAddress.id);

        saveDeliveryInfo({
            billingAddress: {
                streetName: "",
                streetNumber: "",
                zipCode: "",
                city: "",
            },
            shippingAddress: {
                ...newDeliveryAddress,
            },
            shippingSameAsBilling: false,
        });
    };

    // Init

    const onDeliveryAddressesSuccess = useCallback(
        (response: DA_Address[]) => {
            setDeliveryAddresses(response);
            setCurrentDeliveryAddress(getAddressObject(response));
        },
        [getAddressObject]
    );

    const onDeliveryAddressesError = useCallback(() => {
        // TODO: Make a toast telling that the feature is down.
        console.log("Error");
    }, []);

    useEffect(() => {
        getDeliveryAddresses().then(onDeliveryAddressesSuccess).catch(onDeliveryAddressesError);
    }, [getDeliveryAddresses, onDeliveryAddressesSuccess, onDeliveryAddressesError]);

    return {
        currentDeliveryAddress,
        currentDeliveryAddressOption,
        deliveryAddressOptions,
        onDeliveryAddressSelectChanged,
    };
};
