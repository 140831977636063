import React, { useCallback, useEffect, useRef, useState } from "react";
import { DA_EnlargeButton } from "@danishagro/b2b/src/components/molecules/EnlargeButton/EnlargeButton.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Gallery, Item } from "react-photoswipe-gallery";
import "@danishagro/shared/src/helpers/swiperInitialization.helper";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_ArrowButton, DA_arrowDirection } from "@molecules/ArrowButton/ArrowButton.component";
import "photoswipe/dist/photoswipe.css";
import PhotoSwipe from "photoswipe";
import { Swiper as SwiperClass } from "swiper/types";
import { DA_NewProduct, Media } from "@danishagro/shared/src/interfaces/productv2.interface";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import {
    DA_Title,
    DA_TitleTag,
} from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { getNewB2bImage } from "@danishagro/shared/src/helpers/getTransformedImage.helper";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { useMarketingConsent } from "./hooks/useMarketingConsent.hook";
import S from "./ImageSlider.module.scss";

export type DA_ImageSliderProps = Pick<DA_NewProduct, "media"> & {
    startOnVideos?: boolean;
};

export const DA_ImageSlider = ({ media, startOnVideos }: DA_ImageSliderProps) => {
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>(null);
    const [mainSwiper, setMainSwiper] = useState<SwiperClass>(null);
    const [enlarged, setEnlarged] = useState(false);
    const paginationRef = useRef<HTMLSpanElement>(null);
    const [currentImage, setCurrentImage] = useState(0);
    const [photoSwipeRef, setPhotoSwipeRef] = useState<PhotoSwipe>(null);
    const [showPrevButton, setShowPrevButton] = useState(false);
    const [hideNextButton, setHideNextButton] = useState(false);
    const [mediaLength] = useState(media.length || 0);

    const { marketingConsent } = useMarketingConsent();
    const { getDictionaryString } = useTranslations();

    const handleOpen = useCallback(() => setEnlarged(true), []);
    const handleClose = useCallback(() => setEnlarged(false), []);
    const handleSlide = useCallback(
        () => mainSwiper.slideTo(photoSwipeRef.currSlide.index),
        [mainSwiper, photoSwipeRef]
    );
    const thumbsSwiperRef = useRef(null);

    const productImages = media.filter((image) => image.classification === "ProductImage");

    function extractYouTubeID(url: string): string | undefined {
        const regex =
            // eslint-disable-next-line no-useless-escape
            /(?:youtube\.com\/(?:[^\/\n\s]+\/\s*[^\/\n\s]+\/|embed\/|v\/|watch\?v=|watch\?.+&v=)|youtu\.be\/)([^\"\&\?\/\s]{11})/i;
        const match = url.match(regex);
        return match ? match[1] : undefined;
    }

    const [isSmall, setIsSmall] = useState(false);

    const useMeasure = <T extends HTMLElement = HTMLDivElement>(): [
        React.MutableRefObject<T | null>,
        { width: number; height: number }
    ] => {
        const ref = useRef<T | null>(null);
        const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

        useEffect(() => {
            if (ref.current) {
                const { width, height } = ref.current.getBoundingClientRect();
                setDimensions({ width, height });
            }
        }, []); // Empty dependency array

        return [ref, dimensions];
    };

    const [videoInnerRef, videoInnerDimensions] = useMeasure<HTMLDivElement>();
    const [consentWrapperRef] = useMeasure<HTMLDivElement>();

    useEffect(() => {
        const updateSize = () => {
            const videoInnerHeight = videoInnerDimensions.height;

            // If the height is less than 300, set isSmall to true
            if (videoInnerHeight < 290 && !isSmall) {
                setIsSmall(true);
            }
            // If the height is greater or equal to 300, set isSmall to false
            else if (videoInnerHeight >= 290 && isSmall) {
                setIsSmall(false);
            }
        };

        // Start the update
        updateSize();
    }, [videoInnerDimensions, isSmall]);

    const handleRenew = () => {
        if (window.CookieConsent && typeof window.CookieConsent.renew === "function") {
            window.CookieConsent.renew();
        }
    };

    // Placeholder for no image
    const placeholderImage: Media = {
        classification: "ProductImage",
        url: "",
        name: "Placeholder Image",
        type: "Image",
        ogWidth: 1000, // Placeholder width
        ogHeight: 1000, // Placeholder height
    };

    const imageConfig = ImageConfig.PdpThumbnail;
    const imageConfigLarge = ImageConfig.PdpMainImage;

    const mediaWithPlaceholder = media.some((item) => item.classification === "ProductImage")
        ? media
        : [placeholderImage];

    const productImagesWithPlaceholder =
        productImages.length > 0 ? productImages : [placeholderImage];

    useEffect(() => {
        if (photoSwipeRef) {
            photoSwipeRef.on("openingAnimationStart", handleOpen);
            photoSwipeRef.on("openingAnimationEnd", handleOpen);
            photoSwipeRef.on("closingAnimationEnd", handleClose);
            photoSwipeRef.on("change", handleSlide);
            return () => {
                photoSwipeRef.off("openingAnimationStart", handleOpen);
                photoSwipeRef.off("openingAnimationEnd", handleOpen);
                photoSwipeRef.off("closingAnimationEnd", handleClose);
                photoSwipeRef.off("change", handleSlide);
            };
        }
    }, [photoSwipeRef, handleOpen, handleClose, handleSlide]);

    return (
        <Gallery
            onBeforeOpen={(photoSwipe) => setPhotoSwipeRef(photoSwipe)}
            options={{
                loop: true,
                arrowNextSVG: `<svg class="iconArrow" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.2412 16.9961L10.5486 31.4397C9.88457 32.1868 9.17899 32.1868 8.43191 31.4397L7.56031 30.5681C6.81323 29.904 6.81323 29.1984 7.56031 28.4514L20.2607 16L7.56031 3.54864C6.89624 2.80156 6.89624 2.09598 7.56031 1.43191L8.43191 0.560311C9.17899 -0.18677 9.88457 -0.18677 10.5486 0.560311L25.2412 15.0039C25.9053 15.668 25.9053 16.332 25.2412 16.9961Z" fill="currentColor"/>
</svg>`,
                arrowPrevSVG: `<svg class="iconArrow iconArrowLeft" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.2412 16.9961L10.5486 31.4397C9.88457 32.1868 9.17899 32.1868 8.43191 31.4397L7.56031 30.5681C6.81323 29.904 6.81323 29.1984 7.56031 28.4514L20.2607 16L7.56031 3.54864C6.89624 2.80156 6.89624 2.09598 7.56031 1.43191L8.43191 0.560311C9.17899 -0.18677 9.88457 -0.18677 10.5486 0.560311L25.2412 15.0039C25.9053 15.668 25.9053 16.332 25.2412 16.9961Z" fill="currentColor"/>
</svg>`,
                zoomSVG: `<svg class="iconTab" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="M30.414 27.586L22.77 19.942C24.5205 17.4823 25.2829 14.4547 24.906 11.4593C24.5291 8.46391 23.0405 5.7195 20.7352 3.77011C18.43 1.82072 15.4764 0.808665 12.46 0.934587C9.44367 1.06051 6.58474 2.31521 4.44999 4.44996C2.31524 6.58471 1.06054 9.44364 0.934618 12.46C0.808695 15.4764 1.82075 18.4299 3.77014 20.7352C5.71953 23.0404 8.46394 24.529 11.4593 24.9059C14.4547 25.2828 17.4823 24.5205 19.942 22.77L27.586 30.414C27.9632 30.7783 28.4684 30.9799 28.9928 30.9753C29.5172 30.9708 30.0188 30.7605 30.3897 30.3896C30.7605 30.0188 30.9708 29.5172 30.9754 28.9928C30.9799 28.4684 30.7783 27.9632 30.414 27.586ZM3.00003 13C3.00003 11.0222 3.58652 9.08879 4.68533 7.4443C5.78415 5.7998 7.34593 4.51808 9.17319 3.7612C11.0005 3.00433 13.0111 2.80629 14.9509 3.19214C16.8907 3.578 18.6726 4.5304 20.0711 5.92893C21.4696 7.32746 22.422 9.10929 22.8079 11.0491C23.1937 12.9889 22.9957 14.9996 22.2388 16.8268C21.4819 18.6541 20.2002 20.2159 18.5557 21.3147C16.9112 22.4135 14.9778 23 13 23C10.3488 22.9971 7.80691 21.9426 5.93218 20.0679C4.05744 18.1931 3.00294 15.6513 3.00003 13Z" />
</svg>`,
                closeSVG: `<svg class="iconTab" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.3894 16L31.7508 28.3614C32.0831 28.6937 32.0831 29.0592 31.7508 29.4579L29.4579 31.7508C29.0592 32.0831 28.6937 32.0831 28.3614 31.7508L26.2679 29.5576L16 19.3894L3.63863 31.7508C3.30633 32.0831 2.94081 32.0831 2.54206 31.7508L0.249221 29.4579C-0.0830737 29.0592 -0.0830737 28.6937 0.249221 28.3614L12.6106 16L0.249221 3.63863C-0.0830737 3.30633 -0.0830737 2.94081 0.249221 2.54206L2.54206 0.249221C2.94081 -0.0830737 3.30633 -0.0830737 3.63863 0.249221L16 12.6106L28.3614 0.249221C28.6937 -0.0830737 29.0592 -0.0830737 29.4579 0.249221L31.7508 2.54206C32.0831 2.94081 32.0831 3.30633 31.7508 3.63863L29.5576 5.73209L19.3894 16Z" fill="currentColor"/>
</svg>`,
            }}
        >
            <div className={S.thumbWrapper}>
                <Swiper
                    ref={thumbsSwiperRef}
                    spaceBetween={15}
                    slidesPerView={mediaLength > 6 ? "auto" : 6}
                    slideToClickedSlide={true}
                    watchSlidesProgress
                    onSwiper={(swiper) => {
                        setThumbsSwiper(swiper);
                        thumbsSwiperRef.current = swiper;
                    }}
                    className={cn(
                        S.thumbSlider,
                        mediaLength <= 6 && S.noPadding,
                        mediaLength > 6 && S.overSix
                    )}
                    direction="vertical"
                    height={50}
                    threshold={10}
                    aria-hidden={true}
                    onSlideChange={(swiper) => {
                        swiper.activeIndex > 0 ? setShowPrevButton(true) : setShowPrevButton(false);
                        swiper.isEnd ? setHideNextButton(true) : setHideNextButton(false);
                    }}
                >
                    {mediaWithPlaceholder.map((item, index) => {
                        // Determine if the image should be loaded eagerly (first six images)
                        const isEagerLoad = index < 6;

                        if (item.classification === "ProductImage") {
                            return (
                                <SwiperSlide
                                    key={index}
                                    className={cn(currentImage === index ? S.active : S.notActive)}
                                    onClick={() => {
                                        mainSwiper.slideTo(index);
                                        let targetIndex = Math.max(index - 1, 0);
                                        if (index === mediaWithPlaceholder.length - 1) {
                                            targetIndex = index - 2;
                                        }
                                        thumbsSwiperRef.current.slideTo(targetIndex);
                                    }}
                                >
                                    <div className={S.thumbContainer}>
                                        <DA_Image
                                            className={S.thumb}
                                            src={B2bImageSrc(item.url, imageConfig)}
                                            alt={item.name}
                                            priority={isEagerLoad}
                                        />
                                    </div>
                                </SwiperSlide>
                            );
                        } else if (item.classification === "ProductVideo") {
                            // Assuming that 'id' and 'type' are properties of a video item, and 'item' itself is a DA_Video type
                            const videoId = extractYouTubeID(item.url);
                            return (
                                <SwiperSlide
                                    key={index}
                                    className={cn(
                                        currentImage === mediaWithPlaceholder.length + index
                                            ? S.active
                                            : S.notActive
                                    )}
                                    onClick={() => {
                                        mainSwiper.slideTo(mediaWithPlaceholder.length + index);
                                        let targetIndex = Math.max(
                                            mediaWithPlaceholder.length + index,
                                            0
                                        );
                                        // Adjust the target index if it's the last item
                                        if (
                                            index ===
                                            mediaWithPlaceholder.filter(
                                                (mediaItem) =>
                                                    mediaItem.classification === "ProductVideo"
                                            ).length -
                                                1
                                        ) {
                                            targetIndex = mediaWithPlaceholder.length + index - 1;
                                        }
                                        thumbsSwiperRef.current.slideTo(targetIndex);
                                    }}
                                >
                                    <div className={S.thumbContainer}>
                                        <div className={S.playIconContainer}>
                                            <DA_Icon
                                                name={DA_IconNames.Play}
                                                className={S.iconSize}
                                            />
                                        </div>
                                        <DA_Image
                                            className={cn(S.thumb, S.video)}
                                            src={`https://img.youtube.com/vi/${videoId}/default.jpg`}
                                            alt=""
                                        />
                                    </div>
                                </SwiperSlide>
                            );
                        }
                    })}

                    <div className={S.navigationWrapper}>
                        <DA_ArrowButton
                            onCLick={() => thumbsSwiper.slidePrev()}
                            className={cn(S.buttonPrev, showPrevButton && S.showButton)}
                            iconDirection={DA_arrowDirection.Top}
                            small
                        />
                        {mediaLength > 6 && (
                            <DA_ArrowButton
                                onCLick={() => thumbsSwiper.slideNext()}
                                className={cn(S.buttonNext, hideNextButton && S.hideButton)}
                                iconDirection={DA_arrowDirection.Bottom}
                                small
                            />
                        )}
                    </div>
                </Swiper>
            </div>

            <Swiper
                onSwiper={(swiper) => setMainSwiper(swiper)}
                onSlideChange={(swiper) => setCurrentImage(swiper.realIndex)}
                className={cn(S.mainSlider, enlarged && S.enlarged)}
                slideToClickedSlide={true}
                pagination={{
                    clickable: true,
                    el: paginationRef.current,
                }}
                threshold={10}
                spaceBetween={0}
                slidesPerView={1}
                initialSlide={startOnVideos ? media.length : 0}
                thumbs={{
                    swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                }}
            >
                {productImagesWithPlaceholder.map((image, index) => {
                    const large = B2bImageSrc(image.url, imageConfigLarge);

                    const largeZoom = getNewB2bImage(image, 2500);
                    const smallZoom = getNewB2bImage(image, 1000);

                    // Set the priority flag for the first image (index 0)
                    const isFirstImage = index === 0;

                    return (
                        <SwiperSlide key={index} className={S.slide}>
                            <Item
                                original={largeZoom.url}
                                width={largeZoom.ogWidth}
                                height={largeZoom.ogHeight}
                                thumbnail={smallZoom.url}
                            >
                                {({ ref, open }) => (
                                    <>
                                        <DA_Image
                                            ref={ref as React.MutableRefObject<HTMLImageElement>}
                                            src={large}
                                            alt={image.name}
                                            onClick={open}
                                            priority={isFirstImage}
                                            style={{
                                                objectFit: "contain",
                                            }}
                                        />
                                        <div className={S.enlargeButtonPlacementContainer}>
                                            <DA_EnlargeButton onClick={open} />
                                        </div>
                                    </>
                                )}
                            </Item>
                        </SwiperSlide>
                    );
                })}

                {media
                    .filter((item) => item.classification === "ProductVideo")
                    .map((item, index) => (
                        <SwiperSlide key={index} className={S.slide}>
                            <Item
                                content={
                                    <div className={S.videoModalContainer}>
                                        {location.hostname !== "localhost" &&
                                        window.CookieConsent &&
                                        !marketingConsent ? (
                                            <div
                                                ref={videoInnerRef}
                                                className={cn(
                                                    S.consentPlaceholderWrapper,
                                                    S.consentPlaceholderWrapperInner
                                                )}
                                                data-category="cookie_cat_marketing"
                                            >
                                                <div
                                                    ref={consentWrapperRef}
                                                    className={cn(
                                                        S.consentPlaceholderBox,
                                                        isSmall && S.smallContent
                                                    )}
                                                >
                                                    <DA_Icon
                                                        name={DA_IconNames.Cookie}
                                                        className={S.iconSize}
                                                    />
                                                    <DA_Title h2 tag={DA_TitleTag.H2}>
                                                        {getDictionaryString(
                                                            "ConsentVideoPlaceholder.Headline"
                                                        )}
                                                    </DA_Title>
                                                    <div className={S.consentPlaceholderText}>
                                                        <DA_Text
                                                            html={getDictionaryString(
                                                                "ConsentVideoPlaceholder.Text"
                                                            )}
                                                        />
                                                    </div>

                                                    <DA_Button
                                                        title={getDictionaryString(
                                                            "ConsentVideoPlaceholder.Button"
                                                        )}
                                                        onClick={handleRenew}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <iframe
                                                className={S.videoModal}
                                                src={`https://www.youtube.com/embed/${extractYouTubeID(
                                                    item.url
                                                )}?rel=0`}
                                                title="YouTube video player"
                                                allow="accelerometer; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        )}
                                    </div>
                                }
                            >
                                {({ ref, open }) => (
                                    <>
                                        {/* No cookie consent video placeholder */}
                                        {location.hostname !== "localhost" &&
                                            window.CookieConsent &&
                                            !marketingConsent && (
                                                <div
                                                    ref={videoInnerRef}
                                                    className={S.consentPlaceholderWrapper}
                                                    data-category="cookie_cat_marketing"
                                                >
                                                    <div
                                                        ref={consentWrapperRef}
                                                        className={cn(
                                                            S.consentPlaceholderBox,
                                                            isSmall && S.smallContent
                                                        )}
                                                    >
                                                        <DA_Icon
                                                            name={DA_IconNames.Cookie}
                                                            className={S.iconSize}
                                                        />
                                                        <DA_Title h2 tag={DA_TitleTag.H2}>
                                                            {getDictionaryString(
                                                                "ConsentVideoPlaceholder.Headline"
                                                            )}
                                                        </DA_Title>
                                                        <div className={S.consentPlaceholderText}>
                                                            <DA_Text
                                                                html={getDictionaryString(
                                                                    "ConsentVideoPlaceholder.Text"
                                                                )}
                                                            />
                                                        </div>

                                                        <DA_Button
                                                            title={getDictionaryString(
                                                                "ConsentVideoPlaceholder.Button"
                                                            )}
                                                            onClick={handleRenew}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        <div
                                            className={S.videoContainer}
                                            onClick={open}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") open;
                                            }}
                                            role="button"
                                            tabIndex={0}
                                        >
                                            <iframe
                                                tabIndex={-1}
                                                ref={
                                                    ref as React.MutableRefObject<HTMLIFrameElement>
                                                }
                                                className={S.slideMedia}
                                                src={`https://www.youtube.com/embed/${extractYouTubeID(
                                                    item.url
                                                )}?rel=0`}
                                                title="YouTube video player"
                                                allow="accelerometer; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                            <div className={S.enlargeButtonPlacementContainer}>
                                                <DA_EnlargeButton onClick={open} />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Item>
                        </SwiperSlide>
                    ))}

                {mediaLength > 1 && (
                    <span
                        ref={paginationRef}
                        className={cn(S.swiperPagination, "swiperPagination")}
                    />
                )}
            </Swiper>
        </Gallery>
    );
};
