import { useState } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { isPhoneNumberValid } from "@danishagro/shared/src/helpers/validatePhoneNumber.helper";
import { isEmailValid } from "@danishagro/shared/src/helpers/validateEmail.helper";
import { ContactInformationFormData } from "../interfaces/contactInformationFormData.interface";

export const useValidation = () => {
    const { getDictionaryString } = useTranslations();

    const [validationErrorMessages, setValidationErrorMessages] = useState<
        { key: string; value: string }[]
    >([]);

    const onInputChange = (key: string) => {
        let result = [...validationErrorMessages];

        result = result.filter((x) => x.key !== key);

        setValidationErrorMessages(result);
    };

    const validate = (body: ContactInformationFormData) => {
        const tmpValidationErrorMessages = [];

        // Company Name: Mandatory
        if (!body.companyName || body.companyName?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "companyName",
                value: getDictionaryString("CheckoutValidationCompanyNameRequired"),
            });
        }

        // Email: Mandatory
        if (!body.email || body.email?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "email",
                value: getDictionaryString("CheckoutValidationEmailRequired"),
            });
        }

        // Email: Validation
        if (!isEmailValid(body.email)) {
            tmpValidationErrorMessages.push({
                key: "email",
                value: getDictionaryString("CheckoutValidationEmailFormat"),
            });
        }

        // Repeat-Email: Mandatory
        if (!body.repeatEmail || body.repeatEmail?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "repeatEmail",
                value: getDictionaryString("CheckoutValidationEmailRequired"),
            });
        }

        // Repeat-Email: Validation
        if (!isEmailValid(body.repeatEmail)) {
            tmpValidationErrorMessages.push({
                key: "repeatEmail",
                value: getDictionaryString("CheckoutValidationEmailFormat"),
            });
        }

        // Email: Equality
        if (body.repeatEmail !== body.email) {
            tmpValidationErrorMessages.push({
                key: "repeatEmail",
                value: getDictionaryString("CheckoutValidationEmailEquality"),
            });
        }

        // Phone: Mandatory
        if (!body.phone || body.phone?.length === 0) {
            tmpValidationErrorMessages.push({
                key: "phone",
                value: getDictionaryString("CheckoutValidationPhoneRequired"),
            });
        }

        // Phone: Validation
        if (!isPhoneNumberValid(body.phone)) {
            tmpValidationErrorMessages.push({
                key: "phone",
                value: getDictionaryString("CheckoutValidationPhoneFormat"),
            });
        }

        setValidationErrorMessages(tmpValidationErrorMessages);

        return tmpValidationErrorMessages.length === 0;
    };

    return {
        validationErrorMessages,
        validate,
        onInputChange,
    };
};
