import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Tooltip, DA_TooltipProps } from "../Tooltip/Tooltip.component";
import S from "./RadioButton.module.scss";

export interface DA_RadioButtonProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type" | "onChange" | "className"> {
    name?: string;
    label: string;
    className?: string;
    labelElementClassName?: string;
    vertical?: boolean;
    onChange?: (state, props) => void;
    tooltip?: string | DA_TooltipProps;
}

export const DA_RadioButton = (props: DA_RadioButtonProps) => {
    const {
        name,
        label,
        className,
        labelElementClassName,
        checked,
        disabled,
        vertical,
        onChange,
        tooltip,
        ...rest
    } = props;

    return (
        <div
            className={cn(
                S.wrapper,
                disabled && S.disabled,
                vertical ? S.vertical : S.horizontal,
                className
            )}
        >
            <label className={cn(S.labelElement, labelElementClassName)}>
                <div className={S.indicatorWrapper}>
                    <input
                        name={name}
                        className={S.input}
                        type="radio"
                        onChange={({ currentTarget }) => onChange(currentTarget.checked, props)}
                        disabled={disabled}
                        checked={typeof checked === "boolean" ? !!checked : undefined}
                        {...rest}
                    />
                    <div className={S.indicator} />
                </div>

                <div className={S.label}>{label}</div>

                {tooltip ? (
                    <div className={S.tooltip}>
                        {typeof tooltip === "string" ? (
                            <DA_Tooltip text={tooltip} />
                        ) : (
                            <DA_Tooltip {...tooltip} />
                        )}
                    </div>
                ) : null}
            </label>
        </div>
    );
};
