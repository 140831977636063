import React from "react";
import { DA_FarmInTime } from "@templates/FarmInTime/FarmInTime.component";
import { Helmet } from "react-helmet";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";

export const DA_FarmInTimePage = () => {
    const { globalTranslations, getDictionaryString } = useTranslations();
    const { currentPathDetails } = usePage();

    return (
        <>
            <Helmet>
                <title>
                    {getDictionaryString("farmintime")}
                    {" | "}
                    {globalTranslations.pageTitlePostfix}
                </title>
            </Helmet>

            <DA_Container>
                <DA_Breadcrumb
                    breadcrumbItems={[
                        {
                            title: getDictionaryString("webshop"),
                            href: "/",
                            id: globalThis.frontPageId,
                        },
                        ...currentPathDetails.map(({ name, url, path, categoryId }) => ({
                            title: name,
                            href: url || path,
                            id: categoryId.toString(),
                        })),
                    ]}
                />

                <DA_FarmInTime />
            </DA_Container>
        </>
    );
};
