import React, { useCallback, useState, MouseEvent, useEffect } from "react";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_LinkMenu } from "@danishagro/shared/src/components/molecules/LinkMenu/LinkMenu.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { _OrderListModal } from "@molecules/WishListButton/modals/OrderListModal/OrderListModal.component";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useAddressesApi } from "@danishagro/shared/src/hooks/api/useAddressesApi.hook";
import toast from "react-hot-toast";
import { DA_WishListToast } from "@molecules/WishListButton/WishListToast/WishListToast.component";
import { DA_AddToCartModal } from "@organisms/AddToCartModal/AddToCartModal.component";
import { _FarmInTimeModal } from "./modals/FarmInTimeModal/FarmInTimeModal.component";
import S from "./WishListButton.module.scss";

export interface DA_WishListButtonProps {
    productId: string;
    productName: string;
    productImage: string;
    variantId?: string;
    productPageUrl?: string;
    isNotModal?: boolean;
    isVariantSelected?: () => void;
    hasVariants?: boolean;
    variantSelected?: number;
    variantGroups?: number;
    quantityOnPallet?: string;
}

export const DA_WishListButton = (props: DA_WishListButtonProps) => {
    const [showSpinner, setShowSpinner] = useState(false);
    const { getDictionaryString } = useTranslations();
    const { setOrderlistProductsV2 } = useFarmInTimeApi();
    const { getDeliveryAddresses } = useAddressesApi();
    const { customerNumber, cvrAndCustomerNumbers } = useAppData();
    const { showModal, isModalShowing } = useModal();
    const [isAllVariantsSelectedLocal, setIsAllVariantsSelectedLocal] = useState(false);

    useEffect(() => {
        if (props.variantSelected === props.variantGroups) {
            setIsAllVariantsSelectedLocal(true);
        } else {
            setIsAllVariantsSelectedLocal(false);
        }
    }, [props.variantSelected, props.variantGroups]);

    const showAddToFitErrorModal = useCallback(
        (type: "NO_ACCOUNT_ATTACHED") => {
            showModal(<DA_AddToCartModal type={type} />, { size: ModalSize.MD });
        },
        [showModal]
    );

    // Tooltip text for hover action
    const hoverTooltipContent = getDictionaryString("AddToFarmInTimeOrOrderList");

    const shouldTryAddToFit = (
        modalType: "FIT" | "OrderList",
        event?: MouseEvent<HTMLButtonElement>
    ) => {
        event?.preventDefault();

        // Logged in and has account attached
        if (globalThis.isAuthenticated && cvrAndCustomerNumbers && customerNumber) {
            if (modalType === "FIT") {
                openFarmInTimeModal();
            } else if (modalType === "OrderList") {
                openOrderListModal();
            }
        }

        // Logged in but have no account attached
        else if (globalThis.isAuthenticated && (!cvrAndCustomerNumbers || !customerNumber)) {
            showAddToFitErrorModal("NO_ACCOUNT_ATTACHED");
        }
    };

    // Open Order List Modal
    const openOrderListModal = useCallback(() => {
        setShowSpinner(true);
        getDeliveryAddresses()
            .then((addresses) => {
                if (addresses.length === 1) {
                    setOrderlistProductsV2(customerNumber, addresses[0].id, {
                        productId: props.productId,
                        quantity: 1,
                        variantId: props.variantId,
                    }).then(() => {
                        toast.custom(() => (
                            <DA_WishListToast
                                heading={getDictionaryString("WishListToastOrderListHeading")}
                                href="/bestillingsliste"
                            />
                        ));
                    });
                } else {
                    showModal(
                        <_OrderListModal
                            productId={props.productId}
                            productImage={props.productImage}
                            productName={props.productName}
                            quantityOnPallet={props.quantityOnPallet}
                            variantId={props.variantId ? props.variantId : undefined}
                        />,
                        { size: ModalSize.LG }
                    );
                }
            })
            .finally(() => setShowSpinner(false));
    }, [
        getDeliveryAddresses,
        setOrderlistProductsV2,
        customerNumber,
        props.productId,
        props.variantId,
        props.productImage,
        props.productName,
        props.quantityOnPallet,
        getDictionaryString,
        showModal,
    ]);

    // Open Farm In Time Modal
    const openFarmInTimeModal = useCallback(() => {
        showModal(
            <_FarmInTimeModal
                productId={props.productId}
                productImage={props.productImage}
                productName={props.productName}
                quantityOnPallet={props.quantityOnPallet}
                variantId={props.variantId ? props.variantId : undefined}
            />,
            { size: ModalSize.LG }
        );
    }, [
        showModal,
        props.productId,
        props.productImage,
        props.productName,
        props.quantityOnPallet,
        props.variantId,
    ]);

    return (
        <>
            {props.isNotModal ? (
                <div className={S.wishListBox}>
                    <div className={S.wishListBoxTitle}>
                        <DA_Icon name={DA_IconNames.FormCheck} useSvgDimensions />
                        {getDictionaryString("AddProductToList", {
                            uppercaseFirst: true,
                        })}
                    </div>
                    <div className={S.wishListBoxBtns}>
                        <button
                            className={S.wishlistBtn}
                            onClick={() => {
                                if (props.hasVariants || !isAllVariantsSelectedLocal) {
                                    props.isVariantSelected();
                                } else {
                                    shouldTryAddToFit("OrderList");
                                }
                            }}
                        >
                            <DA_Icon name={DA_IconNames.Add} useSvgDimensions />
                            {getDictionaryString("AddToOrderList", {
                                uppercaseFirst: true,
                            })}
                        </button>
                        <button
                            className={S.wishlistBtn}
                            onClick={() => {
                                if (props.hasVariants || !isAllVariantsSelectedLocal) {
                                    props.isVariantSelected();
                                } else {
                                    shouldTryAddToFit("FIT");
                                }
                            }}
                        >
                            <DA_Icon name={DA_IconNames.Add} useSvgDimensions />
                            {getDictionaryString("AddToMyFarmList", {
                                uppercaseFirst: true,
                            })}
                        </button>
                    </div>
                </div>
            ) : (
                <DA_LinkMenu
                    className={S.wishListButton}
                    showSpinner={showSpinner}
                    icon={DA_IconNames.AddChecklist}
                    showToolTip
                    toolTipContent={hoverTooltipContent}
                    isModalShowing={isModalShowing}
                    links={[
                        {
                            title: getDictionaryString("AddProductToOrderList", {
                                uppercaseFirst: true,
                            }),
                            onClick: () => {
                                if (props.hasVariants || !isAllVariantsSelectedLocal) {
                                    props.isVariantSelected();
                                } else {
                                    shouldTryAddToFit("OrderList");
                                }
                            },
                        },
                        {
                            title: getDictionaryString("AddProductToMyFarmList", {
                                uppercaseFirst: true,
                            }),
                            onClick: () => {
                                if (props.hasVariants || !isAllVariantsSelectedLocal) {
                                    props.isVariantSelected();
                                } else {
                                    shouldTryAddToFit("FIT");
                                }
                            },
                        },
                    ]}
                />
            )}
        </>
    );
};
