import React, { MouseEvent, useCallback } from "react";
import { DA_AddToCartModal } from "@organisms/AddToCartModal/AddToCartModal.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_CartButtonProps } from "./CartButton.props";

export const DA_CartButton = (props: DA_CartButtonProps) => {
    const {
        title,
        addToCart,
        hasVariants,
        hasHazardStatements,
        isAvailableToBuy,
        productPage,
        hideText,
        showSpinner,
        fullWidth,
        disabled,
        className,
    } = props;

    const href = !hasVariants && !hasHazardStatements ? undefined : productPage;
    const { showModal } = useModal();
    const { getDictionaryString } = useTranslations();
    const { cvrAndCustomerNumbers, customerNumber } = useAppData();

    const showAddToCartErrorModal = useCallback(
        (type: "NO_ACCOUNT_ATTACHED" | "NOT_LOGGED_IN") => {
            showModal(<DA_AddToCartModal type={type} />, { size: ModalSize.SM });
        },
        [showModal]
    );

    const shouldTryAddToCart = (event: MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();

        // Logged in and has account attached
        if (globalThis.isAuthenticated && cvrAndCustomerNumbers && customerNumber) {
            addToCart();
        }

        // Logged in but have no account attached
        else if (globalThis.isAuthenticated && (!cvrAndCustomerNumbers || !customerNumber)) {
            showAddToCartErrorModal("NO_ACCOUNT_ATTACHED");
        }

        // Logged out
        else {
            showAddToCartErrorModal("NOT_LOGGED_IN");
        }
    };

    const onClick = !hasVariants && !hasHazardStatements ? shouldTryAddToCart : undefined;

    return (
        <>
            {/** Add to cart button */}
            {isAvailableToBuy ? (
                <DA_Button
                    href={href}
                    onClick={onClick}
                    hideText={hideText}
                    icon={DA_IconNames.Cart}
                    title={title}
                    showSpinner={showSpinner}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    className={className}
                />
            ) : null}

            {/** Read more button */}
            {!isAvailableToBuy ? (
                <DA_Button
                    href={productPage}
                    title={getDictionaryString("read more")}
                    fullWidth={fullWidth}
                />
            ) : null}
        </>
    );
};
