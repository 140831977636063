import React, { useState, useEffect, useRef } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_SortingSelect } from "@molecules/SortingSelect/SortingSelect.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import {
    DA_DynamicOverlayWrapper,
    useDynamicOverlay,
} from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import { ChecklistFacet } from "@templates/Relewise/components/ChecklistFacet.component";
import { ProductFacetResult, StringProductDataValueFacetResult } from "@relewise/client";
import S from "./FilteringMobileDrawer.module.scss";

export interface DA_FilteringMobileDrawerProps {
    facets?: ProductFacetResult;
    update?: (items: unknown) => void;
}

export const DA_FilteringMobileDrawer = (props: DA_FilteringMobileDrawerProps) => {
    const { getDictionaryItem, getDictionaryString } = useTranslations();
    const { showOverlay, closeOverlay } = useDynamicOverlay();
    const { selectedFacets, setSelectedFacets, totalProductCount, resetFilters } = useRelewise();
    const [overlayIsOpen, setOverlayIsOpen] = useState(false);
    const [closeDrawer, setCloseDrawer] = useState(false);
    const [sortingTabIndex] = useState<number>();
    const [activeFilterCount, setActiveFilterCount] = useState(0);
    const wasResetInitiated = useRef(false);

    // Reset
    const resetFilter = () => {
        closeOverlay();
        resetFilters();
        setCloseDrawer(true);
        wasResetInitiated.current = true;
        setActiveFilterCount(0);
        // Other reset actions can go here...
    };

    // Save
    const saveAndClose = () => {
        setCloseDrawer(true);
        wasResetInitiated.current = true;
        closeOverlay(); // for instance
    };

    const openDynamicOverlay = (selectedFacets: Record<string, string[]>) => {
        // Close existing overlay first
        closeOverlay();
        setCloseDrawer(false);

        const getFacetsByType = (selectedFacets: Record<string, string[]>, type: string) => {
            if ((selectedFacets && !selectedFacets[type]) || selectedFacets[type].length < 1) {
                return null;
            }
            return selectedFacets[type];
        };

        const setFacet = (type: string, value: string) => {
            const currentSelectFacetValues = getFacetsByType(selectedFacets, type);
            const valueAlreadySelected =
                (currentSelectFacetValues?.find((v) => v === value)?.length ?? 0) > 0;

            if (valueAlreadySelected) {
                const newSelectFacets = { ...selectedFacets };
                const indexToRemove = newSelectFacets[type].indexOf(value);
                newSelectFacets[type].splice(indexToRemove, 1);
                setSelectedFacets(newSelectFacets);
                return;
            }

            const newSelectFacets = { ...selectedFacets };
            newSelectFacets[type].push(value);
            setSelectedFacets(newSelectFacets);
        };

        // Open new overlay
        showOverlay({
            id: "filter_open",
            title: getDictionaryItem("Filtre"),
            content: (
                <DA_DynamicOverlayWrapper
                    content={
                        <div className={S.drawerContentContainer}>
                            <div>
                                <div className={S.facets}>
                                    {props.facets?.items?.map((facet, index) =>
                                        (facet as StringProductDataValueFacetResult).available
                                            .length ? (
                                            <div key={index}>
                                                {facet.field === "Data" ? (
                                                    <ChecklistFacet
                                                        expanded={index < 3}
                                                        facet={
                                                            facet as StringProductDataValueFacetResult
                                                        }
                                                        selected={
                                                            selectedFacets[
                                                                (
                                                                    facet as StringProductDataValueFacetResult
                                                                ).key
                                                            ]
                                                        }
                                                        setFacet={setFacet}
                                                    />
                                                ) : null}
                                            </div>
                                        ) : null
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                    footer={
                        <div className={S.drawerButtonsWrapper}>
                            <DA_Button
                                onClick={resetFilter}
                                isGhost={true}
                                title={getDictionaryString("FilterCancelButton")}
                            />
                            <DA_Button
                                onClick={saveAndClose}
                                title={`${getDictionaryString("show", {
                                    uppercaseFirst: true,
                                })} ${totalProductCount} ${getDictionaryString(
                                    totalProductCount === 1 ? "product" : "products"
                                )}`}
                            />
                        </div>
                    }
                />
            ),
        });
        setOverlayIsOpen(true);
    };

    useEffect(() => {
        if (overlayIsOpen && !wasResetInitiated.current) {
            openDynamicOverlay(selectedFacets);
        }
        if (closeDrawer) setOverlayIsOpen(false);
        // After useEffect logic, reset the ref value to false for subsequent calls
        wasResetInitiated.current = false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFacets, totalProductCount]);

    useEffect(() => {
        const selectedCount = Object.keys(selectedFacets).reduce(
            (total, facet) => total + selectedFacets[facet].length,
            0
        );
        setActiveFilterCount(selectedCount);
    }, [selectedFacets]);

    return (
        <div className={S.filterDrawerContainer}>
            <button
                className={S.orderLinesItemBtn}
                onClick={() => openDynamicOverlay(selectedFacets)}
                disabled={Object.keys(selectedFacets).length === 0 && true}
            >
                {getDictionaryString("FilterShowing")}{" "}
                {activeFilterCount > 0 && (
                    <span className={S.badge}>
                        <span className={S.badgeNumber}>{activeFilterCount}</span>
                    </span>
                )}
            </button>
            <div className={S.sorting}>
                <DA_SortingSelect tabIndex={sortingTabIndex} fullWidth />
            </div>
        </div>
    );
};
