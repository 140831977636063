import { FormEvent, useCallback, useState } from "react";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import {
    NotificationContact,
    NotificationContactV2,
} from "../../../interfaces/notificationContact.interface";
import { mapFormDataToArray } from "../components/NotificationServiceModal/helpers/mapFormDataToArray";
import { DA_NotificationServiceViewState } from "../components/enums/viewStates";

export const useNotificationServiceModal = (
    validate: (body: NotificationContact) => boolean,
    currentAddress: DA_Address,
    onSubmit: (contact: NotificationContactV2) => void,
    onCancel: () => void
) => {
    const [viewState, setViewState] = useState(DA_NotificationServiceViewState.INIT);

    const { customerNumber } = useAppData();
    const { putContactsByAddressV2 } = useFarmInTimeApi();

    const onFormSubmitted = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            const form = new FormData(event.target as HTMLFormElement);
            const body = {} as NotificationContact;

            form.forEach((value, key) => (body[key] = value));

            const isValid = validate(body);

            if (isValid) {
                setViewState(DA_NotificationServiceViewState.SAVING);

                const formattedBody = mapFormDataToArray(body);

                try {
                    await putContactsByAddressV2(customerNumber, currentAddress?.id, formattedBody);
                    onSubmit(body);
                } catch {
                    setViewState(DA_NotificationServiceViewState.ERROR);
                }
            }
        },
        [currentAddress, customerNumber, onSubmit, putContactsByAddressV2, validate]
    );

    const onCancelButtonClicked = useCallback(() => {
        onCancel();
    }, [onCancel]);

    return {
        viewState,
        onCancelButtonClicked,
        onFormSubmitted,
    };
};
