import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Tippy from "@tippyjs/react/headless";
import { useSpring, animated } from "react-spring";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "../../atoms/Icon/Icon.component";
import { DA_LinkList } from "../LinkList/LinkList.component";
import { DA_ButtonLinkProps } from "../../atoms/ButtonLink/ButtonLink.component";
import { DA_Spinner } from "../../atoms/Spinner/Spinner.component";
import { useTranslations } from "../../../contexts/translations/translations.context";
import S from "./LinkMenu.module.scss";

export interface DA_LinkMenuProps {
    icon?: DA_IconNames;
    alignRight?: boolean;
    className?: string;
    links: DA_ButtonLinkProps[];
    showSpinner?: boolean;
    showToolTip?: boolean;
    toolTipContent?: string;
    isModalShowing?: boolean;
}

export const DA_LinkMenu = ({
    links,
    alignRight,
    className,
    icon = DA_IconNames.MenuDots,
    showSpinner,
    showToolTip,
    toolTipContent,
    isModalShowing,
}: DA_LinkMenuProps) => {
    const [visible, setVisible] = useState(false);
    const showDelay = useRef<NodeJS.Timeout | null>(null);
    const { getDictionaryString } = useTranslations();

    const initialAnimationSettings = useMemo(
        () => ({
            opacity: 0,
            config: { tension: 200, friction: 15 },
        }),
        []
    );
    const [popUpStyle, setSpring] = useSpring(() => initialAnimationSettings);

    const show = useCallback((event) => {
        showDelay.current = setTimeout(
            () => {
                setVisible(true);
                setHoverTooltipVisible(false);
            },
            event.type === "mouseenter" ? 300 : 0
        );
    }, []);

    const hide = useCallback(() => {
        clearTimeout(showDelay.current);
        setVisible(false);
    }, []);

    const onMount = useCallback(() => {
        setSpring.start({
            ...initialAnimationSettings,
            opacity: 1,
        });
    }, [setSpring, initialAnimationSettings]);

    const onHide = useCallback(
        ({ unmount }) => {
            setSpring.start({ ...initialAnimationSettings, onRest: unmount });
        },
        [setSpring, initialAnimationSettings]
    );

    // State to control the visibility of the hover tooltip
    const [hoverTooltipVisible, setHoverTooltipVisible] = useState(false);

    // Watch for changes in isModalShowing prop and set hoverTooltipVisible accordingly
    useEffect(() => {
        if (isModalShowing) {
            setHoverTooltipVisible(false);
        }
    }, [isModalShowing]);

    const ButtonContent = (
        <button
            type="button"
            onClick={visible ? hide : show}
            className={cn(S.button, alignRight && S.alignRight, className)}
            disabled={showSpinner}
            aria-label={getDictionaryString("accessibilityButtonWishList")}
        >
            {showSpinner ? (
                <div className={S.spinner}>
                    <DA_Spinner />
                </div>
            ) : (
                <DA_Icon name={icon} className={cn(S.icon, className)} />
            )}
        </button>
    );

    const TippyContent = (
        <Tippy
            appendTo={document.body}
            arrow={false}
            visible={visible}
            animation
            interactive
            onMount={onMount}
            onHide={onHide}
            placement="bottom-end"
            onClickOutside={hide}
            render={(attr) => (
                <animated.div className={S.popUp} style={popUpStyle} {...attr} onClick={hide}>
                    <DA_LinkList links={links} />
                </animated.div>
            )}
        >
            {ButtonContent}
        </Tippy>
    );

    return (
        <Tippy
            visible={showToolTip ? hoverTooltipVisible : undefined}
            placement={showToolTip ? "left" : undefined}
            popperOptions={showToolTip ? { modifiers: [{ name: "arrow" }] } : undefined}
            render={(attrs) =>
                showToolTip && !visible ? (
                    <animated.div className={S.popUpToolTip} {...attrs}>
                        <div className={S.toolTipText}>{toolTipContent}</div>
                        <div className={S.arrow} data-popper-arrow="" />
                    </animated.div>
                ) : null
            }
        >
            <span
                onMouseEnter={() => !isModalShowing && setHoverTooltipVisible(true)}
                onMouseLeave={() => setHoverTooltipVisible(false)}
            >
                {TippyContent}
            </span>
        </Tippy>
    );
};
