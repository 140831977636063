import React from "react";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./VetMedication.module.scss";

export interface DA_VetMedicationImageLinkProps {
    logo: string;
    link: string;
    pathname?: string;
}

export const DA_VetMedicationImageLink = (props: DA_VetMedicationImageLinkProps) => {
    const { getDictionaryString } = useTranslations();
    const isFrontPage = props.pathname === undefined || props.pathname === "/";

    return (
        <>
            {props.link && props.logo && (
                <DA_BasicLink
                    className={cn(S.vetMedicationLink, isFrontPage && S.vetMedicationLinkFrontPage)}
                    href={props.link}
                >
                    <DA_Image
                        src={props.logo}
                        width={120}
                        alt={getDictionaryString("VetMedicationImageAltText")}
                        className={S.vetMedicationImage}
                    />
                </DA_BasicLink>
            )}
        </>
    );
};
