import React, { useState } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useDynamicOverlay } from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import S from "./Overlay.module.scss";

interface DA_QuantityOnPalletOverlayProps {
    quantity: number;
    quantityOnPallet: number;
    defaultQuantityOnPallet?: number;
    numberOfPallets?: number;
    onSelection: (selectedValue: number) => void;
}

enum ButtonType {
    Keep = "keep",
    Change = "change",
}

export const DA_QuantityOnPalletOverlay = ({
    quantity,
    quantityOnPallet,
    defaultQuantityOnPallet,
    numberOfPallets,
    onSelection,
}: DA_QuantityOnPalletOverlayProps) => {
    const { closeOverlay } = useDynamicOverlay();
    const { getDictionaryString } = useTranslations();
    const [isLoadingKeep, setLoadingKeep] = useState(false);
    const [isLoadingChange, setLoadingChange] = useState(false);

    const handleSelect = (value: number, button: string) => {
        if (button === ButtonType.Keep) setLoadingKeep(true);
        if (button === ButtonType.Change) setLoadingChange(true);
        setTimeout(() => {
            onSelection(value);
            closeOverlay();
        }, 1000); // Add delay of 1 second before closing the modal
    };

    return (
        <div className={S.modalContent}>
            <DA_Text
                noPadding
                html={getDictionaryString("shopquantityonpallettext", {
                    quantity: quantity,
                    quantityOnPallet: quantityOnPallet,
                    defaultQuantityOnPallet: defaultQuantityOnPallet,
                    numberOfPallets: numberOfPallets,
                })}
            />

            <div className={S.buttonGroup}>
                <DA_Button
                    className={S.modalButton}
                    title={getDictionaryString("shopquantityonpalletbuttonkeeptext", {
                        quantity: quantity,
                    })}
                    showSpinner={isLoadingKeep}
                    onClick={() => handleSelect(quantity, ButtonType.Keep)}
                />
                <DA_Button
                    className={S.modalButton}
                    title={getDictionaryString("shopquantityonpalletbuttonchangetext", {
                        quantity: quantityOnPallet,
                    })}
                    showSpinner={isLoadingChange}
                    onClick={() => handleSelect(quantityOnPallet, ButtonType.Change)}
                    isGhost
                />
            </div>
        </div>
    );
};
