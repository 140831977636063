import { useCallback } from "react";
import { OrderListResponse } from "@templates/FarmInTime/components/Settings/interfaces/orderListResponse.interface";
import { FarmInTimeResponse } from "@templates/FarmInTime/components/Settings/interfaces/farmInTimeResponse.interface";
import { NotificationContact } from "@templates/FarmInTime/components/Settings/interfaces/notificationContact.interface";
import { DA_FarmInTimePrice } from "@danishagro/shared/src/interfaces/farmInTimePrice.interface";
import { DA_CartData } from "@danishagro/shared/src/interfaces/cartData.interface";
import { FetchOptions } from "@danishagro/shared/src/interfaces/fetchOptions.interface";
import { useRequest } from "@danishagro/shared/src/hooks/useRequest.hook";

type GetOrderListProducts = (
    customerNumber: string,
    addressId: string,
    options?: FetchOptions
) => Promise<OrderListResponse>;

type SetOrderlistProducts = (
    customerNumber: string,
    addressId: string,
    parameters: {
        productId: string;
        quantity: number;
        variantId?: string;
        languageId?: string;
    },
    options?: FetchOptions
) => Promise<Response>;

type SetFarmInTimeProducts = (
    customerNumber: string,
    addressId: string,
    parameters: {
        productId: string;
        variantId: string;
        frequency: string;
        month: string;
        quantity: number;
        languageId?: string;
    },
    options?: FetchOptions
) => Promise<Response>;

type UpdateFarmInTimeProduct = (
    customerNumber: string,
    addressId: string,
    farmInTimeId: number,
    parameters: {
        productId: string;
        variantId: string;
        frequency: string;
        month: string;
        quantity: number;
        languageId?: string;
    },
    options?: FetchOptions
) => Promise<Response>;

type UpdateOrderListProduct = (
    customerNumber: string,
    addressId: string,
    farmInTimeOrderListProductId: number,
    parameters: {
        productId: string;
        variantId: string;
        quantity: number;
    },
    options?: FetchOptions
) => Promise<Response>;

type DeleteFarmInTimeProduct = (
    customerNumber: string,
    addressId: string,
    farmInTimeId: number,
    options?: FetchOptions
) => Promise<Response>;

type DeleteProductFromOrderList = (
    customerNumber: string,
    addressId: string,
    id: number,
    options?: FetchOptions
) => Promise<Response>;

type GetFarmInTimeProducts = (
    customerNumber: string,
    addressId: string,
    options?: FetchOptions
) => Promise<FarmInTimeResponse>;

type GetContactByAddress = (
    customerNumber: string,
    addressId: string,
    options?: FetchOptions
) => Promise<NotificationContact>;

type GetContactByAddressV2 = (
    customerNumber: string,
    addressId: string,
    options?: FetchOptions
) => Promise<NotificationContact[]>;

type PutContactsByAddress = (
    customerNumber: string,
    addressId: string,
    parameters: NotificationContact,
    options?: FetchOptions
) => Promise<Response>;

type PutContactsByAddressV2 = (
    customerNumber: string,
    addressId: string,
    parameters: NotificationContact[],
    options?: FetchOptions
) => Promise<Response>;

type GetFarmInTimeFrequencies = (options?: FetchOptions) => Promise<string[]>;

type GetFarmInTimeMonths = (options?: FetchOptions) => Promise<string[]>;

type CreateFarmInTimeCart = (
    customerNumber: string,
    addressId: string,
    orderSecret: string | undefined,
    options?: FetchOptions
) => Promise<{
    cart: DA_CartData;
    cartSecret: string;
    farmInTimeReferenceId: string;
    message: string;
}>;

type CreateFarmInTimeCartWithSelection = (
    customerNumber: string,
    addressId: string,
    orderSecret: string | undefined,
    includedIds?: string[],
    options?: FetchOptions
) => Promise<{
    cart: DA_CartData;
    cartSecret: string;
    id: string;
    farmInTimeReferenceId: string;
    message: string;
}>;

type CreateFarmInTimeCartWithSelectionV2 = (
    customerNumber: string,
    addressId: string,
    mergeCart: boolean,
    includedIds?: string[],
    options?: FetchOptions
) => Promise<{
    cart: DA_CartData;
    cartSecret: string;
    id: string;
    farmInTimeReferenceId: string;
    message: string;
}>;

type GetProductPrices = (
    customerNumber: string,
    parameters: { quantity: number; productNumber: string; variantId?: string }[],
    options?: FetchOptions
) => Promise<DA_FarmInTimePrice[]>;

type GetDriverMessageByAddress = (
    customerNumber: string,
    addressId: string,
    options?: FetchOptions
) => Promise<{ message?: string }>;

type PutDriverMessageByAddress = (
    customerNumber: string,
    addressId: string,
    parameters: { driverMessage: string },
    options?: FetchOptions
) => Promise<Response>;

export const useFarmInTimeApi = () => {
    const request = useRequest();

    const getOrderListProducts: GetOrderListProducts = useCallback(
        (customerNumber: string, addressId: string, options?) =>
            request.get(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/orderlistproducts`,
                undefined,
                options
            ),
        [request]
    );

    const getOrderListProductsV2: GetOrderListProducts = useCallback(
        (customerNumber: string, addressId: string, options?) =>
            request.get(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/orderlistproducts?version=2`,
                undefined,
                options
            ),
        [request]
    );

    const updateOrderListProduct: UpdateOrderListProduct = useCallback(
        (
            customerNumber: string,
            addressId: string,
            farmInTimeOrderListProductId: number,
            parameters,
            options?
        ) =>
            request.patch(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/orderlistproducts/${farmInTimeOrderListProductId}`,
                parameters,
                options
            ),
        [request]
    );

    const updateOrderListProductV2: UpdateOrderListProduct = useCallback(
        (
            customerNumber: string,
            addressId: string,
            farmInTimeOrderListProductId: number,
            parameters,
            options?
        ) =>
            request.patch(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/orderlistproducts/${farmInTimeOrderListProductId}?version=2`,
                parameters,
                options
            ),
        [request]
    );

    const setOrderlistProducts: SetOrderlistProducts = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) =>
            request.post(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/orderlistproducts`,
                parameters,
                options
            ),
        [request]
    );

    const setOrderlistProductsV2: SetOrderlistProducts = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) =>
            request.post(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/orderlistproducts?version=2`,
                parameters,
                options
            ),
        [request]
    );

    const setFarmInTimeProducts: SetFarmInTimeProducts = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) =>
            request.post(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/farmintimeproducts`,
                parameters,
                options
            ),
        [request]
    );

    const setFarmInTimeProductsV2: SetFarmInTimeProducts = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) =>
            request.post(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/farmintimeproducts?version=2`,
                parameters,
                options
            ),
        [request]
    );

    const updateFarmInTimeProduct: UpdateFarmInTimeProduct = useCallback(
        (customerNumber: string, addressId: string, farmInTimeId: number, parameters, options?) =>
            request.patch(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/farmintimeproducts/${farmInTimeId}`,
                parameters,
                options
            ),
        [request]
    );

    const updateFarmInTimeProductV2: UpdateFarmInTimeProduct = useCallback(
        (customerNumber: string, addressId: string, farmInTimeId: number, parameters, options?) =>
            request.patch(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/farmintimeproducts/${farmInTimeId}?version=2`,
                parameters,
                options
            ),
        [request]
    );

    const deleteFarmInTimeProduct: DeleteFarmInTimeProduct = useCallback(
        (customerNumber: string, addressId: string, farmInTimeId: number, options?) =>
            request.delete(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/farmintimeproducts/${farmInTimeId}`,
                undefined,
                options
            ),
        [request]
    );

    const getFarmInTimeFrequencies: GetFarmInTimeFrequencies = useCallback(
        (options?) => request.get("farmintime/frequencies", options),
        [request]
    );

    const getFarmInTimeMonths: GetFarmInTimeMonths = useCallback(
        (options?) => request.get("farmintime/months", options),
        [request]
    );

    // NOTE: Deprecated for createFarmInTimeCartWithSelection
    const createFarmInTimeCart: CreateFarmInTimeCart = useCallback(
        (customerNumber: string, addressId: string, orderSecret: string | undefined, options?) =>
            request
                .post(
                    `/farmintime/customers/${customerNumber}/addresses/${addressId}/createcart${
                        orderSecret ? `?mergeOrderSecret=${orderSecret}` : ""
                    }`,
                    undefined,
                    options
                )
                .then((response) => response.json()),
        [request]
    );

    const createFarmInTimeCartWithSelection: CreateFarmInTimeCartWithSelection = useCallback(
        (
            customerNumber: string,
            addressId: string,
            orderSecret: string | undefined,
            includedIds?: string[],
            options?
        ) =>
            request
                .post(
                    `/farmintime/customers/${customerNumber}/addresses/${addressId}/createcartwithselection${
                        orderSecret ? `?mergeOrderSecret=${orderSecret}` : ""
                    }`,
                    JSON.stringify(includedIds),
                    options
                )
                .then((response) => response.json()),
        [request]
    );

    const createFarmInTimeCartWithSelectionV2: CreateFarmInTimeCartWithSelectionV2 = useCallback(
        (
            customerNumber: string,
            addressId: string,
            mergeCart: boolean,
            includedIds?: string[],
            options?
        ) =>
            request
                .post(
                    `/farmintime/customers/${customerNumber}/addresses/${addressId}/createcartwithselection?mergeCart=${
                        mergeCart ? `true` : "false"
                    }&version=2`,
                    JSON.stringify(includedIds),
                    options
                )
                .then((response) => response.json()),
        [request]
    );

    const deleteProductFromOrderList: DeleteProductFromOrderList = useCallback(
        (customerNumber: string, addressId: string, id: number, options?) =>
            request.delete(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/orderlistproducts/${id}`,
                undefined,
                options
            ),
        [request]
    );

    const getFarmInTimeProducts: GetFarmInTimeProducts = useCallback(
        (customerNumber: string, addressId: string, options?) =>
            request.get(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/farmintimeproducts`,
                undefined,
                options
            ),
        [request]
    );

    const getFarmInTimeProductsV2: GetFarmInTimeProducts = useCallback(
        (customerNumber: string, addressId: string, options?) =>
            request.get(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/farmintimeproducts?version=2`,
                undefined,
                options
            ),
        [request]
    );

    const getContactByAddress: GetContactByAddress = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) =>
            request.get(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/contacts`,
                parameters,
                options
            ),
        [request]
    );

    const getContactByAddressV2: GetContactByAddressV2 = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) =>
            request.get(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/contacts`,
                { version: 2 },
                parameters,
                options
            ),
        [request]
    );

    const putContactsByAddress: PutContactsByAddress = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) =>
            request.put(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/contacts`,
                parameters,
                options
            ),
        [request]
    );

    const putContactsByAddressV2: PutContactsByAddressV2 = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) =>
            request.put(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/contacts?version=2`,
                parameters,
                options
            ),
        [request]
    );

    const getProductPrices: GetProductPrices = useCallback(
        (customerNumber: string, parameters, options?) => {
            return (
                request
                    // NOTE: When the pricing service is faster, we want to use this endpoint instead to re-add quantity increase to the price
                    // .post(`farmintime/customers/${customerNumber}/getproductpricebyquantity`, parameters, options)
                    .post(`prices/getlistprices/${customerNumber}`, parameters, options)
                    .then((response) => response.json())
                    .then(({ productPrices }) => productPrices)
            );
        },
        [request]
    );

    const getFarmInTimeDriverMessageByAddress: GetDriverMessageByAddress = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) => {
            return request.get(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/drivermessage`,
                parameters,
                options
            );
        },
        [request]
    );

    const putFarmInTimeDriverMessageByAddress: PutDriverMessageByAddress = useCallback(
        (customerNumber: string, addressId: string, parameters, options?) => {
            return request.put(
                `farmintime/customers/${customerNumber}/addresses/${addressId}/drivermessage`,
                parameters,
                options
            );
        },
        [request]
    );

    return {
        getOrderListProducts,
        getOrderListProductsV2,
        updateOrderListProduct,
        updateOrderListProductV2,
        setOrderlistProducts,
        setOrderlistProductsV2,
        setFarmInTimeProducts,
        setFarmInTimeProductsV2,
        updateFarmInTimeProduct,
        updateFarmInTimeProductV2,
        deleteFarmInTimeProduct,
        getFarmInTimeFrequencies,
        getFarmInTimeMonths,
        createFarmInTimeCart,
        createFarmInTimeCartWithSelection,
        createFarmInTimeCartWithSelectionV2,
        deleteProductFromOrderList,
        getFarmInTimeProducts,
        getFarmInTimeProductsV2,
        getContactByAddress,
        getContactByAddressV2,
        putContactsByAddress,
        putContactsByAddressV2,
        getProductPrices,
        getFarmInTimeDriverMessageByAddress,
        putFarmInTimeDriverMessageByAddress,
    };
};
