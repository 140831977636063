import React, { useEffect, useState } from "react";
import { ProductFacetResult, StringProductDataValueFacetResult } from "@relewise/client";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import { ChecklistFacet } from "./components/ChecklistFacet.component";

export interface Relewise_FacetsProps {
    facets?: ProductFacetResult;
    update?: (items: unknown) => void;
}

export const Relewise_Facets = (props: Relewise_FacetsProps) => {
    const { getDictionaryString } = useTranslations();
    const { selectedFacets, setSelectedFacets } = useRelewise();
    const [hasFacets, setHasFacets] = useState<boolean>();

    useEffect(() => {
        const facetCount = props.facets?.items?.reduce(
            (total, item: StringProductDataValueFacetResult) => total + item.available?.length,
            0
        );
        setHasFacets(facetCount > 0);
    }, [props.facets]);

    const getFacetsByType = (selectedFacets: Record<string, string[]>, type: string) => {
        if ((selectedFacets && !selectedFacets[type]) || selectedFacets[type].length < 1) {
            return null;
        }
        return selectedFacets[type];
    };

    const setFacet = (type: string, value: string) => {
        const currentSelectFacetValues = getFacetsByType(selectedFacets, type);
        const valueAlreadySelected =
            (currentSelectFacetValues?.find((v) => v === value)?.length ?? 0) > 0;

        if (valueAlreadySelected) {
            const newSelectFacets = { ...selectedFacets };
            const indexToRemove = newSelectFacets[type].indexOf(value);
            newSelectFacets[type].splice(indexToRemove, 1);
            setSelectedFacets(newSelectFacets);
            return;
        }

        const newSelectFacets = { ...selectedFacets };
        newSelectFacets[type].push(value);
        setSelectedFacets(newSelectFacets);
    };

    return (
        <>
            {hasFacets ? (
                <DA_Title h3 noMargin>
                    {getDictionaryString("filters")}
                </DA_Title>
            ) : null}

            {props.facets?.items?.map((facet, index) =>
                (facet as StringProductDataValueFacetResult).available.length ? (
                    <div key={index}>
                        {facet.field === "Data" ? (
                            <ChecklistFacet
                                expanded={index < 3}
                                facet={facet as StringProductDataValueFacetResult}
                                selected={
                                    selectedFacets[(facet as StringProductDataValueFacetResult).key]
                                }
                                setFacet={setFacet}
                            />
                        ) : null}
                    </div>
                ) : null
            )}
        </>
    );
};
