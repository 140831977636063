import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useQuickPayApi } from "@hooks/api/useQuickPayApi.hook";

export const useQuickpayPaymentMethods = () => {
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string>();
    const [paymentMethods, setPaymentMethods] = useState<string[]>();

    const { getPaymentMethods } = useQuickPayApi();

    const onPaymentMethodChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentPaymentMethod(event.target.value);
    };

    const fetchPaymentMethods = useCallback(async () => {
        try {
            const response = await getPaymentMethods();

            if (response && Array.isArray(response)) {
                setPaymentMethods(response);
                setCurrentPaymentMethod(response?.[0]);
            }
        } catch {
            // TODO: A JIRA task has been created to handle this flow better.
        }
    }, [getPaymentMethods]);

    useEffect(() => {
        fetchPaymentMethods();
    }, [fetchPaymentMethods]);

    return {
        currentPaymentMethod,
        onPaymentMethodChange,
        paymentMethods,
    };
};
