import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { DA_InputWrapper } from "@danishagro/shared/src/components/atoms/InputWrapper/InputWrapper.component";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { DA_QuantityPicker } from "@molecules/QuantityPicker/QuantityPicker.component";
import { DA_Select } from "@danishagro/shared/src/components/atoms/Select/Select.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_WishListToast } from "@molecules/WishListButton/WishListToast/WishListToast.component";
import { useAddressFromSessionStorage } from "@danishagro/shared/src/hooks/useAddressFromSessionStorage.hook";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { pushToDataLayer } from "@danishagro/shared/src/gtm/useTagManger";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { DA_Tag } from "@danishagro/shared/src/components/atoms/Tag/Tag.component";
import { isGreaterThanZero } from "@danishagro/shared/src/helpers/greaterThanZero.helper";
import { useDeliveryAddress } from "../hooks/useDeliveryAddress.hook";
import { useFrequencies } from "../hooks/useFrequencies.hook";
import { useMonths } from "../hooks/useMonths.hook";
import S from "./FarmInTimeModal.module.scss";

interface FarmInTimeModalProps {
    productId: string;
    productName: string;
    productImage: string;
    variantId?: string;
    quantityOnPallet?: string;
}

export const _FarmInTimeModal = (props: FarmInTimeModalProps) => {
    const { getDictionaryString, getDictionaryItem } = useTranslations();
    const { closeModal } = useModal();
    const { customerNumber } = useAppData();
    const [quantity, setQuantity] = useState(1);
    const { isMobile } = useScreen();
    const { setFarmInTimeProductsV2 } = useFarmInTimeApi();
    const { saveDeliveryInfo } = useCart();
    const {
        setAddressInSessionStorage,
        hasAddressInSessionStorage,
        currentAddressFromSessionStorage,
    } = useAddressFromSessionStorage();

    const {
        currentDeliveryAddressOption,
        deliveryAddressOptions,
        currentDeliveryAddress,
        onDeliveryAddressSelectChanged,
    } = useDeliveryAddress();

    const updateQuantity = useCallback((newQuantity: number) => {
        setQuantity(newQuantity);
    }, []);

    const addProductToFarmInTime = useCallback(
        (productId, quantity, variantId, frequency, month) => {
            setFarmInTimeProductsV2(customerNumber, currentDeliveryAddress?.id, {
                productId: productId,
                quantity: quantity,
                variantId: variantId,
                frequency: frequency,
                month: month,
            }).then(() => {
                toast.custom(() => (
                    <DA_WishListToast
                        heading={getDictionaryString("WishListToastFarmInTimeHeading")}
                        href="/bestillingsliste#tab-FARM-IN-TIME"
                    />
                ));
                setAddressInSessionStorage(currentDeliveryAddress.id);
                // GTM Tracking
                pushToDataLayer({
                    event: "add_as_favorite",
                    product_name: props.productName,
                });
                closeModal();
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            setFarmInTimeProductsV2,
            customerNumber,
            currentDeliveryAddress,
            setAddressInSessionStorage,
            saveDeliveryInfo,
            closeModal,
            getDictionaryString,
        ]
    );

    const isFormReady = useMemo(
        () => typeof currentDeliveryAddress?.id !== "undefined",
        [currentDeliveryAddress]
    );

    const { frequencyOptions, onFrequencySelectChanged, currentFrequency, currentFrequencyOption } =
        useFrequencies();

    const {
        monthOptions,
        onMonthSelectChanged,
        currentMonth,
        currentMonthOption,
        hasMonthError,
        setMonthError,
    } = useMonths();

    const onSubmitButtonClicked = () => {
        if (currentFrequency === "Yearly" && typeof currentMonth === "undefined") {
            setMonthError(true);
            return;
        }

        addProductToFarmInTime(
            props.productId,
            quantity,
            props.variantId,
            currentFrequency,
            currentMonth
        );
    };

    return (
        <DA_Modal
            title={getDictionaryString("AddProductToFarmInTime", {
                uppercaseFirst: true,
            })}
        >
            <DA_Text>
                {getDictionaryString("FarmInTimeModalText", {
                    uppercaseFirst: true,
                })}
            </DA_Text>

            {/** Delivery Address */}
            <DA_InputWrapper
                label={getDictionaryString("FarmInTimeModalAddressLabel")}
                forId="add-product-to-fit"
                tooltip={getDictionaryString("FarmInTimeModalTooltipText")}
            >
                <DA_Select
                    className={S.selectWrapper}
                    id="add-product-to-fit"
                    value={
                        hasAddressInSessionStorage
                            ? currentAddressFromSessionStorage
                            : currentDeliveryAddressOption?.value
                    }
                    options={deliveryAddressOptions}
                    onChange={onDeliveryAddressSelectChanged}
                />
            </DA_InputWrapper>

            {isMobile ? (
                <>
                    <div className={S.mobileWrapper}>
                        <div className={S.mobileTitle}>{getDictionaryString("product")}</div>
                        <div className={S.mobileImageNameWrapper}>
                            <DA_Image
                                key={props.productImage}
                                src={B2bImageSrc(props.productImage, ImageConfig.PdpThumbnail)}
                                alt={props.productName}
                                className={S.mobileImage}
                            />
                            <div className={S.mobileProductNameWrapper}>{props.productName}</div>
                        </div>
                    </div>
                    <div className={S.mobileFrequencyWrapper}>
                        <label className={S.mobileTitle} htmlFor="farm-in-time-frequency-mobile">
                            {getDictionaryString("frequency")}
                        </label>
                        <div className={S.mobileFrequencyInner}>
                            <DA_Select
                                id="farm-in-time-frequency-mobile"
                                value={currentFrequencyOption?.value}
                                options={frequencyOptions}
                                onChange={onFrequencySelectChanged}
                            />

                            {currentFrequency === "Yearly" ? (
                                <DA_Select
                                    id="farm-in-time-month-mobile"
                                    value={currentMonthOption?.value}
                                    options={monthOptions}
                                    invalid={hasMonthError}
                                    onChange={onMonthSelectChanged}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className={S.mobileQuantityPickerWrapper}>
                        <label className={S.mobileTitle} htmlFor="farm-in-time-modal-quantity">
                            {getDictionaryString("amount")}
                        </label>
                        <DA_QuantityPicker
                            inputId="farm-in-time-modal-quantity"
                            onChange={updateQuantity}
                            value={quantity}
                        />
                    </div>
                    <div>
                        {isGreaterThanZero(props.quantityOnPallet) && (
                            <div className={S.labelTagWrapperMobile}>
                                <DA_Tag color="neutral" size="dynamic">
                                    {getDictionaryItem("quantityonpallettext", {
                                        quantity: props.quantityOnPallet,
                                    })}
                                </DA_Tag>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className={S.productWrapper}>
                    <div className={S.header} aria-hidden={true}>
                        <div className={S.headerItem}>{getDictionaryString("product")}</div>
                        <div className={S.headerItem}>{getDictionaryString("frequency")}</div>
                        <div className={S.headerItem}>{getDictionaryString("amount")}</div>
                    </div>
                    <div className={S.productInfoWrapper}>
                        <div className={S.imageWrapper}>
                            <DA_Image
                                key={props.productImage}
                                src={B2bImageSrc(props.productImage, ImageConfig.PdpThumbnail)}
                                alt=""
                                className={S.image}
                            />
                        </div>
                        <div className={S.productNameWrapper}>{props.productName}</div>
                        <div className={S.productFrequencyWrapper}>
                            <DA_Select
                                id="farm-in-time-frequency"
                                value={currentFrequencyOption?.value}
                                options={frequencyOptions}
                                onChange={onFrequencySelectChanged}
                            />

                            {currentFrequency === "Yearly" ? (
                                <DA_Select
                                    id="farm-in-time-month"
                                    value={currentMonthOption?.value}
                                    options={monthOptions}
                                    invalid={hasMonthError}
                                    onChange={onMonthSelectChanged}
                                />
                            ) : null}
                        </div>
                        <div className={S.quantityQounterWrapper}>
                            <DA_QuantityPicker onChange={updateQuantity} value={quantity} />
                            {isGreaterThanZero(props.quantityOnPallet) && (
                                <div className={S.labelTagWrapper}>
                                    <DA_Tag color="neutral" size="dynamic">
                                        {getDictionaryItem("quantityonpallettext", {
                                            quantity: props.quantityOnPallet,
                                        })}
                                    </DA_Tag>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className={S.buttonWrapper}>
                <DA_Button
                    title={
                        isMobile
                            ? getDictionaryString("FarmInTimeModalMobileSubmitButton")
                            : getDictionaryString("FarmInTimeModalSubmitButton")
                    }
                    onClick={onSubmitButtonClicked}
                    className={S.primary}
                    disabled={!isFormReady}
                />
                <DA_Button
                    title={getDictionaryString("cancel")}
                    isGhost
                    onClick={closeModal}
                    className={S.secondary}
                />
            </div>
        </DA_Modal>
    );
};
