import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { DA_Address } from "../interfaces/address.interface";

export const useAddressFromSessionStorage = () => {
    const [searchParams] = useSearchParams();

    // Set address in browsers session storage
    const setAddressInSessionStorage = useCallback((addressId: string) => {
        if (typeof addressId !== "undefined" && addressId) {
            sessionStorage.setItem("addressId", addressId);
        }
    }, []);

    // Get addressId from queryString
    const getAddressIdFromQueryString = useCallback(() => {
        const addressId = searchParams.get("addressId");
        setAddressInSessionStorage(addressId);
    }, [searchParams, setAddressInSessionStorage]);

    // Boolean: Check if session storage has address
    const hasAddressInSessionStorage = !!sessionStorage.getItem("addressId");

    // Get current address object from session storage
    const currentAddressFromSessionStorage = sessionStorage.getItem("addressId");

    // function to find address object
    const getAddressObject = useCallback(
        (addresses: DA_Address[], addressId?: string) => {
            const argumentAddress = addressId
                ? addresses.find((x) => x.id === addressId)
                : undefined;
            const storageAddress = currentAddressFromSessionStorage
                ? addresses.find((x) => x.id === currentAddressFromSessionStorage)
                : undefined;

            // First accept a direct addressId as argument
            if (argumentAddress) {
                return argumentAddress;
            }
            // Second look in the sessionStorage and check for that
            else if (storageAddress) {
                return storageAddress;
            }
            // Third return the first address in list
            else {
                return addresses?.[0];
            }
        },
        [currentAddressFromSessionStorage]
    );

    // Init

    useEffect(() => {
        getAddressIdFromQueryString();
    }, [getAddressIdFromQueryString]);

    return {
        setAddressInSessionStorage,
        hasAddressInSessionStorage,
        currentAddressFromSessionStorage,
        getAddressObject,
    };
};
