import React from "react";
import { Helmet } from "react-helmet";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_OneStepCheckout } from "@templates/OneStepCheckout/OneStepCheckout.component";
import { CartProvider } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useLocation } from "react-router-dom";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";

export const DA_FarmInTimeCheckoutPage = () => {
    const { globalTranslations, getDictionaryString } = useTranslations();
    const { customerNumber } = useAppData();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const orderSecret = searchParams.get("cart");

    return (
        <>
            <Helmet>
                <title>
                    {getDictionaryString("checkout")}
                    {" | "}
                    {globalTranslations.pageTitlePostfix}
                </title>
            </Helmet>

            {orderSecret ? (
                <CartProvider customerNumber={customerNumber} orderSecret={orderSecret}>
                    <DA_OneStepCheckout />
                </CartProvider>
            ) : null}

            {!orderSecret ? (
                <DA_Container>
                    <DA_EmptyState
                        title={getDictionaryString("fitCheckoutUnauthorizedTitle")}
                        text={getDictionaryString("fitCheckoutUnauthorizedBody")}
                        type={DA_EmptyStateTypes.Error}
                    />
                </DA_Container>
            ) : null}
        </>
    );
};
