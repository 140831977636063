import React from "react";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import S from "./ArrowButton.module.scss";

export interface DA_ArrowButtonProps {
    onCLick: () => void;
    className?: string;
    iconDirection?: DA_arrowDirection;
    small?: boolean;
    disabled?: boolean;
    title?: string;
    isGhost?: boolean;
}

export enum DA_arrowDirection {
    Left = "Left",
    Right = "Right",
    Top = "Top",
    Bottom = "Bottom",
}

export const DA_ArrowButton = ({
    onCLick,
    className,
    iconDirection,
    small,
    disabled,
    title,
    isGhost,
}: DA_ArrowButtonProps) => {
    const buttonClass = cn(
        S.button,
        iconDirection === DA_arrowDirection.Left && S.directionLeft,
        iconDirection === DA_arrowDirection.Top && S.directionTop,
        iconDirection === DA_arrowDirection.Bottom && S.directionBottom
    );

    return (
        <div className={cn(className, S.wrapper)}>
            <DA_Button
                icon={DA_IconNames.ChevronRight}
                onClick={onCLick}
                hideText
                title={title}
                small={small}
                large={!small}
                disabled={disabled}
                className={buttonClass}
                isGhost={isGhost}
            />
        </div>
    );
};
