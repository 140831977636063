import React from "react";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { components, ValueContainerProps } from "react-select";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { getSelectStyles } from "@danishagro/shared/src/helpers/getSelectStyles.helper";
import { AutocompleteRequest } from "../interfaces/autocompleteRequest.interface";
import S from "./useGooglePlacesAutocomplete.module.scss";

export const useGooglePlacesAutocomplete = (
    prefix,
    setAutomaticAddress,
    currentErrorCount,
    onAutomaticAddressChangeCallback
) => {
    const { getDictionaryString } = useTranslations();
    const { customerDetails, shippingDetails } = useCart();

    const onAutocompleteLookupSuccess = (results: google.maps.GeocoderResult[]) => {
        const streetName = results?.[0].address_components.find((x) => x.types.includes("route"));
        const streetNumber = results?.[0].address_components.find((x) =>
            x.types.includes("street_number")
        );
        const zipCode = results?.[0].address_components.find((x) =>
            x.types.includes("postal_code")
        );
        const city = results?.[0].address_components.find((x) => x.types.includes("locality"));

        setAutomaticAddress({
            streetName: streetName?.long_name ?? "",
            streetNumber: streetNumber?.long_name ?? "",
            zipCode: zipCode?.long_name ?? "",
            city: city?.long_name ?? "",
        });

        if (typeof onAutomaticAddressChangeCallback !== "undefined") {
            onAutomaticAddressChangeCallback();
        }
    };

    const onAutocompleteSelectChanged = (response: AutocompleteRequest) => {
        if (response?.value?.place_id) {
            geocodeByPlaceId(response.value.place_id)
                .then(onAutocompleteLookupSuccess)
                .catch((error) => console.error(error));
        } else {
            // Clearing
            setAutomaticAddress({
                streetName: "",
                streetNumber: "",
                zipCode: "",
                city: "",
            });

            if (typeof onAutomaticAddressChangeCallback !== "undefined") {
                onAutomaticAddressChangeCallback();
            }
        }
    };

    const ValueContainerWithIcon = ({ children, ...props }: ValueContainerProps) => (
        <components.ValueContainer {...props}>
            <div className={S.valueContainerWrapper}>
                <DA_Icon className={S.valueContainerIcon} name={DA_IconNames.Zoom} />
                <div className={S.valueContainerLabel}>{children}</div>
            </div>
        </components.ValueContainer>
    );

    const getPlaceholder = () => {
        if (!!customerDetails?.streetName && !!shippingDetails?.streetName) {
            if (prefix === "billing") {
                return `${customerDetails.streetName} ${customerDetails.streetNumber},
                        ${customerDetails.zipCode} ${customerDetails.city}`;
            } else if (prefix === "shipping") {
                return `${shippingDetails.streetName} ${shippingDetails.streetNumber},
                        ${shippingDetails.zipCode} ${shippingDetails.city}`;
            }
        }
    };

    const selectProps = {
        className: S.select,
        onChange: onAutocompleteSelectChanged,
        openMenuOnFocus: false,
        placeholder: getPlaceholder(),
        isClearable: true,
        noOptionsMessage: () => getDictionaryString("no autocomplete options"),
        loadingMessage: () => getDictionaryString("loading autocomplete results"),
        components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            ValueContainer: ValueContainerWithIcon,
        },
        styles: getSelectStyles({ invalid: currentErrorCount > 0 }),
    };

    return {
        selectProps,
    };
};
