import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Tippy from "@tippyjs/react/headless";
import ReactFocusLock from "react-focus-lock";
import { eachDayOfInterval, format, isSameDay } from "date-fns";
import { da } from "date-fns/locale";
import { Calendar } from "react-date-range";
import { animated, useSpring } from "react-spring";
import { Placement } from "tippy.js";
import { useIsMounted } from "../../../hooks/useIsMounted.hook";
import { DA_Input } from "../../atoms/Input/Input.component";
import { DA_IconNames } from "../../atoms/Icon/Icon.component";
import { ConditionalWrapper } from "../../../helpers/ConditionalWrapper.helper";
import S from "./DatePicker.module.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

export interface DA_DatePickerProps {
    name?: string;
    firstInputId?: string;
    date?: string;
    minDate?: string;
    maxDate?: string;
    datePlaceholder?: string;
    onChange?: (date: string) => void;
    disabled?: boolean;
    disableFocusLock?: boolean;
    placement?: Placement;
    appendTo?: Element | "parent";
    invalid?: boolean;
    suggestedDeliveryDates?: string[];
    deliveryModeCode?: string;
    disabledDay?: (date: Date) => boolean;
}

export const DA_DatePicker = (props: DA_DatePickerProps) => {
    const [mountId, setMountId] = useState(new Date().getTime());
    const [selectedDate, setSelectedDate] = useState<Date>(
        props.date ? new Date(props.date) : undefined
    );
    const [visible, setVisible] = useState(false);
    const initialAnimationSettings = useRef({
        opacity: 0,
        config: { tension: 300, friction: 15 },
    }).current;
    const [popUpStyle, setSpring] = useSpring(() => initialAnimationSettings);
    const isMounted = useIsMounted();
    const inputRef = useRef<HTMLInputElement>(null);

    // Logic for parsing suggestedDeliveryDates and calculating disabledDates only when deliveryModeCode is 11
    const suggestedDates = useMemo(() => {
        // TODO: Activate deliveryModeCode 11 later
        if (props.deliveryModeCode === "111111" && props.suggestedDeliveryDates?.length > 0) {
            return props.suggestedDeliveryDates.map((dateString) => {
                const date = new Date(dateString);
                date.setHours(0, 0, 0, 0); // Strip time
                return date;
            });
        }
        return [];
    }, [props.suggestedDeliveryDates, props.deliveryModeCode]);

    // // Ensure selectedDate is set only after suggestedDates are loaded
    // useEffect(() => {
    //     if (suggestedDates.length > 0 && isFirstRender) {
    //         setSelectedDate(suggestedDates[0]);
    //         setIsFirstRender(false); // Mark first render as complete
    //     }
    // }, [suggestedDates, isFirstRender]);

    // // On subsequent renders when suggestedDates changes, you can still set the first date
    // useEffect(() => {
    //     if (suggestedDates.length > 0 && !isFirstRender) {
    //         setSelectedDate(suggestedDates[0]);
    //     }
    // }, [suggestedDates, isFirstRender]);

    // Use the first suggested date as minDate and the last as maxDate (for deliveryModeCode 11)
    const minDateObj = useMemo(
        () =>
            suggestedDates.length > 0
                ? suggestedDates[0]
                : props.minDate
                ? new Date(props.minDate)
                : undefined,
        [suggestedDates, props.minDate]
    );
    const maxDateObj = useMemo(
        () =>
            suggestedDates.length > 0
                ? suggestedDates[suggestedDates.length - 1]
                : props.maxDate
                ? new Date(props.maxDate)
                : undefined,
        [suggestedDates, props.maxDate]
    );

    // Calculate disabled dates (all dates between minDate and maxDate except the suggestedDates)
    const disabledDates = useMemo(() => {
        if (suggestedDates.length > 0 && minDateObj && maxDateObj) {
            const allDates = eachDayOfInterval({
                start: minDateObj,
                end: maxDateObj,
            });
            return allDates.filter(
                (date) => !suggestedDates.some((allowedDate) => isSameDay(date, allowedDate))
            );
        }
        return [];
    }, [minDateObj, maxDateObj, suggestedDates]);

    const show = useCallback(() => {
        setVisible(true);
    }, []);

    const hide = useCallback(() => {
        setVisible(false);
        setMountId(new Date().getTime());
    }, []);

    const onKeyDown = useCallback(
        (event) => {
            if (event.keyCode === 27) {
                hide();
            }
        },
        [hide]
    );

    const onShow = useCallback(() => {
        document.addEventListener("keydown", onKeyDown);
    }, [onKeyDown]);

    const onHide = useCallback(() => {
        document.removeEventListener("keydown", onKeyDown);
        setTimeout(() => {
            if (document.activeElement === document.body) {
                inputRef.current.focus();
            }
        }, 0);
    }, [onKeyDown]);

    const parseDate = useCallback(
        (date: Date) =>
            date
                .toLocaleDateString("da")
                .split(".")
                .reverse()
                .map((value) => value.padStart(2, "0"))
                .join("-"),
        []
    );

    const onInputKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const ignoreKeys = ["Tab", "Shift", "Alt", "Control"];
            if (!ignoreKeys.includes(event.key)) {
                show();
            }
        },
        [show]
    );

    useEffect(() => {
        if (props.date) {
            setSelectedDate(new Date(props.date));
        }
    }, [props.date]);

    useEffect(() => {
        setSpring({
            ...initialAnimationSettings,
            opacity: visible ? 1 : 0,
        });
    }, [setSpring, initialAnimationSettings, visible]);

    return (
        <ConditionalWrapper
            condition={isMounted}
            wrapper={(children) => (
                <Tippy
                    key={mountId}
                    arrow={false}
                    visible={visible}
                    animation
                    interactive
                    placement={props.placement || "bottom-start"}
                    appendTo={props.appendTo || document.body}
                    onShow={onShow}
                    onHide={onHide}
                    onClickOutside={hide}
                    disabled={props.disabled}
                    render={(attr) => (
                        <animated.div className={S.popUp} style={popUpStyle} {...attr}>
                            <ReactFocusLock
                                disabled={!visible || props.disableFocusLock}
                                autoFocus={visible}
                                returnFocus={false}
                            >
                                <Calendar
                                    locale={da}
                                    date={selectedDate}
                                    minDate={minDateObj}
                                    maxDate={maxDateObj}
                                    disabledDates={props.suggestedDeliveryDates && disabledDates}
                                    disabledDay={props.disabledDay}
                                    months={1}
                                    direction="horizontal"
                                    color="var(--color-primary-1)"
                                    preventSnapRefocus={true}
                                    onChange={(date) => {
                                        setSelectedDate(date);
                                        setTimeout(hide, 300);
                                        if (props.onChange) {
                                            props.onChange(parseDate(date));
                                        }
                                    }}
                                    showDateDisplay={false}
                                />
                            </ReactFocusLock>
                        </animated.div>
                    )}
                >
                    {children}
                </Tippy>
            )}
        >
            <div className={S.wrapper}>
                <div className={S.inputWrapper}>
                    <DA_Input
                        ref={inputRef}
                        id={props.firstInputId}
                        name={props.name}
                        icon={DA_IconNames.Calendar}
                        inputClassName={S.rawInput}
                        onClick={show}
                        onKeyDown={onInputKeyDown}
                        value={selectedDate ? format(selectedDate, "dd.MM.yyyy") : ""}
                        showAsFocused={visible}
                        autoComplete="off"
                        placeholder={props.datePlaceholder}
                        disabled={props.disabled}
                        readOnly
                        invalid={props.invalid}
                    />
                </div>
            </div>
        </ConditionalWrapper>
    );
};
