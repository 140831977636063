import React, { useMemo, useState } from "react";
import { animated, Transition } from "react-spring";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { DA_FarmInTimeCartItem } from "@templates/FarmInTime/components/Cart/components/List/components/Item/Item.component";
import { DA_OrderListCartOrderLine } from "@danishagro/shared/src/interfaces/OrderListCartOrderLine.interface";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Checkbox } from "@danishagro/shared/src/components/atoms/Checkbox/Checkbox.component";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useFarmInTimeQuickSelect } from "../../contexts/FarmInTimeQuickSelectProvider";
import FarmInTimeQuickSelectTooltip from "./components/Tooltip/Tooltip";
import { DA_CartListProps } from "./List.props";
import S from "./List.module.scss";

export const DA_FarmInTimeCartList = (props: DA_CartListProps) => {
    const { getDictionaryString } = useTranslations();
    const { isMobile, isMobileOrTablet } = useScreen();
    const { customerNumber } = useAppData();

    const [isQuickSelectTooltipVisible, setQuickSelectTooltipVisible] = useState<boolean>(false);
    const { toggleAllOfType, isAllChecked, isQuickSelectEnabled, setSelectedProducts } =
        useFarmInTimeQuickSelect();

    const texts = useMemo(
        () => ({
            name: getDictionaryString("FarmInTimeCartListOrderingListTitle", {
                uppercaseFirst: true,
            }),
            amount: getDictionaryString("amount", { uppercaseFirst: true }),
            price: getDictionaryString("pcs price", { uppercaseFirst: true }),
        }),
        [getDictionaryString]
    );

    const onQuickSelectCheckboxChanged = (state: boolean) => {
        toggleAllOfType(props.type, state);
    };

    const onQuickSelectToggleButtonClicked = () => {
        setQuickSelectTooltipVisible((prevValue) => !prevValue);
    };

    const onQuickSelectChanged = () => {
        setSelectedProducts((prevValue) => {
            const result = {
                ...prevValue,
                enabled: !prevValue.enabled,
            };

            const list = result.data?.farmInTimeList;
            const keys = Object.keys(list ?? {});

            // If quick-select is now enabled, we want to deselect all by default
            if (!prevValue.enabled === true) {
                const items = keys.reduce((acc, key) => {
                    acc[key] = false;
                    return acc;
                }, {});

                result.data.farmInTimeList = items;
            }
            // If quick-select is now disabled we want to make sure all are selected
            else if (!prevValue.enabled === false) {
                const items = keys.reduce((acc, key) => {
                    acc[key] = true;
                    return acc;
                }, {});

                result.data.farmInTimeList = items;
            }

            localStorage.setItem(`fit-quick-select:${customerNumber}`, JSON.stringify(result));

            return result;
        });
    };

    const isSelectionShownClass =
        props.type === "farmInTimeList" && isQuickSelectEnabled ? S.isShown : S.isHidden;

    return (
        <section className={props.className}>
            {/** Header */}
            {!props.readOnly && (
                <div className={S.header} aria-hidden={true}>
                    <div className={S.headerItem}>
                        <div className={S.headerItemInner}>
                            {props.listTitle}

                            {props.type === "farmInTimeList" &&
                            isMobile &&
                            props.products?.length > 0 ? (
                                <>
                                    <button
                                        className={S.quickSelectTogggleButton}
                                        onClick={onQuickSelectToggleButtonClicked}
                                    >
                                        <DA_Icon
                                            className={S.quickSelectTogggleIcon}
                                            name={DA_IconNames.Ellipsis}
                                        />
                                    </button>

                                    <FarmInTimeQuickSelectTooltip
                                        isVisible={isQuickSelectTooltipVisible}
                                        isQuickSelectEnabled={isQuickSelectEnabled}
                                        onClose={onQuickSelectToggleButtonClicked}
                                        onChange={onQuickSelectChanged}
                                    />
                                </>
                            ) : null}
                        </div>
                    </div>
                    <div className={S.headerItem}>
                        <div className={S.headerItemInner}>
                            <span>{texts.amount}</span>

                            {props.type === "farmInTimeList" &&
                            !isMobile &&
                            props.products?.length > 0 ? (
                                <>
                                    <button
                                        className={S.quickSelectTogggleButton}
                                        onClick={onQuickSelectToggleButtonClicked}
                                    >
                                        <DA_Icon
                                            className={S.quickSelectTogggleIcon}
                                            name={DA_IconNames.Ellipsis}
                                        />
                                    </button>

                                    <FarmInTimeQuickSelectTooltip
                                        isVisible={isQuickSelectTooltipVisible}
                                        isQuickSelectEnabled={isQuickSelectEnabled}
                                        onClose={onQuickSelectToggleButtonClicked}
                                        onChange={onQuickSelectChanged}
                                    />
                                </>
                            ) : null}
                        </div>
                    </div>

                    <div className={cn(S.headerItem, isSelectionShownClass)}>
                        <DA_Checkbox
                            label={
                                !isMobileOrTablet
                                    ? getDictionaryString("FarmInTimeIncludeExcludeHeader")
                                    : ""
                            }
                            defaultChecked={isAllChecked?.[props.type]}
                            onChange={onQuickSelectCheckboxChanged}
                        />
                    </div>

                    <div className={S.headerItem}>{texts.price}</div>
                </div>
            )}

            {props.products?.length > 0 ? (
                <Transition
                    items={props.products}
                    from={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                    keys={(item) => item.farmInTimeOrderListProductId}
                >
                    {(style, item: DA_OrderListCartOrderLine, _state, index) => (
                        <animated.div className={S.item} style={style}>
                            <DA_FarmInTimeCartItem
                                {...item}
                                type={props.type}
                                address={props.address}
                                price={props.prices[index]}
                            />
                        </animated.div>
                    )}
                </Transition>
            ) : props.isFetching ? (
                <DA_EmptyState
                    title={getDictionaryString("loadingProducts")}
                    type={DA_EmptyStateTypes.Loading}
                    iconOnly
                />
            ) : (
                <DA_EmptyState
                    title={
                        props.type === "farmInTimeList"
                            ? getDictionaryString("FarmInTimeEmptyStateTitle")
                            : props.type === "orderingList"
                            ? getDictionaryString("OrderingListEmptyStateTitle")
                            : null
                    }
                    text={
                        props.type === "farmInTimeList"
                            ? getDictionaryString("FarmInTimeEmptyStateDescription")
                            : props.type === "orderingList"
                            ? getDictionaryString("OrderingListEmptyStateDescription")
                            : null
                    }
                    type={DA_EmptyStateTypes.NoCartItems}
                />
            )}
        </section>
    );
};
