import React, { FormEvent, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Input } from "@danishagro/shared/src/components/atoms/Input/Input.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useNavigate } from "react-router-dom";
import { useValidation } from "./hooks/useValidation.hook";
import { ContactInformationFormData } from "./interfaces/contactInformationFormData.interface";
import S from "./ContactInformation.module.scss";

export const DA_SteppedCheckoutContactInformation = () => {
    const { getDictionaryString } = useTranslations();
    const navigate = useNavigate();
    const { isMobile } = useScreen();

    const [viewState, setViewState] = useState<string>("NONE");

    const { saveContactInfo, customerDetails } = useCart();

    const { validationErrorMessages, validate, onInputChange } = useValidation();

    const onFormSubmitted = async (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        const form = new FormData(event.target as HTMLFormElement);
        const body = {} as ContactInformationFormData;

        form.forEach((value, key) => (body[key] = value));

        const isValid = validate(body);

        if (isValid) {
            setViewState("LOADING");

            // Submitting step 1
            // Will automatically refresh the cart globally
            saveContactInfo(body)
                .then(() => {
                    // Proceed to step 2
                    navigate("/checkout/shipping");
                })
                .catch(() => {
                    // TODO: A JIRA task has been created to handle this flow better.
                })
                .finally(() => {
                    setViewState("NONE");
                });
        }
    };

    const companyNameError = validationErrorMessages.find((x) => x.key === "companyName");
    const cvrError = validationErrorMessages.find((x) => x.key === "cvr");
    const emailError = validationErrorMessages.find((x) => x.key === "email");
    const phoneError = validationErrorMessages.find((x) => x.key === "phone");
    const repeatEmailError = validationErrorMessages.find((x) => x.key === "repeatEmail");

    return (
        <form noValidate onSubmit={onFormSubmitted}>
            <section className={S.section}>
                <div className={S.header}>
                    <DA_Title h3>{getDictionaryString("contactInformation")}</DA_Title>
                    <span className={S.headerSpacer}>
                        *{getDictionaryString("mandatory field")}
                    </span>
                </div>
                <div className={S.inner}>
                    <div className={S.split}>
                        {/** Company Name */}
                        <DA_Input
                            id="companyName"
                            label={`${getDictionaryString("companyName")}*`}
                            className={S.inputWrapper}
                            defaultValue={customerDetails?.companyName}
                            autoComplete="organization"
                            onChange={() => onInputChange("companyName")}
                            disabled={viewState === "LOADING"}
                            invalid={companyNameError ? true : false}
                            helperText={companyNameError ? companyNameError.value : undefined}
                        />

                        {/** VAT Number */}
                        <DA_Input
                            id="cvr"
                            label={`${getDictionaryString("companyNumber")}`}
                            className={S.inputWrapper}
                            defaultValue={customerDetails?.cvr}
                            onChange={() => onInputChange("cvr")}
                            disabled={viewState === "LOADING"}
                            invalid={cvrError ? true : false}
                            helperText={cvrError ? cvrError.value : undefined}
                        />
                    </div>

                    <div className={S.split}>
                        {/** E-mail */}
                        <DA_Input
                            id="email"
                            type="email"
                            label={`${getDictionaryString("email")}*`}
                            className={S.inputWrapper}
                            onChange={() => onInputChange("email")}
                            defaultValue={customerDetails?.email}
                            invalid={emailError ? true : false}
                            disabled={viewState === "LOADING"}
                            helperText={emailError ? emailError.value : undefined}
                        />

                        {/** E-mail 2 */}
                        <DA_Input
                            id="repeatEmail"
                            type="email"
                            autoComplete="email"
                            label={`${getDictionaryString("repeat email address")}*`}
                            className={S.inputWrapper}
                            onChange={() => onInputChange("repeatEmail")}
                            defaultValue={customerDetails?.email}
                            invalid={repeatEmailError ? true : false}
                            disabled={viewState === "LOADING"}
                            helperText={repeatEmailError ? repeatEmailError.value : undefined}
                        />
                    </div>

                    <div className={S.split}>
                        {/** Phone */}
                        <DA_Input
                            id="phone"
                            type="tel"
                            autoComplete="tel"
                            label={`${getDictionaryString("phone")}*`}
                            className={S.inputWrapper}
                            onChange={() => onInputChange("phone")}
                            defaultValue={customerDetails?.phone}
                            invalid={phoneError ? true : false}
                            disabled={viewState === "LOADING"}
                            helperText={phoneError ? phoneError.value : undefined}
                        />

                        <div className={cn(S.inputWrapper, S.headerSpacer)}>&nbsp;</div>
                    </div>

                    <span className={S.contentSpacer}>
                        *{getDictionaryString("mandatory field")}
                    </span>
                </div>
            </section>

            <div className={S.buttons}>
                {!isMobile ? (
                    <DA_Button
                        href="/cart"
                        title={getDictionaryString("back to cart")}
                        disabled={viewState === "LOADING"}
                        isGhost
                    />
                ) : null}

                <DA_Button
                    title={getDictionaryString("continue checkout")}
                    disabled={viewState === "LOADING"}
                    showSpinner={viewState === "LOADING"}
                    submit
                />
            </div>
        </form>
    );
};
