// hooks/useCartItem.ts
import React from "react";
import { getPriceWithoutVat } from "@organisms/CartList/Item/ScreenSizes/CartItemDesktop/helper/getPriceWithoutVat.helper";

export const useUpdateCartItem = (props) => {
    const normalizeNumberString = (value: string): number => {
        // Remove thousand separator (dot) and replace decimal comma with dot
        const normalizedString = value.replace(/\./g, "").replace(/,/g, ".");
        return parseFloat(normalizedString);
    };

    const isPriceSameAsWithoutFeeAndCharges =
        React.isValidElement(props.priceData.price) ||
        React.isValidElement(props.priceData.withoutFeeAndChargesPrice)
            ? false // If it's a JSX element, we cannot compare directly
            : props.priceData.withoutFeeAndChargesPrice &&
              "priceWithoutVat" in props.priceData.withoutFeeAndChargesPrice &&
              !isNaN(normalizeNumberString(props.priceData.price as string))
            ? normalizeNumberString(props.priceData.price as string) ===
              props.priceData.withoutFeeAndChargesPrice.priceWithoutVat
            : false;

    const withoutFeeAndChargesPriceValue = getPriceWithoutVat(
        props?.priceData?.withoutFeeAndChargesPrice,

        isPriceSameAsWithoutFeeAndCharges
    );

    const feeAndChargesPriceValue = getPriceWithoutVat(props?.priceData?.feeAndChargesPrice);

    // Check if the values are JSX elements
    const isWithoutFeeAndChargesJSX = React.isValidElement(withoutFeeAndChargesPriceValue.price);
    const isFeeAndChargesJSX = React.isValidElement(feeAndChargesPriceValue.price);

    return {
        isWithoutFeeAndChargesJSX,
        isFeeAndChargesJSX,
        withoutFeeAndChargesPriceValue,
        feeAndChargesPriceValue,
    };
};
