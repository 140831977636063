import React, { useCallback } from "react";
import { useMeasure } from "react-use";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { BottomSheetOptions, useBottomSheet } from "../../contexts/bottomSheet/bottomSheet.context";
import { ModalOptions, useModal } from "../../contexts/modal.context";
import { useScreen } from "../../contexts/screen.context";
import { DA_Modal } from "../../components/molecules/Modal/Modal.component";
import S from "./useDynamicOverlay.module.scss";

type OverlayData = {
    id: string;
    title: React.ReactNode;
    content: React.ReactNode;
    closeButtonToBack?: boolean;
    hideCloseButton?: boolean;
    hideBackButton?: boolean;
};

export const DA_DynamicOverlayWrapper = (props: {
    content: React.ReactNode;
    footer: React.ReactNode;
    wrapper?: (children: React.ReactNode) => JSX.Element;
}) => {
    const [footerRef, footerRect] = useMeasure<HTMLDivElement>();
    const { isMobileOrTablet } = useScreen();
    const wrapper =
        props.wrapper ||
        ((children: React.ReactNode) => <div data-body-scroll-lock-ignore="true">{children}</div>);
    return wrapper(
        <>
            <div style={{ paddingBottom: isMobileOrTablet ? footerRect.bottom : 0 }}>
                {props.content}
            </div>
            {props.footer ? (
                <div ref={footerRef} className={cn(isMobileOrTablet && S.footer)}>
                    {props.footer}
                </div>
            ) : null}
        </>
    );
};

export const useDynamicOverlay = () => {
    const { isMobileOrTablet } = useScreen();
    const { showModal, closeModal } = useModal();
    const { presentBottomSheet, dismissBottomSheet } = useBottomSheet();

    const showOverlay = useCallback(
        (
            data: OverlayData,
            options?: { modal?: ModalOptions; bottomSheet?: Omit<BottomSheetOptions, "id"> }
        ) => {
            if (isMobileOrTablet) {
                presentBottomSheet(data.content, {
                    id: data.id,
                    headerTitle: data.title,
                    contentPadding: true,
                    nestedSheet: true,
                    closeButtonToBack: data.closeButtonToBack,
                    hideCloseButton: data.hideCloseButton,
                    hideBackButton: data.hideBackButton,
                    ...options?.bottomSheet,
                });
            } else {
                showModal(<DA_Modal title={data.title}>{data.content}</DA_Modal>, options?.modal);
            }
        },
        [isMobileOrTablet, presentBottomSheet, showModal]
    );

    const closeOverlay = useCallback(() => {
        if (typeof closeModal !== "undefined") {
            closeModal();
        }

        if (typeof dismissBottomSheet !== "undefined") {
            dismissBottomSheet();
        }
    }, [closeModal, dismissBottomSheet]);

    return { showOverlay, closeOverlay };
};
