import React from "react";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import S from "./UspItem.module.scss";

export interface DA_UspItemProps {
    icon: DA_IconNames;
    text: string;
    subText?: string;
}

export const DA_UspItem = (props: DA_UspItemProps & { className?: string }) => (
    <article className={cn(S.container, props.className)}>
        <div className={S.iconWrapper}>
            <DA_Icon name={props.icon} className={S.icon} />
        </div>

        <div className={S.textWrapper}>
            <div className={S.text}>
                <span className={S.uspTitle}>
                    <DA_Text noPadding html={props.text} />
                </span>
                {props.subText && <DA_Text smallText noPadding html={props.subText} />}
            </div>
        </div>
    </article>
);
