import { useCallback, useEffect, useRef, useState } from "react";
import { DA_ProductPrice } from "@danishagro/shared/src/interfaces/price.interface";
import { useAppData } from "../contexts/appData.context";
import { useRequest } from "./useRequest.hook";

const createFingerprint = (customerNumber, items) => {
    const prefix = `${customerNumber}--`;
    const itemsString = items.map(({ productNumber }) => productNumber).join("|");
    return `${prefix}${itemsString}`;
};

type ProductsPriceItem = { productNumber: string };
type ReturnType<T> = T extends ProductsPriceItem[] ? DA_ProductPrice[] : DA_ProductPrice;

export const useProductPrices = <P extends ProductsPriceItem | ProductsPriceItem[]>(
    items: P,
    getDetails = false
): ReturnType<P> => {
    const [priceData, setPriceData] = useState<(DA_ProductPrice | undefined)[]>([]);

    const { customerNumber, showPrices } = useAppData();
    const request = useRequest();

    const pricesCount = useRef(0);
    const fingerprint = useRef("");
    const controllerRef = useRef<AbortController | null>();

    const getProductListPrices = useCallback(
        (customerNumber, products, options?) =>
            request
                .post(
                    `prices/getlistprices/${customerNumber}`,
                    products.filter(({ productNumber }) => productNumber),
                    options
                )
                .then((response) => response.json())
                .then(({ productPrices }) => productPrices),
        [request]
    );

    const getProductDetailsPrices = useCallback(
        (customerNumber, products, options?) =>
            request
                .post(
                    `prices/getproductprices/${customerNumber}`,
                    products.filter(({ productNumber }) => productNumber),
                    options
                )
                .then((response) => response.json())
                .then(({ productPrices }) => productPrices),
        [request]
    );

    useEffect(() => {
        if (!showPrices) {
            return undefined;
        }

        // Make sure items are in an array
        const productItems = Array.isArray(items) ? items : [items];
        // Create fingerprint and match with previous fingerprint to find out
        // if it's necessary to reset the prices (if the list of products has changed)
        const newFingerprint = createFingerprint(customerNumber, productItems);
        const isResetNecessary = !newFingerprint.startsWith(fingerprint.current);
        // Find out if this is a request for more prices
        // (probably due to loading more products on scroll)
        const isRequestForMore =
            !isResetNecessary && newFingerprint.length > fingerprint.current.length;

        if (isResetNecessary) {
            // Reset prices immediately to avoid showing old prices
            setPriceData([]);
        }

        if (
            (isResetNecessary || isRequestForMore) &&
            productItems.length &&
            newFingerprint !== fingerprint.current
        ) {
            if (controllerRef.current) {
                // If a request has already been sent to the API,
                // abort it before sending a new one
                controllerRef.current.abort();
            }

            // Save fingerprint and create new AbortController
            fingerprint.current = newFingerprint;
            controllerRef.current = new AbortController();

            // Isolate the products that we haven't already received prices for
            const productsWithoutPriceData = productItems
                .slice(isResetNecessary ? 0 : pricesCount.current)
                .map(({ productNumber, retailVariantId }) => ({
                    productNumber,
                    variantId: retailVariantId || undefined,
                }));

            // Request the API
            (getDetails ? getProductDetailsPrices : getProductListPrices)(
                customerNumber,
                productsWithoutPriceData,
                {
                    signal: controllerRef.current.signal,
                }
            )
                .then((response) => {
                    // Map the received prices with the product numbers from the request.
                    // If a price is missing, insert an empty price object.
                    const newPriceData = productsWithoutPriceData.map(
                        ({ productNumber: productNumberTarget }) => {
                            const { productNumber, priceWithVat, priceWithoutVat, currency } =
                                response.find(
                                    ({ productNumber }) => productNumber === productNumberTarget
                                ) || {
                                    productNumber: productNumberTarget,
                                    priceWithVat: 0,
                                    priceWithoutVat: 0,
                                    currency: "",
                                };

                            return {
                                productNumber,
                                priceWithVat,
                                priceWithoutVat,
                                currency,
                            };
                        }
                    );

                    // Save the new prices and update price count
                    setPriceData((current) => {
                        const newValue = [...(isResetNecessary ? [] : current), ...newPriceData];
                        pricesCount.current = newValue.length;
                        return newValue;
                    });
                    controllerRef.current = null;
                })
                .catch((err) => {
                    // Ignore abort errors, since they only occur
                    // when we initiate them ourselves (a few lines up)
                    if (err?.name !== "AbortError") {
                        console.log(err);
                        setPriceData((current) => {
                            const newValue = [
                                ...(isResetNecessary ? [] : current),
                                ...productsWithoutPriceData.map(() => undefined),
                            ];
                            pricesCount.current = newValue.length;
                            return newValue;
                        });
                        controllerRef.current = null;
                    }
                });
        }
    }, [
        items,
        getDetails,
        getProductListPrices,
        getProductDetailsPrices,
        customerNumber,
        showPrices,
    ]);

    return (Array.isArray(items) ? priceData : priceData[0]) as ReturnType<P>;
};
