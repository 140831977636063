import { ShippingInformationFormData } from "../interfaces/shippingInformationFormData.interface";

interface DeliveryArgs {
    billingAddress: {
        streetName: string;
        streetNumber: string;
        zipCode: string;
        city: string;
    };
    shippingAddress: {
        id: string;
        streetName: string;
        streetNumber: string;
        zipCode: string;
        city: string;
    };
    shippingSameAsBilling: boolean;
}

export const formatFormBody = (body: ShippingInformationFormData) => {
    const result = {} as DeliveryArgs;

    if (body) {
        result.billingAddress = {
            streetName: body.billing_streetName,
            streetNumber: body.billing_streetNumber,
            zipCode: body.billing_zipCode,
            city: body.billing_city,
        };

        result.shippingAddress = {
            id: undefined,
            streetName: body.shipping_streetName,
            streetNumber: body.shipping_streetNumber,
            zipCode: body.shipping_zipCode,
            city: body.shipping_city,
        };

        if (body.shippingSameAsBilling === "true") {
            result.billingAddress = {
                streetName: body.shipping_streetName,
                streetNumber: body.shipping_streetNumber,
                zipCode: body.shipping_zipCode,
                city: body.shipping_city,
            };
        }

        result.shippingSameAsBilling = body.shippingSameAsBilling === "true" ? true : false;
    }

    return result;
};
