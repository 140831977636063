import React from "react";
import { Helmet } from "react-helmet";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_PageNotFound } from "@danishagro/shared/src/components/organisms/PageNotFound/PageNotFound.component";

export const DA_NotFoundPage = () => {
    const { getDictionaryString, globalTranslations } = useTranslations();

    const pageTitle = globalThis.showServerError
        ? "500 - Serverfejl"
        : `${getDictionaryString("page not found page-title")} | ${
              globalTranslations.pageTitlePostfix
          }`;

    return (
        <DA_Container noVerticalPadding>
            <Helmet>
                <title>
                    {pageTitle} | {globalTranslations.pageTitlePostfix}
                </title>
            </Helmet>

            <DA_PageNotFound code={globalThis.showServerError ? 500 : 404} />
        </DA_Container>
    );
};
