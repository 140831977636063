import { useCallback, useEffect, useMemo, useState } from "react";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";

export const useFrequencies = () => {
    const { getDictionaryString } = useTranslations();
    const { getFarmInTimeFrequencies } = useFarmInTimeApi();

    const [frequencies, setFrequencies] = useState<string[]>();
    const [viewState, setViewState] = useState("LOADING");

    // Mappers

    const frequencyOptions = useMemo(() => {
        const result: { label: string; value: string; extra: string }[] = [];

        frequencies?.forEach((frequency) => {
            result.push({
                value: frequency,
                label: getDictionaryString(frequency),
                extra: getDictionaryString(`helperText${frequency}`),
            });
        });

        return result;
    }, [frequencies, getDictionaryString]);

    // Fetch frequencies

    const fetchFrequencies = useCallback(async () => {
        const response = await getFarmInTimeFrequencies();

        if (response?.[0]) {
            setFrequencies(response);
        }

        return response;
    }, [getFarmInTimeFrequencies]);

    // Init

    useEffect(() => {
        fetchFrequencies()
            .then((response) => {
                if (response && response.length > 0) {
                    setViewState("DONE");
                } else {
                    setViewState("ERROR");
                }
            })
            .catch(() => {
                setViewState("ERROR");
            });
    }, [fetchFrequencies]);

    return {
        frequencies,
        frequencyOptions,
        viewState,
    };
};
