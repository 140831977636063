import React, { useCallback, useMemo, useRef, useState } from "react";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { animated, Transition } from "react-spring";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_ViewStates } from "@danishagro/shared/src/content/viewState/viewStates";
import { DA_FarmInTimeSettingsProps } from "./Settings.interface";
import { DA_NotificationServiceButton } from "./components/NotificationServiceButton/NotificationServiceButton.component";
import { DA_DriverMessageButton } from "./components/DriverMessageButton/DriverMessageButton.component";
import { useProducts } from "./hooks/useProducts.hook";
import { useMonths } from "./hooks/useMonths.hook";
import { useFrequencies } from "./hooks/useFrequencies.hook";
import { ProductItem } from "./components/ProductItem/ProductItem.component";
import { ProductItemProps } from "./components/ProductItem/ProductItem.interface";
import S from "./Settings.module.scss";

export const DA_FarmInTimeSettings = (props: DA_FarmInTimeSettingsProps) => {
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
    const { getDictionaryString } = useTranslations();
    const { scrollTo } = useScreen();
    const readMoreSectionRef = useRef<HTMLDivElement>();

    const { frequencyOptions, viewState: frequencyViewState } = useFrequencies();

    const { months, monthOptions, viewState: monthsViewState } = useMonths();
    const {
        products,
        prices,
        viewState: productViewState,
        onItemUpdated,
    } = useProducts(props.currentAddress);

    const scrollToReadMore = useCallback(() => {
        scrollTo(readMoreSectionRef.current.getBoundingClientRect().y);
    }, [scrollTo]);

    const viewState = useMemo(() => {
        const viewStates = [frequencyViewState, monthsViewState, productViewState];

        if (viewStates.includes(DA_ViewStates.LOADING)) {
            return DA_ViewStates.LOADING;
        } else if (viewStates.includes(DA_ViewStates.EMPTY)) {
            return DA_ViewStates.EMPTY;
        } else if (viewStates.includes(DA_ViewStates.ERROR)) {
            return DA_ViewStates.ERROR;
        } else {
            return DA_ViewStates.DONE;
        }
    }, [frequencyViewState, monthsViewState, productViewState]);

    return (
        <div>
            <div className={S.actions}>
                <DA_NotificationServiceButton currentAddress={props.currentAddress} />
                <DA_DriverMessageButton currentAddress={props.currentAddress} />
                <DA_ButtonLink
                    title={getDictionaryString("FarmInTimeSettingsReadMore")}
                    onClick={scrollToReadMore}
                    className={S.readMoreButton}
                />
            </div>

            <DA_ContentBox noPadding>
                <div className={S.header} aria-hidden={true}>
                    <div className={S.headerItem}>
                        {getDictionaryString("FarmInTimeSettingsTableName")}
                    </div>
                    <div className={S.headerItem}>
                        {getDictionaryString("FarmInTimeSettingsTableFrequency")}
                    </div>
                    <div className={S.headerItem}>
                        {getDictionaryString("FarmInTimeSettingsTableAmount")}
                    </div>
                    <div className={S.headerItem}>
                        {getDictionaryString("FarmInTimeSettingsTablePrice")}
                    </div>
                </div>

                {/** Done */}
                {viewState === DA_ViewStates.DONE && (
                    <Transition
                        items={products}
                        from={{ opacity: 1 }}
                        leave={{ opacity: 0 }}
                        keys={(item) => item.farmInTimeId}
                    >
                        {(style, item: ProductItemProps, _state, index) => (
                            <animated.div style={style}>
                                <ProductItem
                                    key={item.farmInTimeId}
                                    className={S.item}
                                    frequencyOptions={frequencyOptions}
                                    months={months}
                                    monthOptions={monthOptions}
                                    currentAddress={props.currentAddress}
                                    onItemUpdate={onItemUpdated}
                                    {...item}
                                    price={prices?.[index]}
                                />
                            </animated.div>
                        )}
                    </Transition>
                )}

                {/** Loading */}
                {viewState === DA_ViewStates.LOADING && (
                    <DA_EmptyState
                        type={DA_EmptyStateTypes.Loading}
                        title={getDictionaryString("FarmInTimeSettingsListLoadingHeading")}
                        iconOnly
                    />
                )}

                {/** Empty */}
                {viewState === DA_ViewStates.EMPTY && (
                    <DA_EmptyState
                        type={DA_EmptyStateTypes.NoContent}
                        title={getDictionaryString("FarmInTimeSettingsListEmptyHeading")}
                        text={getDictionaryString("FarmInTimeSettingsListEmptyTagline")}
                    />
                )}

                {/** Error */}
                {viewState === DA_ViewStates.ERROR && (
                    <DA_EmptyState
                        type={DA_EmptyStateTypes.Error}
                        title={getDictionaryString("FarmInTimeSettingsListErrorHeading")}
                        text={getDictionaryString("FarmInTimeSettingsListErrorTagline")}
                    />
                )}
            </DA_ContentBox>

            <section ref={readMoreSectionRef}>
                <DA_Title fullWidth h5 inheritColor>
                    {getDictionaryString("FarmInTimeSettingsHeading")}
                </DA_Title>

                <DA_Text
                    noPadding
                    html={
                        isDescriptionExpanded
                            ? getDictionaryString("FarmInTimeSettingsDescription")
                            : getDictionaryString("FarmInTimeSettingsDescriptionShort")
                    }
                />

                <DA_ButtonLink
                    className={S.expandButton}
                    title={getDictionaryString(
                        isDescriptionExpanded
                            ? "FarmInTimeSettingsDescriptionLess"
                            : "FarmInTimeSettingsDescriptionMore"
                    )}
                    onClick={() => setIsDescriptionExpanded((prev) => !prev)}
                />
            </section>
        </div>
    );
};
