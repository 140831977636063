import React, { FormEvent, useCallback, useState } from "react";
import { DA_Alert } from "@danishagro/shared/src/components/atoms/Alert/Alert.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { DA_TextArea } from "@danishagro/shared/src/components/atoms/TextArea/TextArea.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_ViewStates } from "@danishagro/shared/src/content/viewState/viewStates";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import S from "./DriverMessageModal.module.scss";

export interface DriverMessageModalProps {
    productId?: string;
    variantId?: string;
    lineId?: string;
    message?: string;
    customerNumber?: string;
    orderSecret?: string;

    onSubmit: (updatedMessage: string) => void;
    onCancel: () => void;
}

export const DriverMessageModal = (props: DriverMessageModalProps) => {
    const { getDictionaryString } = useTranslations();
    const { updateOrderlineDriverMessage } = useCart();
    const [viewState, setViewState] = useState<DA_ViewStates>(DA_ViewStates.INIT);

    const onFormSubmitted = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event?.preventDefault();

            const form = new FormData(event.target as HTMLFormElement);
            const body = {} as { driverMessage: string };

            form.forEach((value, key) => (body[key] = value));

            setViewState(DA_ViewStates.SAVING);

            try {
                const didSucceed = await updateOrderlineDriverMessage({
                    lineId: props.lineId,
                    driverMessage: body.driverMessage,
                });

                if (didSucceed) {
                    props.onSubmit(body?.driverMessage);
                } else {
                    throw new Error("Failed to update driver message");
                }
            } catch (error) {
                console.error(error); // Log the error for debugging purposes
                setViewState(DA_ViewStates.ERROR);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [props, updateOrderlineDriverMessage]
    );

    const onCancelButtonClicked = useCallback(() => {
        props.onCancel();
    }, [props]);

    return (
        <DA_Modal title={getDictionaryString("DriverMessageModalHeading")}>
            <form noValidate onSubmit={onFormSubmitted}>
                <div className={S.content}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getDictionaryString("CartDriverMessageModalDescription"),
                        }}
                    />

                    {/** DriverMessage */}
                    <DA_TextArea
                        autoComplete="off"
                        name="driverMessage"
                        className={S.inputWrapper}
                        inputClassName={S.input}
                        defaultValue={props?.message}
                        id="driverMessage"
                    />
                </div>

                <div className={S.buttons}>
                    {/** Submit */}
                    <DA_Button
                        title={getDictionaryString("DriverMessageModalSubmit")}
                        disabled={viewState === DA_ViewStates.SAVING}
                        showSpinner={viewState === DA_ViewStates.SAVING}
                        submit
                    />

                    {/** Cancel */}
                    <DA_Button
                        title={getDictionaryString("DriverMessageModalCancel")}
                        onClick={onCancelButtonClicked}
                        isGhost
                    />
                </div>
            </form>

            {/** Server error */}
            {viewState === DA_ViewStates.ERROR && (
                <div className={S.error}>
                    <DA_Alert
                        content={getDictionaryString("DriverMessageModalError")}
                        type="error"
                        hideCloseBtn
                    />
                </div>
            )}
        </DA_Modal>
    );
};
