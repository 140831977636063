import React, { useRef, useState } from "react";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import S from "./VideoPlayerViewer.module.scss";

interface VideoPlayerViewerProps {
    className?: string;
    video: {
        alias: string;
        url: string;
        autoplay?: boolean;
        controls?: boolean;
        muted?: boolean;
        loop?: boolean;
    };
    posterStart?: {
        url: string;
        width?: number;
        height?: number;
        alt?: string;
    };
}

export const DA_VideoPlayerViewer = (props: VideoPlayerViewerProps) => {
    const { isMobile } = useScreen();

    const {
        className,
        video: { url, autoplay = false, controls = true, muted = false },
        posterStart,
    } = props;
    const [isVideoReady, setIsVideoReady] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleVideoLoaded = () => {
        setIsVideoReady(true); // Mark the video as ready when metadata or data is loaded
    };

    return (
        <div className={`${className} ${S.videoInner}`}>
            {/* Always render video off-screen or hidden to allow it to load */}
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                ref={videoRef}
                src={url}
                controls={controls}
                muted={autoplay ? true : muted} // Ensure muted is true for autoplay
                autoPlay={autoplay}
                loop={true}
                playsInline
                onCanPlay={handleVideoLoaded} // Trigger when the video metadata is ready
                style={{
                    display: isVideoReady ? "block" : "none", // Hide video until it's ready
                    width: !isMobile ? "100%" : undefined,
                    height: "auto",
                }}
            />

            {/* Show the poster until the video is ready */}
            {!isVideoReady && posterStart ? (
                <div className={S.posterOverlay}>
                    <img
                        src={posterStart.url}
                        alt={posterStart.alt || "Video poster"}
                        className={S.posterImage}
                    />
                </div>
            ) : null}
        </div>
    );
};
