import { useEffect } from "react";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { DA_VetMedication } from "@danishagro/shared/src/interfaces/vetMedication.interface";

export const useVetMedicationLink = (vetMedication: DA_VetMedication) => {
    const { setVetMedication } = usePage();
    useEffect(() => {
        setVetMedication(vetMedication);
        return () => setVetMedication(undefined);
    }, [setVetMedication, vetMedication]);
    return setVetMedication;
};
