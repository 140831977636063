import React, { useCallback, useEffect, useRef, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { DA_ViewStates } from "@danishagro/shared/src/content/viewState/viewStates";
import { DriverMessageModal } from "../DriverMessageModal/DriverMessageModal.component";
import { DA_DriverMessageBottomSheet } from "../DriverMessageBottomSheet/DriverMessageBottomSheet.component";
import { DriverMessageButtonProps } from "./DriverMessageButton.interface";

export const DA_DriverMessageButton = (props: DriverMessageButtonProps) => {
    const { getDictionaryString } = useTranslations();
    const { showModal, closeModal } = useModal();
    const { customerNumber } = useAppData();
    const { isMobileOrTablet } = useScreen();
    const { presentBottomSheet, dismissBottomSheet } = useBottomSheet();
    const { getFarmInTimeDriverMessageByAddress } = useFarmInTimeApi();
    const [hasDriverMessage, setHasDriverMessage] = useState(false);

    const [viewState, setViewState] = useState<DA_ViewStates>(DA_ViewStates.NONE);

    const existingMessageRef = useRef<string>();

    const checkForDriverMessage = useCallback(() => {
        getFarmInTimeDriverMessageByAddress(customerNumber, props.currentAddress?.id).then(
            (response) => {
                if (typeof response?.message !== "undefined" && response.message !== "") {
                    setHasDriverMessage(true);
                } else setHasDriverMessage(false);
            }
        );
    }, [customerNumber, getFarmInTimeDriverMessageByAddress, props.currentAddress?.id]);

    const onModalSubmitted = useCallback(
        (updatedMessage: string) => {
            if (updatedMessage !== existingMessageRef.current) {
                setViewState(DA_ViewStates.SAVED);

                setTimeout(() => {
                    setViewState(DA_ViewStates.NONE);
                }, 3000);
            }
            checkForDriverMessage();
            closeModal();
            dismissBottomSheet();
        },
        [closeModal, dismissBottomSheet, checkForDriverMessage]
    );

    const onModalCancelled = useCallback(() => {
        dismissBottomSheet();
        closeModal();
    }, [dismissBottomSheet, closeModal]);

    useEffect(() => {
        checkForDriverMessage();
    }, [
        customerNumber,
        getFarmInTimeDriverMessageByAddress,
        props.currentAddress?.id,
        checkForDriverMessage,
    ]);

    const onButtonClicked = useCallback(async () => {
        setViewState(DA_ViewStates.LOADING);

        let existingMessage = undefined;

        try {
            existingMessage = await getFarmInTimeDriverMessageByAddress(
                customerNumber,
                props.currentAddress?.id
            );
            existingMessageRef.current = existingMessage?.message;
        } catch {
            /* empty */
        }

        if (isMobileOrTablet) {
            presentBottomSheet(
                <DA_DriverMessageBottomSheet
                    onCancel={onModalCancelled}
                    onSubmit={onModalSubmitted}
                    message={existingMessage?.message}
                    currentAddress={props.currentAddress}
                />,
                {
                    id: "drivermesage-bottom-sheet",
                    contentPadding: true,
                    headerTitle: getDictionaryString("DriverMessageModalHeading"),
                    snapPoints: ({ maxHeight }) => [maxHeight],
                }
            );
        } else {
            showModal(
                <DriverMessageModal
                    message={existingMessage?.message}
                    currentAddress={props.currentAddress}
                    onSubmit={onModalSubmitted}
                    onCancel={onModalCancelled}
                />,
                {
                    size: ModalSize.LG,
                }
            );
        }

        setViewState(DA_ViewStates.NONE);
    }, [
        customerNumber,
        getDictionaryString,
        getFarmInTimeDriverMessageByAddress,
        isMobileOrTablet,
        onModalCancelled,
        onModalSubmitted,
        presentBottomSheet,
        props.currentAddress,
        showModal,
    ]);

    return (
        <DA_Button
            onClick={onButtonClicked}
            title={
                hasDriverMessage
                    ? getDictionaryString("FarmInTimeDriverMessageButtonEdit")
                    : getDictionaryString("FarmInTimeDriverMessageButton")
            }
            isGhost={true}
            showSpinner={viewState === DA_ViewStates.LOADING}
            icon={viewState === DA_ViewStates.SAVED && DA_IconNames.Checkmark}
        />
    );
};
