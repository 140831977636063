import React, { useRef, useState } from "react";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { DA_ArrowButton, DA_arrowDirection } from "@molecules/ArrowButton/ArrowButton.component";
import { DA_ImageLink, DA_ImageLinkProps } from "@molecules/ImageLink/ImageLink.component";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass, SwiperOptions } from "swiper/types";
import "@danishagro/shared/src/helpers/swiperInitialization.helper";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import S from "./CategorySlider.module.scss";

export interface V1Props {
    title: string;
    items: DA_ImageLinkProps[];
}

export const DA_CategorySlider = ({ title, items }: V1Props) => {
    const [slidesPerView, setSlidesPerView] = useState(0);
    const [showPreviousButton, setShowPreviousButton] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const sliderRef = useRef<SwiperClass>(null);
    const { getDictionaryString } = useTranslations();

    const onSlideChange = (swiper: SwiperClass) => {
        setShowPreviousButton(swiper.realIndex > 0);
        setShowNextButton(swiper.realIndex < items.length - slidesPerView);
    };

    const onBreakPointChange = (swiper: SwiperClass, breakpoint: SwiperOptions) => {
        setSlidesPerView(breakpoint.slidesPerView as number);

        // Reset slider when breakpoint changes
        // because Swiper can't figure out when to show/hide buttons
        swiper.slideTo(0);
        setShowPreviousButton(false);
        setShowNextButton(items.length > (breakpoint.slidesPerView as number));
    };

    return items?.[0] ? (
        <DA_Container noOverflow>
            <div className={S.sliderTopWrapper}>
                <DA_Title h2>{title}</DA_Title>
            </div>

            <div className={S.swiperWrapper}>
                <div className={S.swiperWrapperInner}>
                    <div className={S.navControl}>
                        {items.length >= slidesPerView && (
                            <>
                                <div
                                    className={cn(
                                        S.navigationWrapper,
                                        S.previous,
                                        showPreviousButton && S.visible
                                    )}
                                >
                                    <DA_ArrowButton
                                        onCLick={() => sliderRef.current.slidePrev()}
                                        iconDirection={DA_arrowDirection.Left}
                                        className={S.buttonNav}
                                        disabled={!showPreviousButton}
                                        small
                                        title={getDictionaryString("accessibilityButtonPrev")}
                                        isGhost
                                    />
                                </div>

                                <div
                                    className={cn(
                                        S.navigationWrapper,
                                        S.next,
                                        showNextButton && S.visible
                                    )}
                                >
                                    <DA_ArrowButton
                                        onCLick={() => sliderRef.current.slideNext()}
                                        className={S.buttonNav}
                                        disabled={!showNextButton}
                                        small
                                        title={getDictionaryString("accessibilityButtonNext")}
                                        isGhost={true}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <Swiper
                        threshold={10}
                        slidesPerView={2}
                        spaceBetween={10}
                        onSlideChange={onSlideChange}
                        onReachBeginning={() => setShowPreviousButton(false)}
                        onReachEnd={() => setShowNextButton(false)}
                        onBreakpoint={onBreakPointChange}
                        onSwiper={(swiper) => (sliderRef.current = swiper)}
                        pagination={{
                            type: "progressbar",
                        }}
                        breakpoints={{
                            512: {
                                slidesPerView: 2.2,
                                spaceBetween: 22,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 22,
                            },
                            992: {
                                slidesPerView: 4,
                                spaceBetween: 22,
                            },
                        }}
                    >
                        {items.map((item, index) => (
                            <SwiperSlide key={index}>
                                <DA_ImageLink {...item} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </DA_Container>
    ) : null;
};
