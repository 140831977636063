import React from "react";
import { DA_Container } from "../../atoms/Container/Container.component";
import { DA_Alert } from "../../atoms/Alert/Alert.component";

export class DA_ErrorBoundary extends React.Component<{
    children: React.ReactNode;
    featureName: string;
    featureVersion: string;
    errorComponent?: React.ReactNode;
}> {
    state = {
        hasError: false,
    };

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // If a chunk couldn't be found - reload page
        if (error.name === "ChunkLoadError") return location.reload();

        // You can also log the error to an error reporting service
        console.log("// Start error with feature", this.props.featureName);
        console.log(error);
        console.log(errorInfo);
        console.log("// End error with feature", this.props.featureName);
    }

    render() {
        if (this.state.hasError) {
            return (
                this.props.errorComponent || (
                    <DA_Container>
                        <DA_Alert
                            type="error"
                            content={
                                <>
                                    <span style={{ userSelect: "none" }}>Error in feature: </span>
                                    <b>{this.props.featureName}</b>
                                    <span style={{ userSelect: "none" }}>
                                        , {this.props.featureVersion}
                                    </span>
                                </>
                            }
                        />
                    </DA_Container>
                )
            );
        }

        return this.props.children;
    }
}
