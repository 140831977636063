import React, { useState } from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "../Icon/Icon.component";
import S from "./Alert.module.scss";

export interface DA_AlertProps {
    content: React.ReactNode;
    type?: "error" | "warning" | "info" | "success";
    size?: "normal" | "small";
    icon?: DA_IconNames | false;
    shadow?: boolean;
    hideCloseBtn?: boolean;
}

export const DA_Alert = ({ type, ...props }: DA_AlertProps) => {
    const [hidden, setHidden] = useState(false);

    const alertClass = cn(
        S.alert,
        props.size === "small" && S.small,
        type === "error" && S.error,
        type === "warning" && S.warning,
        type === "info" && S.info,
        type === "success" && S.success
    );

    const iconName =
        typeof props.icon !== "undefined"
            ? props.icon
            : type === "success"
            ? DA_IconNames.Checkmark
            : type === "warning"
            ? DA_IconNames.WarningSign
            : type === "error"
            ? DA_IconNames.WarningSign
            : DA_IconNames.Info;

    return hidden ? null : (
        <div className={cn(alertClass, props.shadow && S.shadow)}>
            {iconName && <DA_Icon name={iconName} className={cn(S.icon)} />}
            <div className={S.alertText}>{props.content}</div>

            {props.hideCloseBtn || (
                <button onClick={() => setHidden(true)} className={S.close}>
                    <DA_Icon name={DA_IconNames.Close} />
                </button>
            )}
        </div>
    );
};
