import React from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import S from "./ProductTag.module.scss";

export interface DA_ProductTagProps {
    icon: DA_IconNames;
    size?: "small" | "medium";
    color: "green" | "blue";
    children: string;
}

export const DA_ProductTag = (props: DA_ProductTagProps) => {
    const sizeClass = props.size === "small" ? S.small : undefined;
    const colorClass = props.color === "blue" ? S.blue : S.green;

    return (
        <span className={cn(S.tag, sizeClass, colorClass)}>
            <DA_Icon name={props.icon} className={S.icon} />
            {props.children}
        </span>
    );
};
