import React, { useCallback, useMemo, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Image } from "@danishagro/shared/src/components/atoms/Image/Image.component";
import { DA_InputWrapper } from "@danishagro/shared/src/components/atoms/InputWrapper/InputWrapper.component";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { DA_QuantityPicker } from "@molecules/QuantityPicker/QuantityPicker.component";
import { DA_Select } from "@danishagro/shared/src/components/atoms/Select/Select.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import toast from "react-hot-toast";
import { DA_WishListToast } from "@molecules/WishListButton/WishListToast/WishListToast.component";
import { useAddressFromSessionStorage } from "@danishagro/shared/src/hooks/useAddressFromSessionStorage.hook";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { pushToDataLayer } from "@danishagro/shared/src/gtm/useTagManger";
import { B2bImageSrc } from "@danishagro/shared/src/helpers/imageSrc.helper";
import { ImageConfig } from "@danishagro/shared/src/content/imageConfigs/imageConfig.enum";
import { DA_Tag } from "@danishagro/shared/src/components/atoms/Tag/Tag.component";
import { isGreaterThanZero } from "@danishagro/shared/src/helpers/greaterThanZero.helper";
import { useDeliveryAddress } from "../../modals/hooks/useDeliveryAddress.hook";
import S from "./OrderListModal.module.scss";

interface OrderListModalProps {
    productId: string;
    productName: string;
    productImage: string;
    variantId?: string;
    quantityOnPallet?: string;
}

export const _OrderListModal = (props: OrderListModalProps) => {
    const { getDictionaryString, getDictionaryItem } = useTranslations();
    const { closeModal } = useModal();
    const { customerNumber } = useAppData();
    const [quantity, setQuantity] = useState(1);
    const { isMobile } = useScreen();
    const { setOrderlistProductsV2 } = useFarmInTimeApi();
    const { saveDeliveryInfo } = useCart();

    console.log(props.quantityOnPallet);

    const {
        setAddressInSessionStorage,
        hasAddressInSessionStorage,
        currentAddressFromSessionStorage,
    } = useAddressFromSessionStorage();

    const {
        currentDeliveryAddressOption,
        deliveryAddressOptions,
        currentDeliveryAddress,
        onDeliveryAddressSelectChanged,
    } = useDeliveryAddress();

    const updateQuantity = useCallback((newQuantity: number) => {
        setQuantity(newQuantity);
    }, []);

    const addProductToOrderList = useCallback(
        (productId, quantity, variantId) => {
            setOrderlistProductsV2(customerNumber, currentDeliveryAddress?.id, {
                productId: productId,
                quantity: quantity,
                variantId: variantId,
            }).then(() => {
                toast.custom(() => (
                    <DA_WishListToast
                        heading={getDictionaryString("WishListToastOrderListHeading")}
                        href="/bestillingsliste"
                    />
                ));
                setAddressInSessionStorage(currentDeliveryAddress.id);
                // GTM Tracking
                pushToDataLayer({
                    event: "add_as_favorite",
                    product_name: props.productName,
                });
                closeModal();
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            setOrderlistProductsV2,
            customerNumber,
            currentDeliveryAddress,
            setAddressInSessionStorage,
            saveDeliveryInfo,
            closeModal,
            getDictionaryString,
        ]
    );

    const isFormReady = useMemo(
        () => typeof currentDeliveryAddress?.id !== "undefined",
        [currentDeliveryAddress]
    );

    return (
        <DA_Modal
            title={getDictionaryString("AddProductToOrderList", {
                uppercaseFirst: true,
            })}
        >
            <DA_Text>
                {getDictionaryString("OrderListModalText", {
                    uppercaseFirst: true,
                })}
            </DA_Text>

            {/** Delivery Address */}
            <DA_InputWrapper
                label={getDictionaryString("OrderListModalAddressLabel")}
                forId="add-product-to-orderlist"
                tooltip={getDictionaryString("OrderListModalTooltipText")}
            >
                <DA_Select
                    className={S.selectWrapper}
                    id="add-product-to-orderlist"
                    value={
                        hasAddressInSessionStorage
                            ? currentAddressFromSessionStorage
                            : currentDeliveryAddressOption?.value
                    }
                    options={deliveryAddressOptions}
                    onChange={onDeliveryAddressSelectChanged}
                />
            </DA_InputWrapper>

            {isMobile ? (
                <>
                    <div className={S.mobileWrapper}>
                        <div className={S.mobileTitle}>{getDictionaryString("product")}</div>
                        <div className={S.mobileImageNameWrapper}>
                            <DA_Image
                                key={props.productImage}
                                src={B2bImageSrc(props.productImage, ImageConfig.PdpThumbnail)}
                                alt={props.productName}
                                className={S.mobileImage}
                            />
                            <div className={S.mobileProductNameWrapper}>{props.productName}</div>
                        </div>
                    </div>

                    <div className={S.mobileQuantityPickerWrapper}>
                        <label className={S.mobileTitle} htmlFor="order-list-modal-quantity">
                            {getDictionaryString("amount")}
                        </label>
                        <DA_QuantityPicker
                            inputId="order-list-modal-quantity"
                            onChange={updateQuantity}
                            value={quantity}
                        />
                    </div>
                    <div className={S.labelTagWrapperMobile}>
                        {isGreaterThanZero(props.quantityOnPallet) && (
                            <div className={S.labelTagWrapper}>
                                <DA_Tag color="neutral" size="dynamic">
                                    {getDictionaryItem("quantityonpallettext", {
                                        quantity: props.quantityOnPallet,
                                    })}
                                </DA_Tag>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className={S.productWrapper}>
                    <div className={S.header} aria-hidden={true}>
                        <div className={S.headerItem}>{getDictionaryString("product")}</div>
                        <div className={S.headerItem}>{getDictionaryString("amount")}</div>
                    </div>
                    <div className={S.productInfoWrapper}>
                        <div className={S.imageWrapper}>
                            <DA_Image
                                key={props.productImage}
                                src={B2bImageSrc(props.productImage, ImageConfig.PdpThumbnail)}
                                alt=""
                                className={S.image}
                            />
                        </div>
                        <div className={S.productNameWrapper}>{props.productName}</div>
                        <div className={S.quantityQounterWrapper}>
                            <DA_QuantityPicker onChange={updateQuantity} value={quantity} />
                            {isGreaterThanZero(props.quantityOnPallet) && (
                                <div className={S.labelTagWrapper}>
                                    <DA_Tag color="neutral" size="dynamic">
                                        {getDictionaryItem("quantityonpallettext", {
                                            quantity: props.quantityOnPallet,
                                        })}
                                    </DA_Tag>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className={S.buttonWrapper}>
                <DA_Button
                    title={
                        isMobile
                            ? getDictionaryString("OrderListModalMobileSubmitButton")
                            : getDictionaryString("OrderListModalSubmitButton")
                    }
                    onClick={() =>
                        addProductToOrderList(props.productId, quantity, props.variantId)
                    }
                    className={S.primary}
                    disabled={!isFormReady}
                />
                <DA_Button
                    title={getDictionaryString("cancel")}
                    isGhost
                    onClick={closeModal}
                    className={S.secondary}
                />
            </div>
        </DA_Modal>
    );
};
