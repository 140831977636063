import React from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import S from "./EnlargeButton.module.scss";

export interface EnlargeButtonProps {
    onClick: (event: React.MouseEvent) => void;
}

export const DA_EnlargeButton = ({ onClick }: EnlargeButtonProps) => {
    const { getDictionaryString } = useTranslations();
    return (
        <button
            className={S.enlargeButton}
            onClick={onClick}
            title={getDictionaryString("enlarge")}
        >
            <DA_Icon name={DA_IconNames.Expand} className={S.iconSize} />
        </button>
    );
};
