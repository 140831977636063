import React, { useEffect, useMemo } from "react";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { DA_CartTotal } from "@organisms/CartTotal/CartTotal.component";
import { DA_CheckoutCartList } from "@templates/OneStepCheckout/components/CheckoutCartList/CheckoutCartList.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_SteppedCheckout } from "@templates/SteppedCheckout/SteppedCheckout.component";
import { useCart } from "@danishagro/shared/src/contexts/cart/cart.context";
import { useNavigate, useParams } from "react-router-dom";
import { DA_BreadcrumbLinkProps } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.interface";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { CheckoutSteps, SteppedCheckoutParams } from "@interfaces/checkoutParams.interface";
import S from "./SteppedCheckout.module.scss";

export const DA_SteppedCheckoutPage = () => {
    const { getDictionaryString } = useTranslations();
    const navigate = useNavigate();

    const { step } = useParams<SteppedCheckoutParams>();

    // Cart
    const { orderLines, priceData } = useCart();

    const breadcrumbItems = useMemo(
        () =>
            [
                {
                    alias: CheckoutSteps.Contact,
                    title: getDictionaryString("contact checkout step"),
                    isHighlighted: step === CheckoutSteps.Contact,
                    href: "/checkout/contact",
                },
                {
                    alias: CheckoutSteps.Shipping,
                    title: getDictionaryString("shipping checkout step"),
                    isHighlighted: step === CheckoutSteps.Shipping,
                    href: "/checkout/shipping",
                },
                {
                    alias: CheckoutSteps.Payment,
                    title: getDictionaryString("payment checkout step"),
                    isHighlighted: step === CheckoutSteps.Payment,
                    href: "/checkout/payment",
                },
                {
                    alias: CheckoutSteps.Receipt,
                    title: getDictionaryString("receipt checkout step"),
                    isHighlighted: step === CheckoutSteps.Receipt,
                },
            ] as DA_BreadcrumbLinkProps[],
        [getDictionaryString, step]
    );

    useEffect(() => {
        if (globalThis.pageContent?.pageAlias === "Error") {
            globalThis.pageContent = undefined;
            navigate("/");
        }
    }, [navigate]);

    return (
        <DA_Container>
            {/** Breadcrumbs */}
            <DA_Breadcrumb
                breadcrumbItems={breadcrumbItems}
                showNumberedList
                listClassName={S.list}
            />

            <div className={S.content}>
                {/** Checkout */}
                <main className={S.main} id="main">
                    <DA_SteppedCheckout currentStep={step} />
                </main>

                {/** Mini Cart */}
                <aside className={S.aside}>
                    <DA_CheckoutCartList items={orderLines} />

                    <DA_CartTotal prices={priceData} hideButtons={true} />
                </aside>
            </div>
        </DA_Container>
    );
};
