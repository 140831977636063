import React from "react";
import { Helmet } from "react-helmet";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { DA_ProductDetails } from "@templates/ProductDetails/ProductDetails.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_NewProduct } from "@danishagro/shared/src/interfaces/productv2.interface";
import { useProductPrices } from "@danishagro/shared/src/hooks/useProductPrices.hook";
import { useVetMedicationLink } from "@hooks/useVetMedicationLink.hook";

type DA_ProductPageProps = {
    pageData: DA_NewProduct;
};

export const DA_ProductPage = ({ pageData }: DA_ProductPageProps) => {
    const { currentPathDetails } = usePage();
    const { getDictionaryString } = useTranslations();

    const priceData = useProductPrices(
        {
            ...pageData,
            productNumber: pageData.masterProductNumber,
        },
        true
    );

    useVetMedicationLink(pageData.vetMedicationLabel);

    return (
        <DA_Container>
            <Helmet>
                <title>{pageData?.webName}</title>
                <meta name="description" content={pageData?.description} />
            </Helmet>

            <DA_Breadcrumb
                lastIsLink
                breadcrumbItems={[
                    {
                        title: getDictionaryString("webshop"),
                        href: "/",
                        id: globalThis.frontPageId,
                    },
                    ...currentPathDetails.map(({ name, url, path, categoryId }) => ({
                        title: name,
                        href: url || path,
                        id: categoryId.toString(),
                    })),
                ]}
            />

            <DA_ProductDetails {...pageData} price={priceData} />
        </DA_Container>
    );
};
