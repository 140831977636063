import React, {
    Dispatch,
    ReactNode,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_OrderListCartOrderLine } from "@danishagro/shared/src/interfaces/OrderListCartOrderLine.interface";
import { useFarmInTimeProducts } from "../hooks/useProducts.hook";

export interface SelectedProductsType {
    enabled: boolean;
    data: {
        farmInTimeList: { [key: string]: boolean };
        orderingList: { [key: string]: boolean };
    };
}

interface QuickSelectHook {
    selectedProducts: SelectedProductsType;
    setSelectedProducts: Dispatch<SetStateAction<SelectedProductsType>>;

    isAllChecked: { farmInTimeList: boolean; orderingList: boolean };
    toggleAllOfType: (type: string, value: boolean) => void;
    toggleItem: (type: string, farmInTimeOrderListProductId: number, value: boolean) => void;
    checkedCount: number;

    isQuickSelectEnabled: boolean;
}

type Props = {
    children: ReactNode;
    orderListProducts?: DA_OrderListCartOrderLine[];
    farmInTimeListProducts?: DA_OrderListCartOrderLine[];
};

const DA_FarmInTimeQuickSelectContext = createContext<QuickSelectHook>({
    selectedProducts: null,
    setSelectedProducts: () => null,

    isAllChecked: null,
    toggleAllOfType: () => null,
    toggleItem: () => null,
    checkedCount: null,

    isQuickSelectEnabled: false,
});

export const DA_FarmInTimeQuickSelectProvider = (props: Props) => {
    const { customerNumber } = useAppData();
    const { orderListProducts, farmInTimeListProducts } = useFarmInTimeProducts();

    const [selectedProducts, setSelectedProducts] = useState<SelectedProductsType>({
        enabled: false,
        data: { farmInTimeList: {}, orderingList: {} },
    });

    useEffect(() => {
        // Get selectedProducts from LocalStorage
        const savedSelectedProducts = JSON.parse(
            localStorage.getItem(`fit-quick-select:${customerNumber}`) || "{}"
        );
        setSelectedProducts(savedSelectedProducts);
    }, [customerNumber]);

    useEffect(() => {
        // TODO: We cannot check if there is any, because maybe there isnt
        if (farmInTimeListProducts?.[0] || orderListProducts?.[0]) {
            setSelectedProducts((prevState) => {
                const result = {};

                // Ordering List Products
                if (orderListProducts) {
                    const orderingList: { [key: string]: boolean } = {};

                    orderListProducts.forEach((product) => {
                        const id = product.farmInTimeOrderListProductId;
                        orderingList[id] = prevState?.data?.orderingList?.[id] ?? true;
                    });

                    result["orderingList"] = orderingList;
                }

                // Farm in Time List Products
                if (farmInTimeListProducts) {
                    const farmInTimeList: { [key: string]: boolean } = {};

                    farmInTimeListProducts.forEach((product) => {
                        const id = product.farmInTimeOrderListProductId;

                        // Wants to set it to the previous value, that value might be the local storage one.
                        // Defaults to false because customer wants it to be intuitive to select few.
                        farmInTimeList[id] = prevState?.data?.farmInTimeList?.[id] ?? true;
                    });

                    result["farmInTimeList"] = farmInTimeList;
                }

                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        ...result,
                    },
                };
            });
        }
    }, [customerNumber, farmInTimeListProducts, orderListProducts]);

    const toggleAllOfType = (type: string, value: boolean) => {
        setSelectedProducts((prevState) => {
            const list = prevState?.data?.[type];
            const keys = Object.keys(list ?? {});

            const items = keys.reduce((acc, key) => {
                acc[key] = value;
                return acc;
            }, {});

            const result = {
                ...prevState,
                data: {
                    ...prevState.data,
                    [type]: items,
                },
            };

            localStorage.setItem(`fit-quick-select:${customerNumber}`, JSON.stringify(result));

            return result;
        });
    };

    const toggleItem = (type: string, farmInTimeOrderListProductId: number, value: boolean) => {
        setSelectedProducts((prevState) => {
            const list = prevState?.data?.[type];
            const keys = Object.keys(list ?? {});

            const items = keys.reduce((acc, key) => {
                if (key.toString() === farmInTimeOrderListProductId.toString()) {
                    acc[key] = value;
                } else {
                    acc[key] = prevState?.data?.[type]?.[key] ?? true;
                }

                return acc;
            }, {});

            const result = {
                ...prevState,
                data: {
                    ...prevState?.data,
                    [type]: items,
                },
            };

            localStorage.setItem(`fit-quick-select:${customerNumber}`, JSON.stringify(result));

            return result;
        });
    };

    // Mappers

    const isQuickSelectEnabled = useMemo(() => {
        return selectedProducts.enabled ?? false;
    }, [selectedProducts.enabled]);

    const isAllChecked = useMemo(() => {
        return {
            orderingList: Object.values(selectedProducts?.data?.orderingList ?? {}).every(
                (value) => value === true
            ),
            farmInTimeList: Object.values(selectedProducts?.data?.farmInTimeList ?? {}).every(
                (value) => value === true
            ),
        };
    }, [selectedProducts]);

    const checkedCount = useMemo(() => {
        const orderingListTrueCount = Object.values(
            selectedProducts?.data?.orderingList ?? {}
        ).filter((value) => value === true).length;
        const farmInTimeListTrueCount = Object.values(
            selectedProducts?.data?.farmInTimeList ?? {}
        ).filter((value) => value === true).length;

        return orderingListTrueCount + farmInTimeListTrueCount;
    }, [selectedProducts]);

    return (
        <DA_FarmInTimeQuickSelectContext.Provider
            value={{
                selectedProducts,
                setSelectedProducts,
                toggleAllOfType,
                isAllChecked,
                toggleItem,
                checkedCount,
                isQuickSelectEnabled,
            }}
        >
            {props.children}
        </DA_FarmInTimeQuickSelectContext.Provider>
    );
};

export const useFarmInTimeQuickSelect = (): QuickSelectHook =>
    useContext(DA_FarmInTimeQuickSelectContext);
