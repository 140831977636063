import React, { useEffect, useState } from "react";
import {
    DA_SelectOption,
    DA_SelectProps,
} from "@danishagro/shared/src/components/atoms/Select/Select.props";
import {
    useDynamicOverlay,
    DA_DynamicOverlayWrapper,
} from "@danishagro/shared/src/hooks/dynamicOverlay/useDynamicOverlay.hook";
import { useConvertValue } from "@danishagro/shared/src/components/atoms/Select/hooks/useConvertValue.hook";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_DropdownArrow } from "@danishagro/shared/src/components/atoms/DropdownArrow/DropdownArrow.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import S from "./BottomSheetSelect.module.scss";

export const BottomSheetSelect = ({
    options,
    onChange,
    selectName,
    defaultValue,
    getValue,
    getLabel,
    ...restProps
}: DA_SelectProps) => {
    const [tempSelection, setTempSelection] = useState(null);
    const convertValue = useConvertValue({ options, getLabel, getValue });
    const initialOption = defaultValue ? convertValue(defaultValue) : null;

    const [selectedOption, setSelectedOption] = useState<DA_SelectOption | null>(initialOption);

    const [activeOverlayId, setActiveOverlayId] = useState<string | null>(null);

    const { getDictionaryString } = useTranslations();

    // Hooks for dynamic overlay
    const { showOverlay, closeOverlay } = useDynamicOverlay();
    const [overlayIsOpen, setOverlayIsOpen] = useState(false);

    const getOverlayContent = () => (
        <DA_DynamicOverlayWrapper
            content={options.map((option) => (
                <button
                    onClick={() => handleOptionClick(option)}
                    key={option.value}
                    className={cn(
                        S.btnOption,
                        tempSelection && tempSelection.value === option.value ? S.selected : ""
                    )}
                >
                    {option.label}
                </button>
            ))}
            footer={
                <div className={S.drawerButtonsWrapper}>
                    <DA_Button
                        onClick={handleSave}
                        title={getDictionaryString("FilterSaveButton")}
                    />
                    <DA_Button
                        onClick={closeOverlay}
                        isGhost={true}
                        title={getDictionaryString("FilterCancelButton")}
                    />
                </div>
            }
        />
    );

    const handleOpenOverlay = () => {
        setTempSelection(selectedOption);
        const overlayId = "select-options";
        showOverlay({
            id: overlayId,
            title: restProps.placeholder,
            content: getOverlayContent(),
        });
        setActiveOverlayId(overlayId);
        setOverlayIsOpen(true);
    };

    const handleOptionClick = (option) => {
        setTempSelection(option);
    };

    const handleSave = () => {
        if (tempSelection) {
            onChange(tempSelection); // Invoke the passed onChange with the chosen option
            setSelectedOption(tempSelection); // Update the selectedOption state
        }
        closeOverlay();
        setActiveOverlayId(null);
        setOverlayIsOpen(false);
    };

    useEffect(() => {
        if (overlayIsOpen && activeOverlayId) {
            // Close the current overlay
            closeOverlay();

            // Reopen the overlay with updated content
            showOverlay({
                id: activeOverlayId,
                title: restProps.placeholder,
                content: getOverlayContent(),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempSelection]);

    return (
        <button className={S.pickerBtn} onClick={handleOpenOverlay}>
            <div className={S.pickerBtnLeftContent}>
                {selectedOption?.label ? selectName : restProps.placeholder}
                {selectedOption && (
                    <>
                        : <span className={S.selectedOption}>{selectedOption.label}</span>
                    </>
                )}
            </div>
            <div className={S.pickerBtnRightContent}>
                <span className={S.varianCount}>{`${options.length} ${getDictionaryString(
                    "variants"
                )}`}</span>
                <span className={S.arrowIcon}>
                    <DA_DropdownArrow small={restProps.small} />
                </span>
            </div>
        </button>
    );
};
