import React from "react";
import { Helmet } from "react-helmet";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Receipt } from "@templates/Receipt/Receipt.component";
import { useParams } from "react-router-dom";

export const DA_ReceiptPage = () => {
    const { orderSecret } = useParams();
    const { globalTranslations, getDictionaryString } = useTranslations();

    return (
        <>
            <Helmet>
                <title>
                    {getDictionaryString("Receipt")}
                    {" | "}
                    {globalTranslations.pageTitlePostfix}
                </title>
            </Helmet>

            <DA_Receipt cartId={orderSecret} />
        </>
    );
};
