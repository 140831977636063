import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_ButtonLink } from "@danishagro/shared/src/components/atoms/ButtonLink/ButtonLink.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_ProductDescriptionProps } from "./ProductDescription.interface";
import S from "./ProductDescription.module.scss";

export const DA_ProductDescription = forwardRef((props: DA_ProductDescriptionProps, ref) => {
    const { getDictionaryString } = useTranslations();
    const productDescriptionContent = useRef<HTMLDivElement>(null);
    const [notExpandable, setNotExpandable] = useState<boolean>(false);
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState<boolean>(false);

    const onDescriptionExpandedButtonClicked = useCallback(() => {
        setIsDescriptionExpanded(!isDescriptionExpanded);
    }, [isDescriptionExpanded]);

    useImperativeHandle(ref, () => ({
        showFullDescription: () => {
            setIsDescriptionExpanded(true);
        },
    }));

    useEffect(() => {
        const showReadMore = () => {
            if (
                productDescriptionContent.current.scrollHeight <=
                productDescriptionContent.current.clientHeight
            )
                setNotExpandable(true);
        };
        if (productDescriptionContent.current) showReadMore();
    }, []);

    const isDescriptionExpandedTitle = useMemo(
        () =>
            isDescriptionExpanded
                ? getDictionaryString("read less")
                : getDictionaryString("read more"),
        [isDescriptionExpanded, getDictionaryString]
    );

    return (
        <DA_Text noPadding>
            <div className={cn(notExpandable && S.notExpandable)}>
                <div className={cn(S.contentWrapper, isDescriptionExpanded && S.open)}>
                    <div
                        className={cn(S.content)}
                        dangerouslySetInnerHTML={{ __html: props.description }}
                        ref={productDescriptionContent}
                    />
                </div>
                <DA_ButtonLink
                    className={cn(S.expandButton)}
                    title={isDescriptionExpandedTitle}
                    onClick={onDescriptionExpandedButtonClicked}
                />
            </div>
        </DA_Text>
    );
});

DA_ProductDescription.displayName = "DA_ProductDescription";
