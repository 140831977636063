import { useState } from "react";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { PaymentMethodFormData } from "../interfaces/paymentMethodFormData.interface";

export const useValidation = () => {
    const { getDictionaryString } = useTranslations();

    const [validationErrorMessages, setValidationErrorMessages] = useState<
        { key: string; value: string }[]
    >([]);

    const validate = (body: PaymentMethodFormData) => {
        const tmpValidationErrorMessages = [];

        // EULA: Mandatory Accept
        if (!body.eula || body.eula !== "true") {
            tmpValidationErrorMessages.push({
                key: "eula",
                value: getDictionaryString("OneStepCheckoutEulaTermsConditionsError"),
            });
        }

        setValidationErrorMessages(tmpValidationErrorMessages);

        return tmpValidationErrorMessages.length === 0;
    };

    return {
        validationErrorMessages,
        validate,
    };
};
