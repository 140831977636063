import React, { useEffect, useMemo, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { DA_InlineLink } from "@danishagro/shared/src/components/atoms/InlineLink/InlineLink.component";
import { DA_Title } from "@danishagro/shared/src/components/atoms/Title/Title.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useCustomer } from "@danishagro/shared/src/hooks/useCustomer.hook";
import { useCompletedOrder } from "@templates/Receipt/hooks/useCompletedOrder.hook";
import {
    DA_EmptyState,
    DA_EmptyStateTypes,
} from "@danishagro/shared/src/components/molecules/EmptyState/EmptyState.component";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { useUserInformationApi } from "@danishagro/shared/src/hooks/api/useUserInformationApi.hook";
import { DA_UserAccountDetails } from "@danishagro/shared/src/interfaces/userAccountDetails.interface";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { DA_ReceiptDesktop } from "./components/desktop/ReceiptDesktop.component";
import { DA_ReceiptMobile } from "./components/mobile/ReceiptMobile.component";
import S from "./Receipt.module.scss";

export interface DA_ReceiptProps {
    cartId: string;
}

export const DA_Receipt = ({ cartId }: DA_ReceiptProps) => {
    const { getDictionaryString } = useTranslations();
    const { currentCulture } = useAppData();
    const { getUserAccountDetails } = useUserInformationApi();
    const { isMobile } = useScreen();

    // Completed order
    const {
        awaitingResponse,
        orderLines,
        price,
        customerDetails,
        shippingDetails,
        farmInTimeDriverMessage,
        farmInTimeReferenceId,
    } = useCompletedOrder(cartId);

    // Customer
    const { customer, customerNumber } = useCustomer();

    // User account details
    const [userAccountDetails, setUserAccountDetails] = useState<DA_UserAccountDetails>();
    useEffect(() => {
        getUserAccountDetails(customerNumber).then((response) => {
            setUserAccountDetails(response);
        });
    }, [customerNumber, getUserAccountDetails]);

    const priceFormatted = useMemo(
        () =>
            formatNumber(price?.totalPrice.priceWithVat ?? 0, currentCulture, {
                decimals: 2,
            }),
        [price, currentCulture]
    );

    // const completedDateFormatted = useMemo(() => {
    //     if (!completedDate) return "";

    //     try {
    //         return format(new Date(completedDate), "dd.MM.yyyy");
    //     } catch (err) {
    //         return "";
    //     }
    // }, [completedDate]);

    return (
        <DA_Container className={S.wrapper} noTopPadding>
            {/** Header */}
            <div className={S.header}>
                <DA_Title h1 noMargin>
                    {getDictionaryString("ReceiptHeaderHeading")}
                </DA_Title>
                <DA_InlineLink
                    href="/"
                    forceNativeUrl={true}
                    title={getDictionaryString("ReceiptToFrontpage")}
                />
            </div>

            {/** Banner */}
            <DA_ContentBox>
                <div className={S.banner}>
                    <DA_Icon className={S.bannerIcon} name={DA_IconNames.CheckmarkThin} />

                    <div className={S.bannerContent}>
                        <DA_Title h3>{getDictionaryString("ReceiptBannerHeading")}</DA_Title>
                        <DA_Text
                            noPadding
                            html={getDictionaryString("ReceiptBannerRichText", {
                                email: userAccountDetails?.email,
                            })}
                        />
                    </div>
                </div>
            </DA_ContentBox>

            {/** List */}
            {awaitingResponse ? (
                <DA_EmptyState
                    title={getDictionaryString("ReceiptLoadingOrder")}
                    type={DA_EmptyStateTypes.Loading}
                    smallText
                    iconOnly
                />
            ) : !orderLines?.length ? (
                <DA_EmptyState
                    title={getDictionaryString("ReceiptError")}
                    type={DA_EmptyStateTypes.Error}
                    smallText
                />
            ) : isMobile ? (
                // TODO: Add content for DA_ReceiptMobile component
                <DA_ReceiptMobile
                    priceFormatted={priceFormatted}
                    customerDetails={customerDetails}
                    shippingDetails={shippingDetails}
                    customer={customer}
                    customerNumber={customerNumber}
                    userAccountDetails={userAccountDetails}
                    orderLines={orderLines}
                    price={price}
                    hideDriverMessageButton
                    farmInTimeDriverMessage={farmInTimeDriverMessage}
                    farmInTimeReferenceId={farmInTimeReferenceId}
                />
            ) : (
                <DA_ReceiptDesktop
                    priceFormatted={priceFormatted}
                    customerDetails={customerDetails}
                    shippingDetails={shippingDetails}
                    customer={customer}
                    customerNumber={customerNumber}
                    userAccountDetails={userAccountDetails}
                    orderLines={orderLines}
                    price={price}
                    hideDriverMessageButton
                    farmInTimeDriverMessage={farmInTimeDriverMessage}
                    farmInTimeReferenceId={farmInTimeReferenceId}
                />
            )}

            {/** Toolbar */}
            <div className={S.buttonWrapper}>
                <DA_Button
                    forceNativeUrl
                    href="/"
                    title={getDictionaryString("ReceiptToFrontpage")}
                />
            </div>
        </DA_Container>
    );
};
