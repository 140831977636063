import React, { useCallback, useState } from "react";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { useBottomSheet } from "@danishagro/shared/src/contexts/bottomSheet/bottomSheet.context";
import { DA_NotificationBottomSheet } from "../NotificationServicePopup/components/BottomSheets/NotificationServiceBottomSheet/NotificationServiceBottomSheet.component";
import { NotificationServiceModal } from "../NotificationServicePopup/components/NotificationServiceModal/NotificationServiceModal.component";
import { DA_NotificationServiceViewState } from "../NotificationServicePopup/components/enums/viewStates";
import { NotificationServiceButtonProps } from "./NotificationServiceButton.interface";

export const DA_NotificationServiceButton = (props: NotificationServiceButtonProps) => {
    const { getDictionaryString } = useTranslations();
    const { showModal, closeModal } = useModal();
    const { isMobileOrTablet } = useScreen();
    const { presentBottomSheet } = useBottomSheet();

    const { customerNumber } = useAppData();
    const { getContactByAddressV2 } = useFarmInTimeApi();
    const [viewState, setViewState] = useState("NONE");

    const onContactModalSubmitted = useCallback(() => {
        closeModal();
    }, [closeModal]);

    const onContactModalCancelled = useCallback(() => {
        closeModal();
    }, [closeModal]);

    const onButtonClicked = useCallback(async () => {
        setViewState(DA_NotificationServiceViewState.LOADING);

        let existingContact = undefined;

        try {
            existingContact = await getContactByAddressV2(customerNumber, props.currentAddress?.id);
        } catch {
            /* empty */
        }
        setViewState("DONE");

        return isMobileOrTablet
            ? presentBottomSheet(
                  <DA_NotificationBottomSheet
                      defaultContacts={existingContact}
                      currentAddress={props.currentAddress}
                      onSubmit={onContactModalSubmitted}
                      onCancel={onContactModalCancelled}
                  />,
                  {
                      id: "notification-bottomsheet",
                      headerTitle: getDictionaryString("NotificationServiceModalHeading"),
                      contentPadding: true,
                      snapPoints: ({ maxHeight }) => [maxHeight],
                  }
              )
            : showModal(
                  <NotificationServiceModal
                      defaultContacts={existingContact}
                      currentAddress={props.currentAddress}
                      onSubmit={onContactModalSubmitted}
                      onCancel={onContactModalCancelled}
                  />,
                  { size: ModalSize.LG }
              );
    }, [
        customerNumber,
        getContactByAddressV2,
        getDictionaryString,
        isMobileOrTablet,
        onContactModalCancelled,
        onContactModalSubmitted,
        presentBottomSheet,
        props.currentAddress,
        showModal,
    ]);

    return (
        <DA_Button
            onClick={onButtonClicked}
            title={getDictionaryString("FarmInTimeNotificationServiceButton")}
            showSpinner={viewState === DA_NotificationServiceViewState.LOADING}
        />
    );
};
