import { useCallback, useEffect, useRef, useState } from "react";
import { DA_Address } from "@danishagro/shared/src/interfaces/address.interface";
import { DA_FarmInTimeProduct } from "@interfaces/farmInTimeProduct.interface";
import { useFarmInTimeApi } from "@hooks/api/useFarmInTimeApi.hook";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { DA_FarmInTimePrice } from "@danishagro/shared/src/interfaces/farmInTimePrice.interface";

export const useProducts = (currentAddress: DA_Address) => {
    const { getFarmInTimeProductsV2, getProductPrices } = useFarmInTimeApi();
    const { customerNumber } = useAppData();
    const productsControllerRef = useRef<AbortController | null>();
    const pricesControllerRef = useRef<AbortController | null>();

    const [products, setProducts] = useState<DA_FarmInTimeProduct[]>();
    const [viewState, setViewState] = useState("LOADING");

    // Fetch products

    const fetchProducts = useCallback(async () => {
        if (productsControllerRef.current) {
            productsControllerRef.current.abort();
        }

        if (!currentAddress?.id || !customerNumber) {
            return;
        }

        productsControllerRef.current = new AbortController();
        const response = await getFarmInTimeProductsV2(customerNumber, currentAddress?.id, {
            signal: productsControllerRef.current.signal,
        });

        if (response?.farmInTimeList?.[0]) {
            setProducts(response.farmInTimeList);
        }

        return response?.farmInTimeList;
    }, [customerNumber, getFarmInTimeProductsV2, currentAddress?.id]);

    // Prices

    const [prices, setPrices] = useState<DA_FarmInTimePrice[]>([]);

    useEffect(() => {
        if (pricesControllerRef.current) {
            pricesControllerRef.current.abort();
        }

        const priceProducts =
            products?.map(({ quantity, productNumber, productVariantId }) => ({
                quantity,
                productNumber,
                variantId: productVariantId || undefined,
            })) || [];

        // Start with resetting prices
        setPrices([]);

        if (priceProducts.length) {
            pricesControllerRef.current = new AbortController();
            getProductPrices(customerNumber, priceProducts, {
                signal: pricesControllerRef.current.signal,
            }).then((response) => {
                const newPrices = priceProducts.map(
                    (target) =>
                        response.find(
                            ({ productNumber, variantId }) =>
                                productNumber === target.productNumber &&
                                variantId === target.variantId
                        ) || undefined
                );
                setPrices(newPrices);
            });
        }
    }, [products, getProductPrices, customerNumber]);

    // Update

    const onItemUpdated = () => {
        fetchProducts()
            .then((response) => {
                if (response && response.length > 0) {
                    setViewState("DONE");
                } else {
                    setViewState("EMPTY");
                }
            })
            .catch(() => {
                setViewState("ERROR");
            });
    };

    // Init

    useEffect(() => {
        fetchProducts()
            .then((response) => {
                if (response && response.length > 0) {
                    setViewState("DONE");
                } else {
                    setViewState("EMPTY");
                }
            })
            .catch(() => {
                setViewState("ERROR");
            });
    }, [fetchProducts]);

    return {
        prices,
        products,
        viewState,
        onItemUpdated,
    };
};
