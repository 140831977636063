import React, { forwardRef, useId } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_InputWrapper } from "../InputWrapper/InputWrapper.component";
import { DA_InputWrapperProps } from "../InputWrapper/InputWrapper.component";
import { DA_TextAreaProps } from "./TextArea.props";
import S from "./TextArea.module.scss";

type DA_TextAreaPropsWithWrapper = DA_TextAreaProps & Omit<DA_InputWrapperProps, "forId">;

export const DA_TextArea = forwardRef<HTMLTextAreaElement, DA_TextAreaPropsWithWrapper>(
    (props, ref) => {
        const generatedId = useId();

        const {
            label,
            id,
            name,
            helperText,
            tooltip,
            defaultValue,
            className,
            inputClassName,
            invalid,
            highlighted,
            maxRows,
            readOnly,
            onChange,
            inputMode,
            ...rest
        } = props;

        return (
            <DA_InputWrapper
                label={label}
                forId={id || generatedId}
                helperText={helperText}
                tooltip={tooltip}
                invalid={invalid}
                className={className}
            >
                <TextareaAutosize
                    id={id || generatedId}
                    name={name || id}
                    readOnly={readOnly}
                    defaultValue={defaultValue}
                    ref={ref}
                    className={cn(
                        S.input,
                        S.textarea,
                        invalid && S.invalid,
                        highlighted && S.highlighted,
                        inputClassName
                    )}
                    maxRows={maxRows}
                    onChange={({ target }) => (onChange ? onChange(target.value) : null)}
                    inputMode={inputMode}
                    {...rest}
                />
            </DA_InputWrapper>
        );
    }
);
DA_TextArea.displayName = "DA_TextArea";
