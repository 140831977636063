import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { usePage } from "@danishagro/shared/src/contexts/page.context";
import { DA_Container } from "@danishagro/shared/src/components/atoms/Container/Container.component";
import { DA_Breadcrumb } from "@danishagro/shared/src/components/molecules/Breadcrumb/Breadcrumb.component";
import { DA_CategoryLink } from "@interfaces/categoryLink.interface";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { trimSlashes } from "@danishagro/shared/src/helpers/trimSlashes.helper";
import { useScreen } from "@danishagro/shared/src/contexts/screen.context";
import { Relewise_Category } from "@templates/Relewise/Relewise.Category.component";
import { DA_NewCategoryPageData } from "@interfaces/newCategoryPageData.interface";
import { useRelewise } from "@danishagro/shared/src/contexts/relewise.context";
import { DA_NavigationPageSimple } from "@danishagro/shared/src/interfaces/navigationPage.interface";

interface DA_CategoryPageProps {
    pageData: DA_NewCategoryPageData;
}

export const DA_CategoryPage = ({ pageData }: DA_CategoryPageProps) => {
    const { currentPathDetails, getCategoryFromId } = usePage();
    const { facetGroups, setFacetGroups, setCategoryId } = useRelewise();
    const { getDictionaryString } = useTranslations();
    const { isMobile } = useScreen();
    const [categoryId] = useState(pageData.groupProduct.id);
    const [category, setCategory] = useState<DA_NavigationPageSimple>(undefined);
    const [showCategory, setShowCategory] = useState(false);

    const categorySiblings = useMemo<DA_CategoryLink[]>(
        () =>
            pageData.groupProduct.siblings?.map((sibling) => ({
                ...sibling,
                currentPage: trimSlashes(window.location.pathname) === trimSlashes(sibling.url),
            })),
        [pageData]
    );

    useEffect(() => {
        if (categoryId) {
            const navCategory = getCategoryFromId(categoryId);
            setCategory(navCategory);

            // Prepare relewise
            setFacetGroups(navCategory.facets);
            setCategoryId(categoryId);
        }
    }, [categoryId, getCategoryFromId, setCategoryId, setFacetGroups]);

    useEffect(() => {
        setShowCategory(Boolean(facetGroups !== null && categoryId));
    }, [categoryId, facetGroups]);

    // const setVetMedication = useVetMedicationLink(pageData.groupProduct.vetMedication);
    // console.log('page', pageData, categoryId)

    // useEffect(() => {
    //     renderCount.current++;

    //     if (renderCount.current > 1) {
    //         setIsFetching(true);

    //         getPage()
    //             .then((page) => {
    //                 setProducts(page?.groupProduct?.products);
    //                 setTotalProductCount(page?.groupProduct?.totalProductCount);
    //                 setVetMedication(page?.groupProduct?.vetMedication);

    //                 setFilters((currentFilters) =>
    //                     [...currentFilters].map((filterGroup) => ({
    //                         ...filterGroup,
    //                         options: filterGroup?.options?.map((option) => ({
    //                             ...option,
    //                             checked: searchParams.has(filterGroup?.value)
    //                                 ? searchParams
    //                                       .get(filterGroup?.value)
    //                                       .split(",")
    //                                       .includes(option?.value)
    //                                 : false,
    //                             count:
    //                                 page?.groupProduct?.facets
    //                                     .find(({ value }) => value === filterGroup?.value)
    //                                     ?.options?.find(({ value }) => value === option?.value)
    //                                     ?.count || 0,
    //                         })),
    //                     }))
    //                 );
    //             })
    //             .finally(() => {
    //                 setIsFetching(false);
    //             });
    //     }
    // }, [searchParams, setVetMedication]);

    // useEffect(() => {
    // setProducts(showRelewiseCategory ? null : pageData.groupProduct.products);
    // setTotalProductCount(pageData.groupProduct.totalProductCount);
    // setFilters(pageData.groupProduct.facets);
    // setFacetGroups(pageData.groupProduct.facets);
    // setVetMedication(pageData.groupProduct.vetMedication);

    // TODO: Fetch the products directly from state
    // }, [pageData, setFacetGroups, setVetMedication]);

    // useEffect(() => {
    //     if (state?.scrollY && state?.scrollY > 0 && typeof window !== "undefined") {
    //         setTimeout(() => {
    //             scrollTo(state.scrollY);
    //         }, 50);
    //     }
    // }, [scrollTo, state.scrollY]);

    // Breadcrumbs options
    const [isExpanded, setIsExpanded] = useState(false);

    const handleBreadcrumbClick = () => {
        setIsExpanded(!isExpanded);
    };

    const mapPathToItems = (data) =>
        data.map(({ name, path, categoryId }) => ({ title: name, href: path, id: categoryId }));

    let breadcrumbItems;

    if (isMobile) {
        if (!isExpanded && currentPathDetails.length >= 2) {
            breadcrumbItems = [
                { title: "...", onClick: handleBreadcrumbClick },
                ...mapPathToItems(currentPathDetails.slice(-2)),
            ];
        } else {
            breadcrumbItems = [
                { title: getDictionaryString("webshop"), href: "/", id: globalThis.frontPageId },
                ...mapPathToItems(currentPathDetails),
            ];
        }
    } else {
        breadcrumbItems = [
            { title: getDictionaryString("webshop"), href: "/", id: globalThis.frontPageId },
            ...mapPathToItems(currentPathDetails),
        ];
    }

    return (
        <DA_Container>
            <Helmet>
                <title>{pageData?.meta?.title}</title>
                <meta name="description" content={pageData?.meta?.description} />
            </Helmet>
            <DA_Breadcrumb breadcrumbItems={breadcrumbItems} />

            {showCategory ? (
                <Relewise_Category
                    categoryId={categoryId}
                    title={pageData.groupProduct.name}
                    enableQuickOrder={category?.allowQuickOrder}
                    subCategories={pageData.groupProduct.children}
                    relatedCategoryLinks={categorySiblings}
                    parentCategoryName={
                        pageData.groupProduct.parent
                            ? pageData.groupProduct.parent.name
                            : getDictionaryString("webshop")
                    }
                    parentCategoryLink={
                        pageData.groupProduct.parent ? pageData.groupProduct.parent.url : "/"
                    }
                    level={pageData.groupProduct.level}
                />
            ) : null}
        </DA_Container>
    );
};
