import React, { useEffect, useRef, useState } from "react";
import { useProductPrices } from "@danishagro/shared/src/hooks/useProductPrices.hook";
import { DA_CategoryLink } from "@interfaces/categoryLink.interface";
import { DA_BasicLink } from "@danishagro/shared/src/components/atoms/BasicLink/BasicLink.component";
import { cn } from "@danishagro/shared/src/helpers/classNames.helper";
import { DA_ContentBox } from "@danishagro/shared/src/components/atoms/ContentBox/ContentBox.component";
import { DA_Icon, DA_IconNames } from "@danishagro/shared/src/components/atoms/Icon/Icon.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { formatNumber } from "@danishagro/shared/src/helpers/formatNumber.helper";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import Skeleton from "react-loading-skeleton";
import { DA_NewProductSimple } from "@danishagro/shared/src/interfaces/productv2.interface";
import { ProductLoader } from "@templates/Relewise/components/ProductLoader.component";
import S from "./CategoryProductList.module.scss";

export type DA_CategoryProductListItemProps = DA_NewProductSimple & {
    onSale?: boolean;
    eagerLoading?: boolean;
    "data-id"?: string;
    "data-name"?: string;
    "data-listid"?: string;
    "data-price"?: string;
    "data-category"?: string;
    "data-brand"?: string;
};

export interface DA_CategoryProductListProps {
    items: DA_CategoryProductListItemProps[];
    relatedCategoryLinks: DA_CategoryLink[];
    topCategoryLink?: DA_CategoryLink;
}

export const DA_CategoryProductList = ({
    items,
    relatedCategoryLinks,
    topCategoryLink,
}: DA_CategoryProductListProps) => {
    const { getDictionaryString } = useTranslations();
    const { currentCulture } = useAppData();
    const [selectedProductIndex, setSelectedProductIndex] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const productListRef = useRef(null);
    const [isScrollbarVisible, setIsScrollbarVisible] = useState(null);

    const sortAndMapProducts = (items) => {
        const result = [];
        if (items) {
            items
                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                .map((item: DA_NewProductSimple) => {
                    result.push(item);
                });
        }

        return result;
    };

    const products = sortAndMapProducts(items);
    const priceData = useProductPrices(products);

    useEffect(() => {
        setIsScrollbarVisible(
            productListRef.current.scrollHeight > productListRef.current.clientHeight
        );
    }, [productListRef]);

    return (
        <div className={S.container}>
            <DA_ContentBox className={S.contentBox}>
                <div className={S.boxTitle}>
                    <span>{getDictionaryString("type")}</span>
                </div>

                {relatedCategoryLinks && relatedCategoryLinks.length > 0 && (
                    <div className={cn(S.contentWrapper)}>
                        <ul className={S.listWrapper}>
                            {topCategoryLink && (
                                <DA_BasicLink
                                    href={`${
                                        topCategoryLink.redirectUrl
                                            ? topCategoryLink.redirectUrl
                                            : topCategoryLink.url
                                    }?quickorder=true`}
                                    className={cn(
                                        S.categoryLink,
                                        topCategoryLink.currentPage && S.currentPage
                                    )}
                                >
                                    {topCategoryLink.name}
                                    {topCategoryLink.currentPage && (
                                        <DA_Icon
                                            name={DA_IconNames.ChevronRight}
                                            className={S.iconSelected}
                                        />
                                    )}
                                </DA_BasicLink>
                            )}

                            {relatedCategoryLinks.map((item) => (
                                <li
                                    key={item.id}
                                    className={cn(S.listItem, item.currentPage && S.currentPage)}
                                >
                                    <DA_BasicLink
                                        href={`${
                                            item.redirectUrl ? item.redirectUrl : item.url
                                        }?quickorder=true`}
                                        className={cn(
                                            S.categoryLink,
                                            item.currentPage && S.currentPage
                                        )}
                                    >
                                        {item.name}
                                        {item.currentPage && (
                                            <DA_Icon
                                                name={DA_IconNames.ChevronRight}
                                                className={S.iconSelected}
                                            />
                                        )}
                                    </DA_BasicLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </DA_ContentBox>

            <DA_ContentBox className={cn(S.contentBox, isScrollbarVisible && S.hasScroll)}>
                <div className={S.boxTitle}>
                    <span>{getDictionaryString("product")}</span>
                    <span className={S.productNumberTitle}>
                        {getDictionaryString("productnumber")}
                    </span>
                    <span className={S.priceTitle}>{getDictionaryString("recommendedprice")}</span>
                </div>

                <div className={S.productListWrapper}>
                    <div className={S.productList} ref={productListRef}>
                        {products.map((product, index) => (
                            <a
                                key={`${product.entityId}--${index}`}
                                href={`#${product.productNumber}`}
                                className={cn(
                                    S.productItem,
                                    selectedProductIndex !== null &&
                                        index === selectedProductIndex &&
                                        S.productItemSelected
                                )}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedProductIndex(index);
                                    setSelectedProduct(product);
                                    return false;
                                }}
                            >
                                <span>{product.displayName}</span>
                                <span className={S.col2}>{product.productNumber}</span>
                                {priceData && priceData[index] ? (
                                    <span className={S.col3}>
                                        {formatNumber(
                                            priceData[index].priceWithoutVat,
                                            currentCulture,
                                            {
                                                decimals: 2,
                                            }
                                        )}
                                    </span>
                                ) : (
                                    <div className={S.col3}>
                                        <Skeleton width="70px" height="16px" />
                                    </div>
                                )}
                            </a>
                        ))}
                    </div>
                </div>
            </DA_ContentBox>

            <div className={S.selectedProductWrapper}>
                {selectedProduct ? (
                    <ProductLoader product={selectedProduct} />
                ) : (
                    <DA_ContentBox className={S.emptyProductWrapper}>
                        <div className={S.iconWrapper}>
                            <DA_Icon name={DA_IconNames.MagnifyingGlass} className={S.iconEmpty} />
                        </div>
                        <h3>{getDictionaryString("quickorder.emptyproducttitle")}</h3>
                        <p>{getDictionaryString("quickorder.emptyproductdescription")}</p>
                    </DA_ContentBox>
                )}
            </div>
        </div>
    );
};
