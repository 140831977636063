import React from "react";
import Skeleton from "react-loading-skeleton";

interface PriceBase {
    priceWithoutVat: number | JSX.Element;
    priceWithVat: number;
}

interface PriceWithTranslation {
    price: number | JSX.Element | "";
}

// Utility function to get priceWithoutVat from PriceBase type or JSX element
export const getPriceWithoutVat = (
    priceData: PriceBase | JSX.Element,

    returnEmptyIfSame: boolean = false
): PriceWithTranslation => {
    if (React.isValidElement(priceData)) {
        // If it's a JSX element (like a Skeleton), return a Skeleton component
        return { price: <Skeleton width={30} /> };
    } else if (
        typeof priceData === "object" &&
        priceData !== null &&
        "priceWithoutVat" in priceData &&
        typeof priceData.priceWithoutVat === "number"
    ) {
        // If priceWithoutVat is 0, return an empty string
        if (priceData.priceWithoutVat === 0) {
            return { price: "" };
        }

        // Check if we should return an empty string
        if (returnEmptyIfSame) {
            return { price: "" };
        }

        // If it's a PriceBase object, return the translated price
        return { price: priceData.priceWithoutVat };
    } else {
        // If neither, return an empty string or a default placeholder
        return { price: "" };
    }
};
