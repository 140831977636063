import React, { useMemo } from "react";
import { DA_Modal } from "@danishagro/shared/src/components/molecules/Modal/Modal.component";
import { DA_Text } from "@danishagro/shared/src/components/atoms/Text/Text.component";
import { DA_Button } from "@danishagro/shared/src/components/atoms/Button/Button.component";
import { getLoginLink } from "@danishagro/shared/src/helpers/getLoginLink.helper";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { useAppData } from "@danishagro/shared/src/contexts/appData.context";
import { getUrl } from "@danishagro/shared/src/helpers/getUrl.helper";
import { DA_AddToCartModalProps } from "./AddToCartModal.props";
import S from "./AddToCartModal.module.scss";

export const DA_AddToCartModal = (props: DA_AddToCartModalProps) => {
    const { getDictionaryString } = useTranslations();
    const { currentSite } = useAppData();

    const content = useMemo(() => {
        switch (props.type) {
            case "NOT_LOGGED_IN":
                return {
                    title: getDictionaryString("notLoggedInModalTitle"),
                    text: getDictionaryString("notLoggedInModalText"),
                    button: getDictionaryString("notLoggedInModalLoginButtonText", {
                        dontShowFallback: true,
                    }),
                    buttonHref: getLoginLink(currentSite),
                    linkText: getDictionaryString("notLoggedInModalContactLink", {
                        dontShowFallback: true,
                    }),
                };
            case "NO_ACCOUNT_ATTACHED":
                return {
                    title: getDictionaryString("noAccountAttachedTitle"),
                    text: getDictionaryString("noAccountAttachedText"),
                    button: getDictionaryString("noAccountAttachedLoginButtonText", {
                        dontShowFallback: true,
                    }),
                    buttonHref: getUrl("contact"),
                    linkText: getDictionaryString("noAccountAttachedContactLink", {
                        dontShowFallback: true,
                    }),
                };
        }
    }, [currentSite, getDictionaryString, props.type]);

    return (
        <DA_Modal title={content.title}>
            <div className={S.modalWrapper}>
                {/** Text */}
                <div className={S.textWrapper}>
                    <DA_Text html={content.text} noPadding />
                </div>

                {/** Button */}
                {content.button && content.buttonHref ? (
                    <DA_Button href={content.buttonHref} title={content.button} />
                ) : null}

                {/** Link */}
                {content.linkText ? <DA_Text html={content.linkText} noPadding /> : null}
            </div>
        </DA_Modal>
    );
};
