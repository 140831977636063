import React, { useCallback, useMemo, useRef } from "react";
import { DA_Select } from "@danishagro/shared/src/components/atoms/Select/Select.component";
import { ModalSize, useModal } from "@danishagro/shared/src/contexts/modal.context";
import { DA_ConfirmModal } from "@danishagro/shared/src/components/molecules/ConfirmModal/ConfirmModal.component";
import { useTranslations } from "@danishagro/shared/src/contexts/translations/translations.context";
import { FrequencyPickerProps } from "./FrequencyPicker.interface";
import S from "./FrequencyPicker.module.scss";

export const FrequencyPicker = (props: FrequencyPickerProps) => {
    const frequencyWrapperElementRef = useRef<HTMLDivElement>();
    const { getDictionaryString } = useTranslations();
    const { showModal } = useModal();

    // Mappers

    const currentMonthOption = useMemo(
        () => props.monthOptions.find((x) => x.value === props.currentMonth),
        [props.monthOptions, props.currentMonth]
    );

    const currentFrequencyOption = useMemo(
        () => props.frequencyOptions.find((x) => x.value === props.currentFrequency),
        [props.frequencyOptions, props.currentFrequency]
    );

    const onWarningModalClosed = useCallback((callback: (event: Event) => void) => {
        setTimeout(() => {
            document.addEventListener("click", callback);
        }, 200);
    }, []);

    const handleClickMonthState = useCallback(
        (event: Event) => {
            if (
                frequencyWrapperElementRef.current &&
                !frequencyWrapperElementRef.current.contains(event.target as HTMLDivElement)
            ) {
                document.removeEventListener("click", handleClickMonthState);

                showModal(
                    <DA_ConfirmModal
                        title={getDictionaryString("FrequencyModalTitle")}
                        description={getDictionaryString("FrequencyModalDescription")}
                        onDeny={() => onWarningModalClosed(handleClickMonthState)}
                        denyButtonLabel={getDictionaryString("FrequencyModalCloseLabel")}
                    />,
                    { size: ModalSize.XS }
                );
            }
        },
        [getDictionaryString, onWarningModalClosed, showModal]
    );

    const onMonthSelectChanged = (option: { label: string; value: string }) => {
        props.setCurrentMonth(option?.value);

        document.removeEventListener("click", handleClickMonthState);

        props.onItemUpdated({
            month: option?.value,
        });
    };

    const onFrequencySelectChanged = (option: { label: string; value: string }) => {
        document.removeEventListener("click", handleClickMonthState);

        props.setCurrentFrequency(option?.value);

        // Always reset, no matter the value.
        props.setCurrentMonth(undefined);

        if (option.value !== "Yearly") {
            props.onItemUpdated({
                frequency: option?.value,
            });
        } else {
            setTimeout(() => {
                document.addEventListener("click", handleClickMonthState);
            }, 200);
        }
    };

    return (
        <div className={S.wrapper} ref={frequencyWrapperElementRef}>
            <DA_Select
                id="farm-in-time-frequency"
                disabled={props.disabled}
                value={currentFrequencyOption?.value}
                options={props.frequencyOptions}
                onChange={onFrequencySelectChanged}
            />

            {props.currentFrequency === "Yearly" && (
                <DA_Select
                    id="farm-in-time-month"
                    disabled={props.disabled}
                    value={currentMonthOption?.value}
                    options={props.monthOptions}
                    onChange={onMonthSelectChanged}
                />
            )}
        </div>
    );
};
